import { useState } from "react";
import Loader from "../../components/Common/Loader";

const StepKickStart = ({ startStepOne }) => {
  const [loader, setLoader] = useState(false);
  const handleSubmit = () => {
    setLoader(true);
    startStepOne(setLoader);
  };
  return (
    <div>
      <h2 className="text-left text-2xl md:text-4xl mb-4 font-bold text-storyblue">
        Let&apos;s Build Your Story
      </h2>
      <p className="mb-4">
        Kickstart your personalized storytelling adventure by providing us with your child's name, desired
        story topic, book goal, and preferred art style, and we'll craft a unique, enchanting book just for
        them!
      </p>
      <p>
        The process is very easy and within just a few minutes you'll have a fully customized children's book.
        You will be able to upload an image of the main character of your book, pick your art style and we
        will generate a new version of that character that will be used throughout the book.
      </p>
      {/* <button
        onClick={startStepOne}
        className="w-full bg-storyblue text-white font-bold rounded-md text-xl p-2 mt-8 mb-4"
      >
        Let&apos;s Get Started!
      </button> */}
      <button
        onClick={handleSubmit}
        disabled={loader}
        className={`w-full lg:w-[50%] h-11 flex justify-center items-center gap-2 bg-storyblue text-white font-bold rounded-md text-xl p-2 mt-8 mb-4 ${
          loader ? "opacity-50" : ""
        }`}
      >
        <span>Let&apos;s Get Started!</span>
        <Loader loader={loader} />
      </button>
    </div>
  );
};

export default StepKickStart;