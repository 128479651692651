import React, { useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { ROLE_ADMIN, ROLE_SALES, ROLE_SUPERADMIN } from "../helper/Constant";

const AdminRoutesGuard = ({ children }) => {
  const { user } = useUser();
  
  const navigateTo = useNavigate();
  useEffect(() => {
    if (
      user !== null &&
      (user?.role === ROLE_ADMIN || user?.role === ROLE_SUPERADMIN || user?.role === ROLE_SALES)
    ) {
      // return <Navigate to={"/admin/dashboard"} />;
      return navigateTo("/admin/dashboard");
    }
  }, [user]);

  return <div>{children ? children : <Outlet />}</div>;
};

export default AdminRoutesGuard;
