import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
const CheckoutForm = ({ stripe, elements }) => {
  return (
    <>
        <CardSection />
    </>
  );
};

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements}  style={{margin:"0px",
 }}/>
      )}
    </ElementsConsumer>
  );
}

export default InjectedCheckoutForm;
