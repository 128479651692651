import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { useUser } from "../../context/userContext";
import SubmitButton from "../../components/Common/SubmitButton";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import Heading from "../Common/Heading";
import {
  COUPON_FOR_CREDITS_PURCHASE,
  COUPON_FOR_STORY_PURCHASE,
  GOAFFPRO_PUBLIC_KEY,
  HTTP_STATUS_200,
  PURCHASE_TYPE_CREDITS,
  PURCHASE_TYPE_STORY,
} from "../../helper/Constant";
import Loader from "../Common/Loader";

import { init, trackPageView, trackConversion } from "goaffpro-client";
import { useApp } from "../../context/appContext";
import ThankYou from "../Common/ThankYou";
import Guide from "../Common/Guide";

init(GOAFFPRO_PUBLIC_KEY);

const LowCreditModal = ({ showLowCreditModal = true, setShowLowCreditModal = null }) => {
  const [showThanksNote, setShowThanksNote] = useState(false);
  const [couponLoader, setCouponLoader] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const { bookData } = useApp();

  //goaffpro page tracking
  useEffect(() => {
    trackPageView();
  }, []);

  //  const[ userCreditBalance, setUserCreditBalance]= useState(0)
  //  useEffect(() => {
  //    setUserCreditBalance(user?.creditBalance);
  //  }, [user?.creditBalance]);

  const [paymentLoader, setPaymentLoader] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [couponClass, setCouponClass] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [price, setPrice] = useState(null);
  const [freeOfCost, setFreeOfCost] = useState(false);

  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(
    bookData?.bookId ? PURCHASE_TYPE_CREDITS : PURCHASE_TYPE_STORY
  );

  const handleOptionChange = (event) => {
    setSelectedPurchaseOption(event.target.value);
  };

  useEffect(() => {
    if (selectedPurchaseOption === PURCHASE_TYPE_CREDITS) setPrice(user?.plan?.additionalAICredit?.price);
    else if (selectedPurchaseOption === PURCHASE_TYPE_STORY)
      setPrice(user?.plan?.additionalAICredit?.storyCreationCost);
  }, [selectedPurchaseOption]);

  useEffect(() => {
    //If coupon applied having more discount then current charging amount, price goes to -ve
    if (parseFloat(price) < 0) setPrice(0);
    else if (price === 0) setFreeOfCost(true);
  }, [price]);

  //  useEffect(() => {
  //    const goaffproOrder = {
  //      number: "#1001",
  //      total: 1000,
  //    };

  //    // Define goaffproTrackConversion function (if not already defined)
  //    const goaffproTrackConversion = (order) => {
  //      // Your implementation of goaffproTrackConversion function
  //      // You might need to import or define this function here
  //      // For demonstration purposes, we're just logging the order

  //      console.log("Tracking conversion for order:", order);
  //    };

  //    // Call goaffproTrackConversion function with goaffproOrder
  //    goaffproTrackConversion(goaffproOrder);
  //  }, []);

  useEffect(() => {
    if (selectedPurchaseOption === PURCHASE_TYPE_CREDITS) {
      if (discount && parseFloat(discount) >= parseFloat(user?.plan?.additionalAICredit?.price)) {
        setFreeOfCost(true);
      }
    } else if (selectedPurchaseOption === PURCHASE_TYPE_STORY) {
      if (discount && parseFloat(discount) >= parseFloat(user?.plan?.additionalAICredit?.storyCreationCost)) {
        setFreeOfCost(true);
      }
    }
  }, [discount]);

  const handlePayment = async () => {
    const creditsPrice =
      selectedPurchaseOption === PURCHASE_TYPE_CREDITS
        ? user?.plan?.additionalAICredit?.price
        : user?.plan?.additionalAICredit?.storyCreationCost;

    if (freeOfCost) {
      const userData = {
        email: user.email,
        requestType:
          selectedPurchaseOption === PURCHASE_TYPE_CREDITS
            ? COUPON_FOR_CREDITS_PURCHASE
            : COUPON_FOR_STORY_PURCHASE,
        coupon,
        freeOfCost,
        creditsPrice,
        purchaseType: selectedPurchaseOption,
        bookId: bookData ? bookData?.bookId : null,
      };
      const paymentResponse = await callAPI("user/purchase-credits", userData);
      if (paymentResponse?.status === HTTP_STATUS_200) {
        Toast("Image Credits Purchased Successfully");
        setShowThanksNote(true);
        if (setShowLowCreditModal) setShowLowCreditModal(false);
      } else Toast(paymentResponse?.message || "Unexpected Error Occurred", true);
      setPaymentLoader(false);
    } else {
      if (!stripe || !elements) return;
      setPaymentLoader(true);
      const resultStripe = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email,
        },
      });
      if (resultStripe?.error) {
        setPaymentLoader(false);
        Toast(resultStripe?.error?.message, true);
      } else {
        const userData = {
          email: user.email,
          paymentMethod: resultStripe.paymentMethod.id,
          requestType:
            selectedPurchaseOption === PURCHASE_TYPE_CREDITS
              ? COUPON_FOR_CREDITS_PURCHASE
              : COUPON_FOR_STORY_PURCHASE,
          coupon,
          creditsPrice,
          purchaseType: selectedPurchaseOption,
          bookId: bookData ? bookData?.bookId : null,
        };
        const paymentResponse = await callAPI("user/purchase-credits", userData);
        if (paymentResponse?.status === HTTP_STATUS_200) {
          Toast("Image Credits Purchased Successfully");

          //goaffpro track conversion
          trackConversion({
            number: paymentResponse?.paymentId, // human readable order number displayed to the admins
            total: creditsPrice,
            currency: "USD",
          });

          setShowThanksNote(true);
          if (setShowLowCreditModal) setShowLowCreditModal(false);
          // goBack();
        } else Toast(paymentResponse?.message || "Unexpected Error Occurred", true);
        setPaymentLoader(false);
      }
    }
  };

  const handleApplyPromoCode = async (couponCode) => {
    setCouponLoader(true);
    const response = await callAPI("user/coupon-validate", {
      couponCode,
      amount: user?.plan?.additionalAICredit?.price || 0,
      requestType: COUPON_FOR_CREDITS_PURCHASE,
      email: user?.email,
    });

    if (response.status === HTTP_STATUS_200) {
      setCouponClass("text-storygreen");
      let discountedPrice = parseFloat(response?.data?.discount).toFixed(2);
      let price = parseFloat(response?.data?.price).toFixed(2);
      setPrice(price);
      setDiscount(discountedPrice);
      Toast("Coupon Applied");

      setCouponLoader(false);
    } else {
      setDiscount(null);
      setCouponClass("text-storyred");
      Toast("Invalid Coupon", true);
      setCouponLoader(false);
    }
  };

  const goBack = () => {
    navigate(-1);
    setShowLowCreditModal(false);
    setShowThanksNote(false);
  };

  return (
    <>
      {showThanksNote ? (
        <ThankYou goBack={goBack} />
      ) : (
        <div aria-labelledby="modal-title" role="dialog" aria-modal="true">
          {/* <div className="modal-background" onClick={() => setShowLowCreditModal(false)}></div> */}
          {/* <Heading title="Buy Illustration & Story Credits" /> */}
          <div className=" flex items-center align-end mb-4">
            <Heading title="Buy Illustration & Story Credits" />
            <div className="mt-1">
              <Guide />
            </div>
          </div>
          <div className="flex gap-4  items-center">
            {bookData?.bookId && (
              <label className="inline-flex items-center font-bold text-storygreen">
                <input
                  type="radio"
                  className="form-radio text-storygreen"
                  name="credits"
                  value={PURCHASE_TYPE_CREDITS}
                  checked={selectedPurchaseOption === PURCHASE_TYPE_CREDITS}
                  onChange={handleOptionChange}
                />
                <span className="ml-2">Buy Illustrations</span>
              </label>
            )}

            <label className="inline-flex items-center font-bold text-storygreen">
              <input
                type="radio"
                className="form-radio text-storyblue"
                name="story"
                value={PURCHASE_TYPE_STORY}
                checked={selectedPurchaseOption === PURCHASE_TYPE_STORY}
                onChange={handleOptionChange}
              />
              <span className="ml-2">Buy Story</span>
            </label>
          </div>
          <div className="modal-content">
            <div className="bg-storytan p-0 mx-auto">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePayment();
                }}
              >
                <div className="max-w-7xl mx-auto">
                  {selectedPurchaseOption === PURCHASE_TYPE_CREDITS ? (
                    <div className="w-full flex justify-between ">
                      <h1 className=" flex items-start font-bold text-storyblue text-md mt-6 ">
                        Current Image Credits are:
                        <span className="ml-2 font-normal">{bookData?.creditsPerBookLimit || 0}</span>
                      </h1>
                      <h1 className=" flex items-start font-bold text-storyblue text-md mt-6 ">
                        {bookData?.title ? (
                          <span className="ml-2 font-normal">
                            <strong>Book:</strong> {bookData?.title}
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                    </div>
                  ) : selectedPurchaseOption === PURCHASE_TYPE_STORY ? (
                    <h1 className=" flex items-start font-bold text-storyblue text-md mt-6 ">
                      Current Story Creation Limit is:
                      <span className="ml-2 font-normal">
                        {parseInt(user?.booksCreateLimit) - parseInt(user?.booksCreatedCounter)}
                      </span>
                    </h1>
                  ) : (
                    ""
                  )}
                  <div className="bg-white rounded-md flex flex-col gap-3 justify-around items-start w-[100%] my-2 p-2">
                    <h1 className="text-md text-storyblue">
                      <strong>Your Current Plan:</strong> <span>{user?.plan?.name}</span>
                    </h1>
                    {selectedPurchaseOption === PURCHASE_TYPE_CREDITS ? (
                      <>
                        {" "}
                        <h1 className="text-md text-left text-storyblue">
                          <strong>Additional Image Credits Per Book: </strong>
                          {user?.plan?.additionalAICredit?.credits} Image Credits for ${" "}
                          {user?.plan?.additionalAICredit?.price}
                        </h1>
                      </>
                    ) : (
                      <>
                        {" "}
                        <h1 className="text-md text-left text-storyblue">
                          <strong>Additional Story Creation Limit: </strong>
                          {user?.plan?.additionalAICredit?.storyCreationLimit} Stories for ${" "}
                          {user?.plan?.additionalAICredit?.storyCreationCost}
                        </h1>
                      </>
                    )}
                    {/* {user?.plan?.unlimitedDownloads && (
                      <h1 className="text-md text-storyblue">
                        <strong>Unlimited Downloads</strong>
                      </h1>
                    )} */}
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="mt-5  rounded-md  h-10 ">
                      <div className="flex items-center mt-2 gap-2 rounded-md">
                        <input
                          type="text"
                          name="coupon"
                          value={coupon}
                          onChange={(e) => {
                            setCoupon(e.target.value);
                          }}
                          placeholder="Coupon (Optional)"
                          className={`w-full md:w-[20%] p-2 rounded-md uppercase ${couponClass} `}
                        />
                        {/* <button
                          type="button"
                          className="w-72 flex justify-center items-center gap-1 bg-storygreen text-white whitespace-nowrap font-bold px-2 rounded-md text-xs md:text-sm h-9"
                          onClick={() => handleApplyPromoCode(coupon)}
                        >
                          Apply Coupon {couponLoader && <Loader loader={couponLoader} />}
                        </button> */}
                        <button
                          type="button"
                          className={`${
                            !coupon ? "pointer-events-none opacity-70" : ""
                          } w-72 flex justify-center items-center gap-1 bg-storygreen text-white whitespace-nowrap font-bold px-2 rounded-md text-xs md:text-sm h-9`}
                          onClick={() => handleApplyPromoCode(coupon)}
                          disabled={!coupon}
                        >
                          Apply Coupon {couponLoader && <Loader loader={couponLoader} />}
                        </button>
                      </div>
                    </div>
                    {freeOfCost === false && (
                      <>
                        <div className="mt-5  rounded-md h-10 ">
                          <input
                            type="text"
                            required
                            name="billingCardHolderName"
                            value={cardHolderName}
                            onChange={(e) => {
                              setCardHolderName(e.target.value);
                            }}
                            placeholder="Card Holder Name"
                            className="p-2 w-full rounded-md"
                          />
                        </div>

                        <div className="w-full bg-white p-2 rounded-md h-10">
                          <CheckoutForm />
                        </div>
                      </>
                    )}

                    <p className="flex pt-2 text-storygreen text-sm">
                      {" "}
                      {discount && (
                        <span className=" text-storygreen text-sm">
                          {" "}
                          Discount applied of <b className="pl-1"> $ {discount}</b>.
                        </span>
                      )}{" "}
                      You will be charged <b className="pl-1"> $ {price}</b>
                    </p>

                    <SubmitButton title="Buy More Credits" loader={paymentLoader} cancelOperation={goBack} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default LowCreditModal;
