import { Navigate, createHashRouter } from "react-router-dom";
import AdminRoutesGuard from "./AdminRoutesGuard";

import Verify from "../pages/Verify/Verify";

import Builder from "../pages/Builder/Builder";
import BookPage from "../pages/BookPage/BookPage";
import BookFlip from "../pages/BookFlip/BookFlip";
import BookList from "../pages/BookList/BookList";
import BookPurchase from "../pages/BookPurchase/Purchase";
import About from "../pages/About/About";
import Help from "../pages/Help/Help";
import Pricing from "../pages/Pricing/Pricing";
import Profile from "../pages/Profile/Profile";
import Debug from "../pages/Debug/Debug";
import Credit from "../pages/Credit/Credit"


import Admin from "../pages/Admin/Admin";
import Coupons from "../pages/Admin/Coupon";
import Dashboard from "../pages/Admin/Dashboard";




import AdminSignup from "../pages/Admin/AdminSignup";
import UserRouteGuard, { BrowserPopupComponent } from './UserRouteGuard'
import ForgotPassword from "../pages/User/ForgotPassword";
import ResetPassword from "../pages/User/ResetPassword";
import OrderHistory from "../pages/OrderHistory/OrderHistory";
import Payments from "../pages/PaymentHistory/PaymentHistory";
import MyPlans from "../pages/MyPlans/MyPlans";




const RouterMain = createHashRouter([
  {
    children: [
      {
        path: "/",
        element: (
          <BrowserPopupComponent>
            {" "}
            <Builder />{" "}
          </BrowserPopupComponent>
        ),
      },
      {
        path: "/builder/:newBook",
        element: (
          <BrowserPopupComponent>
            {" "}
            <Builder />{" "}
          </BrowserPopupComponent>
        ),
      },
      {
        path: "/verify/:token",
        element: <Verify />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:email/:token",
        element: <ResetPassword />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        element: <UserRouteGuard />,
        children: [
          {
            path: "/books/:bookId",
            element: <BookPage />,
          },
          {
            path: "/bookflip/:bookId",
            element: <BrowserPopupComponent><BookFlip /></BrowserPopupComponent>,
          },
          {
            path: "/booklist",
            element: <BookList />,
          },
          {
            path: "/bookpurchase/:bookId",
            element: <BrowserPopupComponent><BookPurchase /></BrowserPopupComponent>,
          },
          {
            path: "/updateProfile",
            element: <BrowserPopupComponent><Profile /></BrowserPopupComponent>,
          },
          {
            path: "/myPlans",
            element: <BrowserPopupComponent><MyPlans /></BrowserPopupComponent>,
          },
          {
            path: "/pricing",
            element: <Pricing />,
          },
          {
            path: "/about",
            element: <About />,
          },
          {
            path: "/help",
            element: <Help />,
          },
          {
            path: "/library",
            element: <BrowserPopupComponent><Debug /></BrowserPopupComponent>,
          },
          {
            path: "/ImageTokens",
            element: <BrowserPopupComponent><Credit /></BrowserPopupComponent>,
          },
          {
            path: "/OrderHistory",
            element: <BrowserPopupComponent><OrderHistory /></BrowserPopupComponent>,
          },
          {
            path: "/Payments",
            element: <BrowserPopupComponent><Payments /></BrowserPopupComponent>,
          },
        ],
      },
      {
        element: <AdminRoutesGuard />,
        children: [
          {
            path: "/admin/signup",
            element: <AdminSignup />,
          },
          {
            path: "/admin/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/admin/coupon",
            element: <Coupons />,
          },
        ],
      },
      {
        path: "*",
        element: <div>Page Not found</div>,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to={"/"} replace />,
  },
]);//*/
export default RouterMain;
