import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data }) => {
  // Extracting data for labels and values
  const labels = data?.map((entry) => `Host ${entry?.host_id}`);
  const runningJobsData = data?.map((entry) => entry?.running_jobs);
  const concurrentJobsLimitData = data?.map((entry) => entry?.concurrent_jobs_limit);

  // Data for the bar chart
  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: labels,
    },
   plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#062457', '#1d7a80'], 
          },
          formatter: function(val, opts) {
            return opts.seriesIndex === 0 ? val : ''; 
          }
        }
      }}
  };

  const series = [
    {
      name: "Running Jobs",
      data: runningJobsData,
      color: "#062457",
    },
    {
      name: "Concurrent Jobs Limit",
      data: concurrentJobsLimitData,
      color: "#1d7a80",
    },
  ];

  return <ReactApexChart options={chartOptions} series={series} type="bar" height={350} />;
};

export default BarChart;
