import React from "react";
const SearchInput = ({ value, onChange, placeholder,focus }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="border border-gray-300 rounded-md p-2 mb-2"
      onFocus={focus ? () => true : null}
    />
  );
};
export default SearchInput;
