import React, { useState, useEffect } from "react";

const CountdownTimer = ({ durationInSeconds, processAfterTimerStopped = null,text=null }) => {
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);

  
  let intervalId = null;
  useEffect(() => {
    intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    
  }, []); // Runs once on component mount

  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalId);
      processAfterTimerStopped(); // Call this function immediately if timeLeft is 0
    }
  }, [timeLeft]); // Re-run effect when timeLeft changes

  // Format time left into hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="mt-2 text-storygreen">
      {text && <p>{text}</p>}
      <b>{`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`}</b>
    </div>
  );
};

export default CountdownTimer;
