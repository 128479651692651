import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { auth, provider } from "../../firebase";
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import "../../App.css";
import { useUser } from "../../context/userContext";
import { Link } from "react-router-dom";

function BookList() {
  const { user } = useUser();
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchAllBooks = async () => {
      if (user) {
        try {
          //console.log("Fetching books BOOKLIST:", user.email);
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/booklist?email=${encodeURIComponent(user.email)}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setBooks(data);
        } catch (error) {
          console.error("Error fetching books for user:", error);
        }
      } else {
        setBooks([]);
      }
    };

    fetchAllBooks();
  }, [user]);

  const countPagesWithImages = (book) => {
    return Object.values(book.pages).filter((page) => page.imageURL).length;
  };

  const handleGenerateImages = async (bookId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/openai-generator/generate-images-for-book`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bookId }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
    } catch (error) {
      console.error("Error generating images:", error);
    }
  };

  const handleGenerateImagesWithStableDiffusion = async (bookId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/stablediffusion-generator/generate-images-for-book`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bookId }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Stable Diffusion images generation result:", result);
    } catch (error) {
      console.error("Error generating images with Stable Diffusion:", error);
    }
  };

  const [rotations, setRotations] = useState({});

  const handleMouseMove = (bookId, e) => {
    const { width, height, top, left } = e.target.getBoundingClientRect();
    const mouseX = e.clientX - left; // Mouse X position within the element
    const mouseY = e.clientY - top; // Mouse Y position within the element

    // Calculate rotation:
    // - For X-axis, it should depend on the Y position of the mouse (mouseY)
    // - For Y-axis, it should depend on the X position of the mouse (mouseX)
    const rotateX = ((height / 2 - mouseY) / height) * 30; // Adjust the factor (30) for rotation intensity
    const rotateY = ((mouseX - width / 2) / width) * 30;

    setRotations((prev) => ({ ...prev, [bookId]: { rotateX, rotateY } }));
  };

  const handleMouseLeave = (bookId) => {
    setRotations((prev) => ({ ...prev, [bookId]: { rotateX: 0, rotateY: 0 } }));
  };

  return (
    <div className="App">
      <NavigationBar />

      <div className="bg-storytan p-4 mx-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
          <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">Dashboard</h2>
          <div className="book-list grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-y-8 gap-x-8">
            {books?.map((book) => {
              if (!book.title) return null;

              // Use imageURLwebp if available, otherwise fall back to imageURL
              const imageUrl =
                book?.pages?.page4?.imageURLwebp || book?.pages?.page4?.imageURL || "/pattern.png";
              const rotation = rotations[book.id] || { rotateX: 0, rotateY: 0 };

              return (
                <div
                  key={book.id}
                  className="flex flex-col items-center book-item font-sans text-white uppercase text-2xl font-bold w-full rounded-md mx-auto mb-2"
                >
                  <a href={`/books/${book.id}`}>
                    <div
                      className="relative !h-64 !w-64 book-cover shadow-xl"
                      style={{
                        backgroundImage: `url('${imageUrl}')`,
                        backgroundSize: "cover",
                        transform: `rotateX(${rotation.rotateX}deg) rotateY(${rotation.rotateY}deg)`,
                        transition: "transform 0.1s ease-out",
                      }}
                      onMouseMove={(e) => handleMouseMove(book.id, e)}
                      onMouseLeave={() => handleMouseLeave(book.id)}
                    >
                      <div
                        className="absolute top-0 left-0 h-full w-full"
                        style={{ backgroundImage: `url('/book.png')`, backgroundSize: "cover" }}
                      ></div>
                      <div className="book-spine"></div>
                      <div className="relative top-48 text-storytan text-sm bg-black bg-opacity-70 leading-none font-bold font-serif block p-4">
                        {book.title}
                      </div>
                    </div>
                  </a>
                  {book.pdfUrl && (
                    <a
                      href={book.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-storyblue underline h-24"
                    >
                      View PDF
                    </a>
                  )}
                  <div className="flex flex-col gap-1 mt-1">
                    <div className="flex w-full justify-around">
                      <Link
                        // to={`/books/${book.id}`}
                        className="w-[49%] bg-storygreen text-white font-bold font-sans rounded-md p-2 text-xs capitalize"
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/books/${book.id}`}
                        className=" w-[49%] bg-storygreen text-white font-bold font-sans rounded-md p-2 text-xs capitalize"
                      >
                        View
                      </Link>
                    </div>
                    <Link
                      // to={`/books/${book.id}`}
                      className="w-[100%] bg-storygreen text-white font-bold font-sans rounded-md p-2 text-xs capitalize"
                    >
                      Purchase Physical Book
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookList;
