import React, { useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import SubmitButton from "../../components/Common/SubmitButton";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Heading from "../../components/Common/Heading";
import { HTTP_STATUS_200 } from "../../helper/Constant";
import {   Link, useNavigate } from "react-router-dom";
import Guide from "../../components/Common/Guide";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);
      const response = await callAPI("user/forgot-password", {
        email,
      });
      
      if (response.status === HTTP_STATUS_200) {
        setEmail("");
        Toast("Please Check Your Email");
      }
      else Toast(response.message, true);
      setLoader(false);
    } catch (err) {
      Toast("Unexpected Error Occurred", true);
      setLoader(false);
    }
  };

  return (
    <div className="App">
      <NavigationBar />
      <div className="bg-storytan p-4 mx-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
          {/* <Heading title="Forgot Password" /> */}
          <div className=" flex items-center align-end mb-4">
            <Heading title="Forgot Password" />
            <div className="mt-1">
              <Guide />
            </div>
          </div>
          <form
            id="form-reset-password"
            name="form-reset-password"
            className="mt-8 text-start"
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter Your Registered Email"
              className="mt-4 p-2 w-full rounded-md "
              required
            />

            <SubmitButton
              title="Send Password Reset Link"
              loader={loader}
              cancelOperation={() => navigate(-1)}
            />

            {/* <Link
              to="/"
              className="flex justify-center bg-storygreen text-white font-bold rounded-md p-2 mt-4"
            >
              Cancel
            </Link> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
