import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { CouponForm } from "./Forms/CouponForm";
import { callAPI } from "../../services/apiService";
import Dialogs from "../../components/Common/Dialog";
import { Toast } from "../../components/Common/Toast";
import SearchInput from "../../components/Common/SearchInput";
import Button from "../../components/Common/Button";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
import { useNavigate } from "react-router-dom";
import PaginationButtons from "../../components/Common/Pagination";
import { debounce } from "../../helper/Debounce";
import TableHeader from "../../components/Common/TableHeader";
import { NoRecordFound } from "../../components/Common/NoRecordFound";

const Coupons = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponDetails, setCouponDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  const searchCoupons = async () => {
    try {
      setLoading(true);
      const data = await callAPI(`admin/coupon-search?searchTerm=${searchTerm}`);
      setCoupons(data?.message);
      setLoading(false);
    } catch (error) {
      console.error("Error searching Coupons:", error);
      setLoading(false);
    }
  };

  const debouncedSearchCoupons = debounce(searchCoupons, 300);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      debouncedSearchCoupons();
    }
    if (!searchTerm) {
      getCoupons();
    }
  }, [searchTerm]);


  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const getCoupons = async () => {
    setLoading(true);
    const data = await callAPI(`admin/coupon?pageNumber=${currentPage}`);
    setCoupons(data?.coupons);
    setTotalPages(data?.totalPages);
    setLoading(false);
  };
  useEffect(() => {
    if (!searchTerm) {
      getCoupons();
    }
  }, [searchTerm]);

  useEffect(() => {
    getCoupons();
  }, [currentPage]);

  const handleEdit = async (id) => {
    const couponDetail = await callAPI(`admin/coupon/${id}`);
    if (couponDetail) {
      setShowForm(true);
      setCouponDetails(couponDetail);
    }
  };
  
  const handleDelete = async () => {
    try {
      await callAPI(`admin/coupon/delete`, {
        couponId,
      });
      Toast("Coupon Deleted Successfully");
      getCoupons();
      setOpenDialog(false);
    } catch (err) {
      Toast("Coupon Delete Failed", true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="p-0 md:p-4">
      {showForm ? (
        <CouponForm setShowForm={setShowForm} getCoupons={getCoupons} couponDetails={couponDetails} />
      ) : (
        <>
          <div>
            <div className="flex justify-between items-baseline gap-2 flex-wrap my-5">
              <Button
                title="New Coupon"
                className="w-40 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base p-[0.4rem]"
                onclick={() => {
                  setShowForm(true);
                  setCouponDetails(null);
                }}
              />
              <SearchInput placeholder={"Search Coupon"} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
          {loading ? (
            <div className="w-full h-80 flex justify-center items-center">
              <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
            </div>
          ) : (
            <>
              <div className="w-full overflow-auto  flex flex-col justify-between">
                <>
                  <div className="h-[17rem] md:h-[31rem]">
                    <table className="min-w-full  border border-gray-300 ">
                      {TableHeader([
                        "Coupon Code",
                        "Type",
                        "Apply On",
                        "Coupon Life",
                        "Amount",
                        "Expiry",
                        "",
                      ])}
                      <tbody>
                        {coupons && coupons.length > 0 ? (
                          coupons?.map((coupon, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                              <td className="text-left py-2 px-4 border-b">{coupon.couponCode}</td>
                              <td className="text-left py-2 px-4 border-b capitalize">{coupon.couponType}</td>
                              <td className="text-left py-2 px-4 border-b">
                                {coupon?.couponAppliedFor
                                  ? coupon?.couponAppliedFor.replace(/_/g, " ").replace("_", "").toUpperCase()
                                  : ""}
                              </td>
                              <td className="text-left py-2 px-4 border-b capitalize">
                                {coupon?.couponValidity}
                              </td>
                              <td className="text-left py-2 px-4 border-b">{coupon.couponAmount}</td>
                              <td className="text-left py-2 px-4 border-b">{coupon.couponExpiry}</td>
                              <td className="text-left py-4 px-4 border-b flex gap-5">
                                {/* <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  className="cursor-pointer"
                                  title="Edit Coupon"
                                  onClick={() => handleEdit(coupon.id)}
                                /> */}
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="cursor-pointer"
                                  title="Delete Coupon"
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setCouponId(coupon.id);
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoRecordFound colSpan={7} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </>
          )}
          <Dialogs openDialog={openDialog} handelClose={handleClose} handelProcess={handleDelete} />
        </>
      )}
    </div>
  );
};
export default Coupons;
