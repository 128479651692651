// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web apps Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCXhGLnZxZJhc-ALf79PC5iG6rDCM65Geo",
//   authDomain: "storybook-57515.firebaseapp.com",
//   projectId: "storybook-57515",
//   storageBucket: "storybook-57515.appspot.com",
//   messagingSenderId: "215295827716",
//   appId: "1:215295827716:web:d4b894a0d8a556645aba51",
//   measurementId: "G-8BKQ0W8L6V"
// };

/*
// Live
const firebaseConfig = {
  apiKey: "AIzaSyB8f_adEGojhl3fB6yRlO7ZV3Jh3N2TiWY",
  authDomain: "smart-acrobat-405704.firebaseapp.com",
  projectId: "smart-acrobat-405704",
  storageBucket: "smart-acrobat-405704.appspot.com",
  messagingSenderId: "70693930569",
  appId: "1:70693930569:web:70c1c90d17b07bca42e198",
  measurementId: "G-27VX7RMWS2",
};//*/

/*
//Dev
const firebaseConfig = {
  apiKey: "AIzaSyAQB3-CZGlnwfDPxNGsuLhJFtcdzaMuCa0",
  authDomain: "storybuilder-dev.firebaseapp.com",
  projectId: "storybuilder-dev",
  storageBucket: "storybuilder-dev.appspot.com",
  messagingSenderId: "693987010400",
  appId: "1:693987010400:web:edeed1f52e16503b00e005",
  measurementId: "G-NM20GBLVPB",
};//*/

// const isStaging = process.env.REACT_APP_APP_ENV === "staging" ? true : false;

const isStaging = process.env.REACT_APP_APP_ENV === "staging";

const firebaseConfig = {
  apiKey: process.env[`REACT_APP_APIKEY_${isStaging ? "DEV" : "PROD"}`],
  authDomain: process.env[`REACT_APP_AUTHDOMAIN_${isStaging ? "DEV" : "PROD"}`],
  projectId: process.env[`REACT_APP_PROJECT_ID_${isStaging ? "DEV" : "PROD"}`],
  storageBucket: process.env[`REACT_APP_STORAGE_BUCKET_${isStaging ? "DEV" : "PROD"}`],
  messagingSenderId: process.env[`REACT_APP_MESSAGING_SENDER_ID_${isStaging ? "DEV" : "PROD"}`],
  appId: process.env[`REACT_APP_APP_ID_${isStaging ? "DEV" : "PROD"}`],
  measurementId: process.env[`REACT_APP_MEASUREMENT_ID_${isStaging ? "DEV" : "PROD"}`],
};

// console.log("firebaseConfig", process.env.REACT_APP_APP_ENV, firebaseConfig);
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
// to ask user to select account everytime
provider.setCustomParameters({ prompt: "select_account" }); 


const db = getFirestore(app);

export { auth, provider, app, db };
