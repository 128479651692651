import React, { useEffect, useState } from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "../../../components/Common/Toast";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { HTTP_STATUS_200, LOCAL_STORAGE_TOKEN } from "../../../helper/Constant";
import Uploader from "../../Builder/Uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import Dialogs from "../../../components/Common/Dialog";

export const ArtStyleForm = ({ setShowForm, artStyleDetails, getArtStyles, setArtStyles }) => {
  const [loader, setLoader] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [currentPortrait, setCurrentPortrait] = useState(null);
  const [currentPortraitList, setCurrentPortraitList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Art Style name is required"),
    subPrompt: Yup.string().required("Prompt is required"),
    srefImage: Yup.mixed().required("Image is required"),
  });

  useEffect(() => {
    setPreviewImage(artStyleDetails?.sref_image);
  }, [artStyleDetails]);

  const formik = useFormik({
    initialValues: {
      artStyleId: null,
      name: "",
      subPrompt: "",
      srefImage: null,
      use_sref: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async (e) => {
    const formData = new FormData();
    formData.append("artStyleId", artStyleDetails?.id);
    formData.append("name", formik.values.name);
    formData.append("subPrompt", formik.values.subPrompt);
    formData.append("srefImage", formik.values.srefImage);
    formData.append("use_sref", formik.values.use_sref);

    let response;
    try {
      setLoader(true);

      let headers = {};
      const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
      if (token) headers.Authorization = `Bearer ${token}`;
      response = await fetch(`${process.env.REACT_APP_API_URL}/admin/artStyle/update`, {
        method: "POST",
        headers,
        body: formData,
      });

      /*
      if (!artStyleDetails) {
        response = await callAPI(
          "admin/artStyle/save",
          formData
          // {
          //   name: formik.values.name,
          //   subPrompt: formik.values?.subPrompt,
          //   srefImage: formik.values?.srefImage,
          // }
        );
      } else {
        response = await callAPI(
          "admin/artStyle/save",
          formData
          // {
          //   artStyleId: formik.values.artStyleId,
          //   name: formik.values.name,
          //   subPrompt: formik.values?.subPrompt,
          //   srefImage: formik.values?.srefImage,
          // }
        );
      }//*/
      setLoader(false);
      if (response?.status !== HTTP_STATUS_200) Toast(response?.message || response?.error, true);
      else {
        Toast("Art Style Saved Successfully");
        formik.resetForm();
        getArtStyles(setArtStyles);
        setShowForm(false);
      }
    } catch {
      Toast("Art Style Failed To Save", true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (artStyleDetails) {
      const values = {
        artStyleId: artStyleDetails?.id,
        name: artStyleDetails?.name,
        subPrompt: artStyleDetails?.sub_prompt,
        srefImage: artStyleDetails?.sref_image,
        use_sref: artStyleDetails?.use_sref ,
      };
      formik.setValues(values);
    }
  }, [artStyleDetails]);

  const handleImageChange = async (input) => {
    let file = input.target ? input.target.files[0] : input;
    if (!file) {
      console.error("No image selected");
      return;
    }
    setSelectedImage(file);
    setUploadedImageUrl(null);
    // setCurrentPortraitList([]);
    setCurrentPortrait(null);
    formik.setFieldValue("srefImage", file);
  };

  // console.log("first",formik?.values)
  return (
    <div className="bg-storytan mx-auto">
      <div className="max-w-7xl mx-auto">
        {artStyleDetails ? (
          <h1 className="text-storyblue text-xl text-start mb-4 capitalize font-bold">
            {`Edit:  ${artStyleDetails?.name}`}
          </h1>
        ) : (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">Create New Art Style</h1>
        )}
        <form
          id="form-admin-artStyles"
          name="form-admin-artStyles"
          className="text-start"
          onSubmit={formik.handleSubmit}
        >
          {/* <Field
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={(event) => {
              formik.setFieldValue("srefImage", event.currentTarget.files[0]); // Set image field value
            }}
          /> */}

          <div className="flex flex-col  gap-2 md:gap-4">
            <div className="flex flex-col gap-2">
              <label className="font-medium">Art Style Name</label>
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Art Style Name"
                className="p-2 w-full rounded-md"
                autoFocus={true}
              />
              <div className="text-red-600 mt-1">{formik.errors.name}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Art Style Prompt</label>
              <textarea
                type="text"
                name="subPrompt"
                rows={3}
                value={formik.values.subPrompt}
                onChange={formik.handleChange}
                placeholder="Art Style Prompt"
                className="p-2 w-full rounded-md"
              />
              <div className="text-red-600 mt-1">{formik.errors.subPrompt}</div>
            </div>

            <div className="flex gap-2 items-center">
              <label className="font-medium">Use sref: </label>
              <FontAwesomeIcon
                icon={formik.values.use_sref ? faToggleOn : faToggleOff}
                className={`h-8 w-8 cursor-pointer ${
                  formik.values.use_sref ? "text-storygreen" : "text-red-500"
                } `}
                title={formik.values.use_sref ? "Deactivate sref" : "Activate sref"}
                onClick={() => {
                  formik.setFieldValue("use_sref", !formik.values.use_sref);
                }}
              />
            </div>

            <div className="w-full   flex flex-col gap-2 md:flex-row items-center">
              <div className=" flex flex-col gap-2 w-full md:w-[45%]">
                <label className="font-medium">Art Style Image</label>
                {/* <Uploader onFileChange={handleImageChange} setPreviewImage={setPreviewImage} /> */}
                <Uploader
                  onFileChange={handleImageChange}
                  setPreviewImage={setPreviewImage}
                  title="Upload Art Style Image"
                />
                <div className="text-red-600 mt-1">{formik.errors.srefImage}</div>
              </div>
              <div className="mx-auto rounded-md ">
                {previewImage && (
                  <img
                    src={previewImage}
                    alt="preview"
                    className=" mt-8   w-[20rem] h-[20rem] rounded-md object-contain mx-auto"
                  />
                )}
              </div>
            </div>
          </div>
          <SubmitButton title="Save" loader={loader} cancelOperation={setShowForm} />
        </form>
      </div>
      {/* <Dialogs
        openDialog={openDialog}
        handelClose={handleClose}
        confirmMessage={`Are you sure you want to change "sref" status of ${artStyleDetails?.name}?`}
        handelProcess={handleChangeStatus}
      /> */}
    </div>
  );
};
