import { useState } from "react";
import {
    HTTP_STATUS_200,
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
} from "../../../helper/Constant";
import { callAPI } from "../../../services/apiService";
import { Toast } from "../../../components/Common/Toast";

const UserDetailModal = ({ user, isUserDetailModalOpen, onClose }) => {
  const [updatedData, setUpdatedData] = useState({ ...user });
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeSettings = (e, key) => {
    const { checked } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      settings: {
        ...prevData.settings,
        [key]: checked,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const data = {
        email: updatedData?.email,
        firstName: updatedData?.firstName,
        lastName: updatedData?.lastName,
        status: updatedData?.status,
        settings: updatedData?.settings,
      };

      const response = await callAPI(`admin/customer-profile-update`, data);
      if (response?.status ===HTTP_STATUS_200) {
        Toast("User Updated Successfully");
        onClose();
      }
      else  Toast(response?.message || "Failed To Update Profile", true);
      
    } catch (error) {
      Toast("Update user Status Failed",true);
    } finally {
      setLoader(false);
    }
  };

  // console.log("first", updatedData);
  return (
    <div
      className={`max-w-5xl mx-auto min-h-screen modal ${
        isUserDetailModalOpen ? "block" : "hidden"
      }`}
    >
      <h1 className="text-storyblue text-lg md:text-2xl font-bold text-start mb-4">View Profile</h1>
      <form className="" onSubmit={handleSubmit}>
        <div className=" w-full flex justify-between items-center ">
          <label htmlFor="email">Email:</label>
          <input
            className="mt-4 p-2 w-[70%] bg-white rounded-md"
            type="email"
            disabled
            id="email"
            name="email"
            value={updatedData.email}
            onChange={handleChange}
          />
        </div>
        {/* <div className=" w-full flex justify-between items-center ">
          <label htmlFor="creditBalance">Credit Balance:</label>
          <input
            disabled
            type="number"
            id="creditBalance"
            name="creditBalance"
            value={updatedData.creditBalance}
            onChange={handleChange}
            className="mt-4 p-2 w-[70%] bg-white rounded-md"
          />
        </div> */}
        <div className=" w-full flex justify-between items-center ">
          <label htmlFor="firstName">First Name:</label>
          <input
            disabled
            className="mt-4 p-2 w-[70%] bg-white rounded-md"
            type="text"
            id="firstName"
            name="firstName"
            value={updatedData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className=" w-full flex justify-between items-center ">
          <label htmlFor="lastName">Last Name:</label>
          <input
            disabled
            className="mt-4 p-2 w-[70%] bg-white rounded-md"
            type="text"
            id="lastName"
            name="lastName"
            value={updatedData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className=" w-full flex justify-between items-center ">
          <label htmlFor="status">Status:</label>
          <select
            disabled
            id="status"
            name="status"
            value={updatedData.status}
            onChange={handleChange}
            className="mt-4 p-2 w-[70%] bg-white rounded-md"
          >
            <option value={USER_STATUS_ACTIVE}>Active</option>
            <option value={USER_STATUS_INACTIVE}>In-Active</option>
          </select>
        </div>
        <div className=" md:ml-[15rem]">
          {updatedData?.settings &&
            Object.entries(updatedData?.settings).map(([key, value]) => {
              return (
                <div className=" w-full flex  items-center ">
                  <input
                    disabled
                    className="mt-4 p-2 w-[15%] cursor-pointer bg-white rounded-md "
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={value}
                    onChange={(e) => handleChangeSettings(e, key)}
                  />{" "}
                  <label className="capitalize mt-2 pt-2 cursor-pointer" htmlFor={key}>
                    {key.replace(/_/g, " ").replace("USER", "").toLocaleLowerCase()}
                  </label>
                </div>
              );
            })}
        </div>
        {/* <SubmitButton title="Save" loader={loader} cancelOperation={onClose} size="w-1/2" /> */}
        <div className="flex gap-3">
          <button
            className="w-full mt-10 flex justify-center bg-storygreen text-white hover:text-white font-bold rounded-md text-base p-2"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserDetailModal;
