import React, { useEffect } from "react";
import {
  RouterProvider
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
import { UserProvider } from "./context/userContext";
import { AppProvider } from "./context/appContext";
import { StoryProvider } from "./context/storyContext";
import RouterMain from "./routes";

const { REACT_APP_APP_ENV, REACT_APP_STRIPE_PUB_KEY_TEST, REACT_APP_STRIPE_PUB_KEY_LIVE } = process.env;


function App() {

  useEffect(() => {
    //Chat Bot
    const loadCrisp = () => {
      window.$crisp = [];
      // window.CRISP_WEBSITE_ID = "4f87b416-6723-4b45-a517-4ee62092d378";
      window.CRISP_WEBSITE_ID = "43f025bd-04e0-4021-9a1d-531309a357f5";

      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.async = 1;
      document.getElementsByTagName("head")[0].appendChild(script);
    };

    // Apply custom classes to adjust chat widget position on smaller screens
    const style = document.createElement("style");
    style.innerHTML = `
      @media (max-width: 1024px) {
        .crisp-client .crisp-client {
          right: 0 !important;
          bottom: 0 !important;
          max-width: calc(100% - 20px) !important; /* Ensure it stays within the viewport */
          overflow: hidden !important;
        }
      }
    `;

    const loadCookieEyes = () => {
      const script = document.createElement("script");
      script.src = "https://cdn-cookieyes.com/client_data/29131085552c2dc4ec09d641/script.js";
      script.async = 1;
      document.getElementsByTagName("head")[0].appendChild(script);
    };
    // const loadgoAffPro = ()=>{

    //    const script = document.createElement("script");
    //    script.src = "https://api.goaffpro.com/loader.js?shop=w6dmdvcn04";
    //    script.async = 1;
    //    document.getElementsByTagName("head")[0].appendChild(script);
    // }

    loadCrisp(); //*/
    if (REACT_APP_APP_ENV !== "staging") loadCookieEyes();
    // loadgoAffPro();
    // init("w6dmdvcn04");
  }, []);

  const stripePublicKey =
    REACT_APP_APP_ENV === "staging" ? REACT_APP_STRIPE_PUB_KEY_TEST : REACT_APP_STRIPE_PUB_KEY_LIVE;

  // Load the Stripe promise
  const stripePromise = loadStripe(stripePublicKey);

  return (
    <>
      <UserProvider>
        <AppProvider>
          <StoryProvider>
            <Elements stripe={stripePromise}>
              <div className="App bg-white min-h-screen">
                <RouterProvider router={RouterMain} >
                </RouterProvider>
              </div>
              <Toaster position="bottom-left" />
            </Elements>
          </StoryProvider>
        </AppProvider>
      </UserProvider>
    </>
  );
}

export default App;


