import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import "../../App.css";
import { useUser } from "../../context/userContext";
import { callAPI } from "../../services/apiService";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { useApp } from "../../context/appContext";
import Heading from "../../components/Common/Heading";
import { Toast } from "../../components/Common/Toast";
import {
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  HTTP_STATUS_500,
  
} from "../../helper/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer/Footer";
import { Menu } from "@headlessui/react";
import Dialogs from "../../components/Common/Dialog";
import { useStory } from "../../context/storyContext";
import {
  archiveBook,
  editStory,
  handleDeleteBook,
  unArchiveBook,
} from "../Builder/builderHelpers";
import { convertTimestampToDateTime } from "../../helper/helper";
import Guide from "../../components/Common/Guide";
import PlanPurchase from "../../components/Common/PlanPurchase";
import ThankYou from "../../components/Common/ThankYou";

function BookList() {
  const { user } = useUser();
  const { setStoryData } = useStory();
  const navigateTo = useNavigate();
  const { setDraftId } = useApp();
  const [books, setBooks] = useState([]);
  const [archivedBooks, setArchivedBooks] = useState("no");
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState([]);
  const [deletingBook, setDeletingBook] = useState(false);
  const [loadingMissingImage, setLoadingMissingImage] = useState([]);
  const [createImageLoader, setCreateImageLoader] = useState(false);
  const [bookUpdateLoader, setBookUpdateLoader] = useState(false);
  const [userCreditBalance, setUserCreditBalance] = useState(0);
  const [stripeSubscriptionStatus, setStripeSubscriptionStatus] = useState(true);
  const [purchasePlan, setPurchasePlan] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBook, setSelectedBook] = useState({ bookId: null, index: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [showThanksNote, setShowThanksNote] = useState(false);

  //console.log("selectedBook", selectedBook);
  useEffect(() => {
    if (user) {
      if (user?.stripeSubscriptionId) fetchBooks();
      else setStripeSubscriptionStatus(false);
    }
  }, [currentPage]);

  useEffect(() => {
    setUserCreditBalance(user?.creditBalance || 0);
    if (archivedBooks) showArchivedBooks();
    else fetchBooks();
  }, [user?.creditBalance, archivedBooks]);

  // const fetchBooks = async (archived = "no") => {
  //   setLoading(true);
  //   const books = await callAPI(`api/booklist?email=${user?.email}&archived=${archived}`);
  //   if (books?.status === HTTP_STATUS_200) setBooks(books?.books || []);
  //   else Toast(books?.message || books?.error);
  //   setLoading(false);
  // };
  const fetchBooks = async () => {
    setLoading(true);
    //FOR PAGINATION WITH REDIS PAGE NUMBER REQUIRED--LATER
    // const response = await callAPI(
    //   `api/booklist?email=${user?.email}&archived=${archivedBooks}&pageNumber=${currentPage}`
    // );
    const response = await callAPI(`api/booklist?email=${user?.email}&archived=${archivedBooks}`);
    if (response?.status === HTTP_STATUS_200) {
      setBooks(response?.books);
      setTotalPages(response?.totalPages);
      setLoading(false);
    } else {
      Toast(response?.message || response?.error);
    }
    setLoading(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // useEffect(() => {
  //   fetchBooks();
  // }, [currentPage]);

  const showArchivedBooks = async () => {
    setLoading(true);
    const response = await callAPI(
      `api/booklist?email=${user?.email}&archived=${archivedBooks}`
      //page number for calling with redis for pagination
      //`api/booklist?email=${user?.email}&archived=${archivedBooks}&pageNumber=${currentPage}`
    );
    if (response?.status === HTTP_STATUS_200) {
      setBooks(response?.books || []);
      setTotalPages(response?.totalPages);
      setLoading(false);
    } else Toast(response?.message || response?.error);
    setLoading(false);
  };

  const toggleLoaderPDF = (index, showLoader = true) => {
    const newLoadingState = [...loadingPDF];
    if (showLoader) newLoadingState[index] = !newLoadingState[index];
    else newLoadingState[index] = false;
    setLoadingPDF(newLoadingState);
  };
  // const toggleLoaderDeleteBook = (index, showLoader = true) => {
  //   const newLoadingState = [...deletingBook];
  //   if (showLoader) newLoadingState[index] = !newLoadingState[index];
  //   else newLoadingState[index] = false;
  //   setDeletingBook(newLoadingState);
  // };
  const toggleLoaderMissingImage = (index, showLoader = true) => {
    const newLoadingState = [...loadingMissingImage];
    if (showLoader) newLoadingState[index] = !newLoadingState[index];
    else newLoadingState[index] = false;
    setLoadingMissingImage(newLoadingState);
  };

  const updateBookText = async (bookId, pageIndex, updatedText, updatedScene) => {
    try {
      await callAPI("api/book-update/update-book-text", {
        bookId,
        pageIndex,
        updatedText,
        updatedScene,
      });
    } catch (error) {
      console.error("Error updating book:", error);
    }
  };

  // const archiveBook = async (book, fetchBooks) => {
  //   console.log("ArchiveBook");
  //   const response = await callAPI("api/book-update/archive-book", { bookId: book?.id });
  //   if (response.status === HTTP_STATUS_200) {
  //     Toast(response?.message);
  //     fetchBooks();
  //   } else Toast(response?.message || response?.error, true);
  // };

  // const unArchiveBook = async (book, fetchBooks) => {
  //   console.log("unArchiveBook");
  //   const response = await callAPI("api/book-update/unarchive-book", { bookId: book?.id });
  //   if (response.status === HTTP_STATUS_200) {
  //     Toast(response?.message);
  //     fetchBooks();
  //   } else Toast(response?.message || response?.error, true);
  // };

  const handleClose = () => {
    setOpenDialog(false);
  };
  // const handleDeleteBook = async () => {
  //   setDeletingBook(true);
  //   const response = await callAPI(`api/delete-book`, { bookId: selectedBook.bookId });
  //   if (response.status === 200) {
  //     localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
  //     localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
  //     //  setCurrentStep(0);
  //     Toast("Book Deleted Successfully");
  //     window.location.reload(); // Refresh the app
  //     setDeletingBook(false);

  //   } else {
  //     Toast("Unexpected Error deleting book", true);
  //     setDeletingBook(false);
  //   }
  // };

  const generatePdf = async (bookId, index) => {
    try {
      console.log("generatePdf index", index);
      toggleLoaderPDF(index);
      const response = await callAPI(`api/pdf-generator/pdf?bookId=${bookId}`);
      if (response?.pdfUrl) {
        const updatedBooks = [...books];
        updatedBooks[index].pdfUrl = response.pdfUrl;
        setBooks(updatedBooks);
      }
      toggleLoaderPDF(index, false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toggleLoaderPDF(index, false);
    }
  };

  const handleGenerateImages = async (bookId, index) => {
    try {
      toggleLoaderMissingImage(index);
      const response = await callAPI(`api/generateImages/${bookId}`);
      if (response?.response?.status === HTTP_STATUS_400) {
        Toast("Need Additional Image Credits", true);
        navigateTo("/ImageTokens");
      }
      toggleLoaderMissingImage(index, false);
    } catch (error) {
      console.error("Error generating images:", error);
      toggleLoaderMissingImage(index, false);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  const handleClick = () => {
    let type = archivedBooks === "yes" ? "no" : "yes";
    setArchivedBooks(type);
  };

  const openModal = (bookId, pageIndex, pageData) => {
    setSelectedPage({ ...pageData, bookId, pageIndex });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPage(null);
  };

  const [showAllPagesMap, setShowAllPagesMap] = useState({});
  const toggleShowAllPages = (bookId) => {
    setShowAllPagesMap((prevState) => ({
      ...prevState,
      [bookId]: !prevState[bookId],
    }));
  };
  const renderImageTracker = (book) => {
    const showAllPages = showAllPagesMap[book.id];
    const totalPageKeys = Object.keys(book?.pages);
    console.log(totalPageKeys, "totolpages");

    const initialPageCount = totalPageKeys.length < 7 ? totalPageKeys.length : 7; // Or dynamically determine the number of pages

    const displayPageCount = showAllPages ? totalPageKeys?.length : initialPageCount;
    let cells = [];
    for (let i = 1; i <= displayPageCount; i++) {
      const pageKey = `page${i}`;
      const pageData = book.pages[pageKey];
      const pageHasImage = pageData && pageData?.imageURL;
      const cellClass = pageHasImage ? "bg-storyblue" : "bg-gray-300";
      const textColor = pageHasImage ? "text-white" : "text-gray-700";
      cells.push(
        <div
          key={i}
          className={`h-8 w-full ${cellClass} ${textColor}  m-0.5 flex justify-center items-center text-xs cursor-pointer`}
          onClick={() => openModal(book.id, `page${i}`, book.pages[`page${i}`])}
        >
          {i}
        </div>
      );
    }
    const toggleButton = (
      <button
        className="text-sm text-center flex justify-center items-center  text-storygreen"
        onClick={() => toggleShowAllPages(book.id)}
      >
        {showAllPages ? (
          <FontAwesomeIcon
            icon={faCircleMinus}
            className="cursor-pointer text-xl text-storygreen"
            title="less"
          />
        ) : (
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="cursor-pointer text-xl text-storygreen"
            title="more"
          />
        )}
      </button>
    );
    return (
      <div className="grid grid-cols-8 gap-1 w-full">
        {cells} {totalPageKeys.length > initialPageCount && toggleButton}
      </div>
    );
  };

  const handleRecreateImage = async (bookId, pageIndex) => {
    if (userCreditBalance <= 0) {
      Toast("Additional Image Credits Needed", true);
      // navigateTo("/ImageTokens");
      return;
    }
    setCreateImageLoader(true);
    try {
      const response = await callAPI("api/book-update/recreate-image", {
        bookId,
        pageIndex,
      });
      if (response?.status === HTTP_STATUS_500) Toast("Image Not Generated", true);
    } catch (error) {
      console.error("Error recreating image:", error);
      Toast("Image Not Generated", true);
    } finally {
      setCreateImageLoader(false);
    }
  };

  // Updated Modal component
  const PageModal = () => {
    const textRef = useRef(null);
    const sceneRef = useRef(null);

    const handleUpdateBook = async () => {
      setBookUpdateLoader(true);
      const updatedText = textRef.current ? textRef.current.value : "";
      const updatedScene = sceneRef.current ? sceneRef.current.value : "";

      if (selectedPage && selectedPage.bookId && selectedPage.pageIndex) {
        try {
          await updateBookText(selectedPage.bookId, selectedPage.pageIndex, updatedText, updatedScene);
          closeModal(); // Close the modal after successful update
          setBookUpdateLoader(false);
        } catch (error) {
          console.error("Error updating book:", error);
          setBookUpdateLoader(false);
        }
      } else {
        console.error("Missing bookId or pageIndex");
        setBookUpdateLoader(false);
      }
    };

    if (!selectedPage) return null;

    return (
      <div
        className={`relative z-10 ${modalOpen ? "block" : "hidden"}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-8 md:p-4 text-center">
            <div className="relative transform overflow-hidden rounded-lg bg-storytan px-8 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-6xl">
              {/* Content of the modal */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="mt-2">
                  <h3 className="text-sm text-gray-500 font-bold">PAGE TEXT</h3>
                  <textarea
                    ref={textRef}
                    defaultValue={selectedPage?.text}
                    className="h-24 md:h-96 text-lg md:text-2xl w-full p-4 border-gray-400 border-2 text-gray-800"
                  />
                </div>
                <div className="mt-2">
                  <img
                    src={selectedPage?.imageURLwebp}
                    alt="Page"
                    className="mx-auto mt-4 w-48 md:w-96 h-48 md:h-96"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-sm text-gray-500 font-bold">PROMPT FOR IMAGE GENERATION </h3>
                  <textarea
                    ref={sceneRef}
                    defaultValue={selectedPage.scene}
                    className="h-24 md:h-96 text-lg md:text-2xl w-full p-4 border-gray-400 border-2 text-gray-800"
                  />
                </div>
              </div>
              <div className="float-right flex gap-2 mt-5 sm:mt-6">
                <button
                  type="button"
                  className="h-11 capitalize  flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base  text-white shadow-sm"
                  onClick={handleUpdateBook}
                  disabled={bookUpdateLoader}
                >
                  Update Text
                  <Loader loader={bookUpdateLoader} />
                </button>

                <button
                  type="button"
                  className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base   text-white shadow-sm"
                  onClick={() => handleRecreateImage(selectedPage.bookId, selectedPage.pageIndex)}
                  disabled={createImageLoader}
                >
                  New Image
                  <Loader loader={createImageLoader} />
                </button>
                <button
                  type="button"
                  className="capitalize inline-flex justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base   text-white shadow-sm"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleBookNavigation = (book) => {
    if (book?.coverURL) navigateTo(`/bookflip/${book.id}`);
    else editStory(book, setDraftId, setStoryData, navigateTo);
  };
  /*
  const editStory = async (book) => {
    try {
      const data = await callAPI("api/make-story-draft", { email: book.email, bookId: book.id });

      if (data) {
        const currentBookId = localStorage.getItem(LOCAL_STORAGE_BOOK_ID);
        if (currentBookId !== book.id) setIllustrationCharacter(null);
        
        localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
        localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
        localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
        setDraftId(data.bookId);
        navigateTo("/");
      }
    } catch (error) {}
  };//*/

  const goBack = () => {
    navigateTo(-1);
    setShowThanksNote(false);
  };

  return (
    <div className="App mx-auto">
      <NavigationBar />
      {showThanksNote ? (
        <ThankYou goBack={goBack} />
      ) : (
        <>
          <div className="w-full bg-storytan min-h-[90vh]">
            {/* <div className="bg-storytan p-0 md:p-4 mx-auto"> */}
            {purchasePlan ? (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* <PurchasePlan user={user} /> */}
                <PlanPurchase setShowThanksNote={setShowThanksNote} className={"justify-start"} />
              </div>
            ) : (
              <div className="max-w-7xl mx-auto p-4">
                <div className="flex justify-between items-baseline">
                  {/* <Heading title="My Library" /> */}
                  <div className=" flex items-center align-end mb-4">
                    <Heading title="My Library" />
                    <div className="mt-1">
                      <Guide />
                    </div>
                  </div>

                  <button
                    onClick={handleClick}
                    className="h-8 flex justify-center items-center pointer-grab text-white text-xs rounded-md bg-storyblue shadow-xl leading-none font-bold p-0 md:p-2 px-2 md:px-6 capitalize"
                  >
                    {archivedBooks === "yes" ? "Hide" : "Show"} Archived Stories
                  </button>
                </div>
                <div className="book-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-3">
                  {loading ? (
                    <div className="col-span-3 mx-auto ">
                      <h1 className="text-storyblue text-xl font-bold">
                        <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
                      </h1>
                    </div>
                  ) : stripeSubscriptionStatus && books?.length > 0 ? (
                    books?.map((book, index) => (
                      <div
                        key={book?.id}
                        className="  p-0 md:p-2 book-item font-sans text-white bg-storytan uppercase text-2xl font-bold rounded-md mx-auto mb-2"
                      >
                        <Link to="#" onClick={() => handleBookNavigation(book)}>
                          <div className="annie w-[18rem] md:w-[21rem] h-[21rem] object-cover mx-auto rounded-md shadow-xl mb-2 flex justify-center items-center relative">
                            <div
                              className="absolute h-full w-full grayscale rounded"
                              style={{
                                backgroundImage: `url('${
                                  book?.coverURL ? book?.coverURL : "/bookdraft.png"
                                }')`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                            <div
                              className="w-[16rem] h-[19rem] rounded-sm flex justify-center pt-[1rem]"
                              style={{
                                backgroundImage: `url('${"/texture.png"}')`,
                                backgroundSize: "cover",
                                zIndex: 1,
                              }}
                            >
                              {/* <div
                                className="w-[14rem] h-[14rem]  flex justify-center items-center relative rounded-sm"
                                style={{
                                  backgroundImage: `url('${
                                    book?.coverURL ? book?.coverURL : "/bookdraft.png"
                                  }')`,
                                  backgroundSize: "cover",
                                  backgroundSize: "336px",
                                  backgroundPosition: "50% 29%",
                                  backgroundRepeat: "no-repeat",
                                }}

                              > */}
                              <div
                                className="custom-background w-[14rem] h-[14rem] flex justify-center items-center relative rounded-sm"
                                style={{
                                  backgroundImage: `url('${
                                    book?.coverURL ? book?.coverURL : "/bookdraft.png"
                                  }')`,
                                  backgroundSize: window.innerWidth < 640 ? "340px" : "317px", // Adjust for small screens
                                  backgroundPosition: window.innerWidth < 640 ? "29% 24%" : "46% 22%", // Ad
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                <div className="relative flex flex-col items-center top-[9.1rem]">
                                  <h1 className="text-[14px] leading-[21px]  flex justify-center items-center text-center font-yuji-boku text-storyblue">
                                    {book?.coverURL ? book?.title : null}
                                  </h1>
                                  <h1 className="font-poppins text-[10px] leading-[25px] text-gray-600  mb-1 capitalize">
                                    {book?.coverURL
                                      ? `by ${user?.firstName} ${user?.lastName}`
                                      : `Autosaved - ${convertTimestampToDateTime(book.timestamp)}`}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <div className="w-[1rem] relative z-10 top-[-20.5rem] right-[-15.5rem] md:right-[-18.8rem]">
                          <Menu>
                            <Menu.Button className="w-8 pt-[3px] bg-storygreen hover:bg-storyblue rounded-md focus:outline-none">
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className="cursor-pointer text-white text-3xl"
                                title="Menu"
                              />
                            </Menu.Button>
                            <Menu.Items className="absolute right-0 top-[2rem] w-36 z-0 origin-top-right bg-gray-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="px-1 py-1 flex flex-col items-baseline">
                                {book?.coverURL && (
                                  <>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div className="flex items-baseline">
                                          <Link
                                            to={`/bookpurchase/${book.id}`}
                                            className="w-full h-8  text-storygreen text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                          >
                                            Purchase Story
                                          </Link>
                                        </div>
                                      )}
                                    </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                        <>
                                          <Link to={`/bookflip/${book.id}`}>
                                            <div className="w-full h-8  text-storygreen text-xs   hover:text-storyblue leading-none font-bold p-2 capitalize">
                                              FlipBook
                                            </div>
                                          </Link>
                                        </>
                                      )}
                                    </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                        <div className="flex items-baseline">
                                          {!book?.pdfUrl ? (
                                            <>
                                              <button
                                                onClick={() => {
                                                  generatePdf(book?.id, index);
                                                }}
                                                disabled={loadingPDF[index]}
                                                className="w-full flex items-center h-8  text-storygreen text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                              >
                                                Generate Pdf
                                                <Loader loader={loadingPDF[index]} />
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <Link
                                                to={book?.pdfUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="w-full h-8  text-storygreen text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                              >
                                                View PDF
                                              </Link>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Menu.Item>
                                  </>
                                )}

                                <Menu.Item>
                                  {({ active }) => (
                                    <div className="flex items-baseline">
                                      <button
                                        onClick={() => editStory(book, setDraftId, setStoryData, navigateTo)}
                                        className="w-full h-8  text-storygreen text-xs   hover:text-storyblue leading-none font-bold p-2 capitalize"
                                      >
                                        Edit Story
                                      </button>
                                    </div>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  {({ active }) => (
                                    <div>
                                      {archivedBooks === "yes" ? (
                                        <>
                                          <button
                                            onClick={() => unArchiveBook(book?.id, fetchBooks)}
                                            className="w-full flex items-center h-8  text-storygreen text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                          >
                                            UnArchive
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={() => archiveBook(book?.id, fetchBooks)}
                                            className="w-full flex items-center h-8  text-storygreen text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                          >
                                            Archive
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <div className="flex items-baseline">
                                      <button
                                        onClick={() => {
                                          setSelectedBook({
                                            ...selectedBook,
                                            bookId: book?.id,
                                            index,
                                          });
                                          setOpenDialog(true);
                                        }}
                                        className="w-full h-8  text-red-500 text-xs  hover:text-storyblue leading-none font-bold p-2 capitalize"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Menu>
                        </div>
                      </div>
                    ))
                  ) : stripeSubscriptionStatus && books?.length === 0 ? (
                    <div className="col-span-3 h-[60vh] flex items-center mx-auto">
                      <h1 className="text-storyblue  text-xl font-bold">No Book Found</h1>
                    </div>
                  ) : (
                    <div className="col-span-3 h-[60vh] mt-[4rem] flex flex-col items-center mx-auto">
                      <h1 className="text-storyblue  text-xl font-bold">
                        You are currently not Subscribed to any plan! <br></br> To unlock all features click
                        Purchase plan button below
                      </h1>

                      <button
                        type="button"
                        className="w-[50%] flex justify-center gap-2 bg-storygreen text-white font-bold rounded-md text-base h-11 items-center mt-5"
                        onClick={() => setPurchasePlan(true)}
                      >
                        Purchase Plan
                      </button>
                    </div>
                  )}
                </div>
                {/* <div className="flex justify-end mt-4 gap-2">
              {totalPages > 1 && (
                <PaginationButtons
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </div> */}
              </div>
            )}
          </div>
          <Dialogs
            openDialog={openDialog}
            handelClose={handleClose}
            handelProcess={() => handleDeleteBook(selectedBook.bookId, setDeletingBook, navigateTo)}
            confirmMessage="Are you sure you want to delete this Story?"
            loader={deletingBook}
          />
        </>
      )}
      <Footer />
      <PageModal />
    </div>
  );
}

export default BookList;
