import { faRegistered } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Admin/logo.webp";
import Button from "../../components/Common/Button";
import { useState } from "react";
import { EditStorySummary } from "./EditStorySummary";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BackCoverPages = ({
  bookData,
  editSummary = false,
  mode,
  setSummaryModal,
  targetRefs,  
}) => {
  return (
    <div className="h-full flex justify-center">
      {bookData &&
        (mode === "Page" ? (
          <div className="flex flex-col gap-2">
            <div
              className={`h-[350px] w-[350px]  md:w-[600px] md:h-[600px] flex flex-col rounded-lg mx-auto py-3 bg-gray-200 ${
                mode === "Grid" ? "" : "mb-4"
              } `}
              style={{
                backgroundImage: `url("/pattern.png")`,
                backgroundSize: "cover",
              }}
            ></div>
            <div className="flex justify-center items-center mb-4 md:mb-0  h-[350px] w-[350px] md:w-[600px] md:h-[600px] rounded-lg mx-auto bg-white relative">
              <p className="m-4 text-gray-500 text-base   text-center">
                This page is Intentionally left blank
              </p>
            </div>
            <div
              className="flex flex-col items-center"
              id="edit-summary"
              key="edit-summary"
              ref={(ref) => (targetRefs.current["edit-summary"] = ref)}
            >
              <div
                className="h-[350px] w-[350px] md:w-[600px] md:h-[600px] flex flex-col justify-between mx-auto  py-4 px-5 items-center rounded font-serif"
                style={{
                  backgroundImage: `url("/texture.png")`,
                  backgroundSize: "cover",
                }}
              >
                <div className="flex flex-col items-center gap-6 p-5 md:p-16 md:gap-12">
                  <h3 className="text-center text-xl align-center  md:text-2xl bold font-yuji-boku">
                    {bookData?.title}
                  </h3>
                  <h3 className="text-[10px] md:text-lg font-poppins text-black text-justify">
                    {bookData?.summary?.length > 400
                      ? bookData?.summary.slice(0, 400) + "..."
                      : bookData?.summary}
                  </h3>
                </div>
                <div className="w-full flex justify-end items-center gap-3">
                  <div className="flex items-baseline gap-1">
                    <img className="h-3 w-auto md:h-12" src={Logo} alt="Build My Story" />
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      {/* <Barcode value={bookData?.barCode} /> */}
                      <h3 className="text-[8px] md:text-base">www.buildmystory.ai</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                title="Edit Summary"
                onclick={() => {
                  setSummaryModal(true);
                  // setEditing({
                  //   ...editing,
                  //   bookId: bookData?.bookId,
                  //   pageIndex,
                  //   pageData: page,
                  //   type: "editText",
                  //   index: index * 2 + 1,
                  // });
                  // setShowEditTextIllustration(true);
                }}
                className="bg-storygreen flex  text-white  font-sans rounded-md p-2 text-xs m-4  h-8 w-[120px] justify-center"
              />
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col gap-4  md:flex md:flex-row md:justify-center md:gap-4 p-0 md:p-2 rounded  xs:flex-wrap">
            <div className="md:flex md:justify-center py-2 gap-4 xs:flex-wrap">
              {/* Last cover Black Page */}
              <div
                className="mb-4 md:mb-0 h-72 w-72 rounded-lg mx-auto relative"
                style={{
                  backgroundImage: `url("/pattern.png")`,
                  backgroundSize: "cover",
                }}
              ></div>
              {/* Back Cover last Blank Page */}
              <div
                className={`flex flex-col items-center gap-0 md:gap-16`}
                id="edit-summary"
                key="edit-summary"
                ref={(ref) => (targetRefs.current["edit-summary"] = ref)}
              >
                <div
                  className="flex flex-col justify-between mx-auto h-72 w-72 py-4 px-5 items-center rounded font-serif"
                  style={{
                    backgroundImage: `url("/texture.png")`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="flex flex-col items-center gap-6">
                    <h3 className="text-lg bold font-yuji-boku text-center">{bookData?.title}</h3>
                    <h3 className="text-[10px]  font-poppins text-black text-justify">
                      {bookData?.summary?.length > 300
                        ? bookData?.summary.slice(0, 300) + "..."
                        : bookData?.summary}
                    </h3>
                  </div>
                  <div className="w-full flex justify-end items-center gap-3">
                    <div className="flex items-baseline gap-1">
                      <img className="h-3 md:h-6 w-auto" src={Logo} alt="Build My Story" />
                      {/* <FontAwesomeIcon icon={faRegistered} style={{ color: "#062457" }} /> */}
                      {/* <FontAwesomeIcon icon="fa-regular fa-registered" /> */}
                    </div>
                    <div>
                      <div className="flex flex-col gap-1">
                        {/* <Barcode value={bookData?.barCode} /> */}
                        <h3 className="text-[8px]">www.buildmystory.ai</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  title="Edit Summary"
                  onclick={() => {
                    setSummaryModal(true);
                  }}
                  className="bg-storygreen flex  text-white  font-sans rounded-md p-2 text-xs m-4  h-8 w-[120px] justify-center"
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default BackCoverPages;
