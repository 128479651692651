import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { Toast } from "../../../components/Common/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
export const SettingForm = ({ setShowForm, getAllSettings, settingDetails }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const validationSchema = Yup.object().shape({
    settingKey: Yup.string().required("Setting Key is required"),
    settingValue: Yup.string().required("Setting Value is required"),
  });
  const formik = useFormik({
    initialValues: {
      settingKey: "",
      settingTitle: "",
      settingValue: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async (e) => {
    try {
      setLoader(true);
      const response = await callAPI("admin/update-admin-settings/", {
        settingKey: formik.values.settingKey,
        settingTitle: formik.values.settingTitle,
        settingValue: formik.values.settingValue,
      });
      if (response?.error) Toast(response?.error, true);
      else {
        Toast("Setting Saved Successfully");
        getAllSettings();
        setShowForm(false);
      }
      setLoader(false);
      formik.resetForm();
    } catch {
      Toast("Setting Failed To Save", true);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (settingDetails) {
      const values = {
        settingKey: settingDetails[0]?.id,
        settingTitle: settingDetails[0]?.settingTitle,
        settingValue: settingDetails[0]?.settingValue,
      };
      formik.setValues(values);
    }
  }, [settingDetails]);
  return (
    <div className="bg-storytan mx-auto">
      <div className="max-w-7xl mx-auto min-h-screen">
        <h1 className="text-storyblue text-xl font-bold text-start mb-4">Edit Setting</h1>
        <form id="form-setting" name="form-setting" className="text-start" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-2">
            <label className="font-medium">Setting Key</label>
            <input
              type="text"
              name="settingKey"
              value={formik.values.settingKey}
              onChange={formik.handleChange}
              placeholder="Setting Key"
              className="p-2 w-full rounded-md text-[#9EA4B0]"
              readOnly
            />
            <div className="text-red-600 mt-1">{formik.errors.settingKey}</div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium">Setting Title</label>
            <input
              type="text"
              name="settingTitle"
              value={formik.values.settingTitle}
              onChange={formik.handleChange}
              placeholder="Setting Title"
              className="p-2 w-full rounded-md"
              autoFocus={true}
            />
            <div className="text-red-600 mt-1">{formik.errors.settingTitle}</div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium">Setting Value</label>
            <input
              type="text"
              name="settingValue"
              value={formik.values.settingValue}
              onChange={formik.handleChange}
              placeholder="Setting Value"
              className="p-2 w-full rounded-md"
            />
            <div className="text-red-600 mt-1">{formik.errors.settingValue}</div>
          </div>
          <SubmitButton title="Save" loader={loader} cancelOperation={setShowForm}/>
        </form>
      </div>
    </div>
  );
};
