import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare, faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { callAPI } from "../../services/apiService";
import Dialogs from "../../components/Common/Dialog";
import { Toast } from "../../components/Common/Toast";
import Button from "../../components/Common/Button";
import { HTTP_STATUS_200, LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../components/Common/TableHeader";
import PaginationButtons from "../../components/Common/Pagination";
import { NoRecordFound } from "../../components/Common/NoRecordFound";
import { getArtStyles } from "../Builder/builderHelpers";
import { ArtStyleForm } from "./Forms/ArtStyleForm";
const ArtStyles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [artStyleId, setArtStyleId] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [artStyles, setArtStyles] = useState([]);
  const [artStyleDetails, setArtStyleDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  useEffect(() => {
    getArtStyles(setArtStyles);
  }, []);
  
// console.log("artstyles",artStyles)
  useEffect(() => {
    if (artStyleDetails) setShowForm(true);
  }, [artStyleDetails]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };
  const handleChangeStatus = async () => {
    try {
      const response = await callAPI(`admin/artStyle/status`, {
        artStyleId,
      });
      // console.log(response, "response");
      if (response?.status === HTTP_STATUS_200) {
        Toast("Plan Deleted Successfully");
        getArtStyles(setArtStyles);
      } else Toast(response?.message, true);
      setOpenDialog(false);
    } catch (err) {
      Toast("Plan Delete Failed", true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <div className="p-0 md:p-4">
      {showForm ? (
        <ArtStyleForm
          setShowForm={setShowForm}
          artStyleDetails={artStyleDetails}
          getArtStyles={getArtStyles}
          setArtStyles={setArtStyles}
        />
      ) : (
        <>
          <div>
            <div className="flex justify-between items-baseline flex-wrap gap-2 my-5">
              {/* <Button
                title="New Art Style"
                className="w-40 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base p-[0.4rem]"
                onclick={() => {
                  setShowForm(true);
                  setArtStyleDetails(null);
                }}
              /> */}
              {/* <SearchInput placeholder={"Search Plan"} onChange={(e) => setSearchText(e.target.value)} /> */}
            </div>
          </div>
          {loading ? (
            <div className="w-full h-80 flex justify-center items-center">
              <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
            </div>
          ) : (
            <div className="w-full overflow-auto h-[55vh] md:h-[63vh] flex flex-col justify-between">
              <div className="h-[17rem] md:h-[34rem]">
                <table className="min-w-full border border-gray-300">
                  {TableHeader(["S#", "Name", "Prompt", "Image", "Status", ""])}
                  <tbody>
                    {artStyles && artStyles?.length > 0 ? (
                      artStyles?.map((artStyle, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                          <td className="text-left py-2 px-4 border-b capitalize">
                            {/* {artStyle?.id.replaceAll("_", " ")} */}
                            {++index}
                          </td>
                          <td className="text-left py-2 px-4 border-b capitalize">{artStyle?.name}</td>
                          <td className="text-left py-2 px-4 border-b">{artStyle?.sub_prompt}</td>
                          <td className="text-left py-2 px-4 border-b capitalize">
                            <img
                              src={artStyle?.sref_image}
                              alt="preview"
                              className="w-[4rem] h-[4rem] rounded-md object-contain mx-auto"
                            />
                          </td>
                          <td className="text-left py-2 px-4 border-b capitalize">
                            {artStyle?.status ? "Active" : "In Active"}
                          </td>
                          <td className="text-left py-4 px-4 border-b flex gap-5">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="cursor-pointer"
                              title="Edit Art Style"
                              onClick={() => setArtStyleDetails(artStyle)}
                            />
                            <FontAwesomeIcon
                              icon={artStyle?.status ? faToggleOn : faToggleOff}
                              className={`h-4 w-6 cursor-pointer ${
                                artStyle?.status ? "text-storygreen" : "text-red-500"
                              } `}
                              title={artStyle?.status ? "Deactivate" : "Activate"}
                              onClick={() => {
                                setOpenDialog(true);
                                setArtStyleId(artStyle.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFound colSpan={6} />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          )}
          <Dialogs
            openDialog={openDialog}
            handelClose={handleClose}
            confirmMessage={`Are you sure you want to change art style status?`}
            handelProcess={handleChangeStatus}
          />
        </>
      )}
    </div>
  );
};
export default ArtStyles;
