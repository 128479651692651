import { faFileLines, faGrip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToolBar = ({ setMode }) => {
  return (
    <div className="sticky top-44 flex-col justify-center gap-4 rounded bg-white min-w-[4rem] h-[45rem] hidden lg:flex">
      <div className="cursor-pointer" onClick={() => setMode("Grid")}>
        <FontAwesomeIcon icon={faGrip} className=" text-3xl text-storyblue" title="Switch to Grid Mode" />
        <p className="text-storyblue">Grid</p>
      </div>
      <div className="cursor-pointer" onClick={() => setMode("Page")}>
        <FontAwesomeIcon
          icon={faFileLines}
          className=" text-3xl text-storyblue"
          title="Switch to Page Mode"
        />
        <p className="text-storyblue">Page</p>
      </div>
    </div>
  );
};

export default ToolBar;