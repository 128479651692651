import React, { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Dialogs from "../../components/Common/Dialog";
import SearchInput from "../../components/Common/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { convertTimestampToDateTime } from "../../helper/helper";
import { Link, useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_TOKEN, ORDER_TYPE_BOOK_PURCHASE } from "../../helper/Constant";
import TableHeader from "../../components/Common/TableHeader";
import PaginationButtons from "../../components/Common/Pagination";

const PhysicalBookOrder = ({ selectedUserEmail, setSelectedUserEmail, onOptionClick }) => {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [allOrders, setAllOrders] = useState([]);
  const [order, setOrder] = useState([]);
  const [viewBooks, setViewBooks] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });
  // const getAllOrders = async () => {
  //   try {
  //     setLoader(true);
  //     const response = await callAPI("admin/all-orders");
  //     // console.log("orderss", response);
  //     setAllOrders();
  //     setLoader(false);
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };
  // useEffect(() => {
  // getAllOrders();
  // }, []);

  const getUserOrders = async () => {
    try {
      setLoader(true);
      const response = await callAPI(
        `user/get-user-order-history?email=${selectedUserEmail}&pageNumber=${currentPage}`
      );
      //console.log("resss", response);
      setOrder(response?.orders);
      setTotalPages(response?.totalPages);
      setLoader(false);
    } catch (error) {
      Toast("No Book Orders Found", true);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (selectedUserEmail) getUserOrders();
  }, [selectedUserEmail, currentPage]);

  // useEffect(() => {
  //   getAllOrders();
  // }, [order, currentPage]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };



  // const getOrderDetails = async (orderId) => {
  //   try {
  //     setLoader(true);
  //     // const response = await callAPI(`admin/order-details?orderId=${orderId}`);
  //     // setSelectedOrderDetails(response?.data);
  //     setLoader(false);
  //   } catch (error) {
  //     Toast("Failed to fetch order details", true);
  //     setLoader(false);
  //   }
  // };
  // const handleOrderClick = (orderId) => {
  //   getOrderDetails(orderId);
  // };

  //  open Dialogs
  const openDialog = (user) => {
    if (user) {
      setSelectedUser(user);
      setViewBooks(true);
    }
  };
  const closeDialog = () => {
    setViewBooks(false);
    setSelectedUser(null);
  };

  const calculateBookPurchaseTotalAmount = (order) => {
    const bookCost = (parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)).toFixed(2);
    let qty = 1;
    if (order?.orderType === ORDER_TYPE_BOOK_PURCHASE) qty = parseInt(order?.quantity || 0);
    const total = (
      bookCost * qty +
      parseFloat(order?.shippingAmount) -
      parseFloat(order?.couponDiscount || 0)
    ).toFixed(2);
    return total;
  };

  const orderDetail = (order) => {
    if (order?.orderNumber) {
      return (
        <Link
          className="py-2 text-center px-4 border-b cursor-pointer underline text-storygreen"
          onClick={() => setSelectedOrderDetails(order)}
        >
          {order?.orderNumber}
        </Link>
      );
    } else return <div className="py-2 text-center px-4 border-b cursor-pointer text-storygreen">-</div>;
  };

  return (
    <div className="p-4">
      {loader ? (
        <div className="w-full h-80 flex justify-center items-center annie">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className={`flex items-baseline flex-wrap gap-2 ${
              selectedUserEmail ? "justify-between" : "justify-end"
            }  mb-2`}
          >
            {selectedUserEmail && (
              <button
                className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2"
                onClick={() => {
                  if (selectedOrderDetails) {
                    setSelectedOrderDetails(null);
                  } else {
                    setSelectedUserEmail(null);
                    onOptionClick("Customers");
                  }
                }}
              >
                Back
              </button>
            )}
            <SearchInput placeholder={"Search Order"} />
          </div>
          {selectedUserEmail ? (
            <>
              {selectedOrderDetails && <h1 className="text-xl font-bold">Order - Shipping Details</h1>}
              <h1 className="flex my-4">Customer Email: {selectedUserEmail}</h1>
              {selectedOrderDetails ? (
                <table className="min-w-full border border-gray-300">
                  {/* <tbody>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Order #</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.orderNumber}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing Address 1 </th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingAddress1}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Billing Address 2</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingAddress2}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing CardHolder Name</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingCardHolderName}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Billing City</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.billingCity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing Postal Code</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingPostalCode}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Book ID</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.bookId}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Book Price</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.bookPrice}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Book Quantity</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.bookQuantity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Address 1</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shippingAddress1}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping Address 2</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shippingAddress2}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Amount</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingAmount}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping City</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingCity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">shipping Email</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingEmail}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping State</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingState}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Zip</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingZip}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipstation OrderId</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shipstationOrderId}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Zip</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingZip}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Status</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.status}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">DateTime</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.timestamp &&
                          convertTimestampToDateTime(selectedOrderDetails?.timestamp)}
                      </td>
                    </tr>
                  </tbody> */}
                  <tbody>
                    <tr >
                      <th className="text-left py-2 px-4 border-b">Order #</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.orderNumber}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Book ID</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.bookId}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Book Name</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.bookName}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Book Quantity</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.quantity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Book Price</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.price}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping Amount</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingAmount}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Coupon</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.coupon || "-"}</td>
                    </tr>

                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Coupon Discount</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.couponDiscount || "-"}
                      </td>
                    </tr>
                    {/* <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Total Amount </th>
                      <td className="text-left py-2 px-4 border-b">
                        {parseFloat(selectedOrderDetails?.couponDiscount)}
                      </td>
                    </tr> */}
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing Address 1 </th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingAddress1}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Billing Address 2</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingAddress2}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing CardHolder Name</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingCardHolderName}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Billing City</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.billingCity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Billing Postal Code</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.billingPostalCode}
                      </td>
                    </tr>

                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping Address 1</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shippingAddress1}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Address 2</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shippingAddress2}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping City</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingCity}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">shipping Email</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingEmail}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipping State</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingState}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Zip</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingZip}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Shipstation OrderId</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.shipstationOrderId}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">Shipping Zip</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.shippingZip}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="text-left py-2 px-4 border-b">Status</th>
                      <td className="text-left py-2 px-4 border-b">{selectedOrderDetails?.status}</td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 px-4 border-b">DateTime</th>
                      <td className="text-left py-2 px-4 border-b">
                        {selectedOrderDetails?.timestamp &&
                          convertTimestampToDateTime(selectedOrderDetails?.timestamp)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : order && order?.length > 0 ? (
                <div className="w-full overflow-auto">
                  <table className="min-w-full border border-gray-300">
                    {TableHeader([
                      "Date",
                      "Description",
                      "Qty",
                      "Order Number",
                      "Sub-Total",
                      "Coupon",
                      "Discount",
                      "Total",
                      "Shipping Status",
                      " ",
                    ])}
                    <tbody>
                      {order &&
                        order?.map((order, index) => (
                          <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                            <td className="py-2 text-left px-4 border-b">
                              {convertTimestampToDateTime(order?.timestamp)}
                            </td>
                            <td className="py-2 text-left px-4 border-b capitalize">
                              {order?.orderType && order?.orderType.replace("_", " ")}
                            </td>
                            <td className="py-2 text-left px-4 border-b">{order?.quantity}</td>
                            <td className="py-2 text-left px-4 border-b">{orderDetail(order)}</td>
                            <td className="py-2 text-left px-4 border-b">
                              ${" "}
                              {(
                                parseFloat(order?.price) +
                                parseFloat(order?.additionalPageCost) +
                                parseFloat(order?.shippingAmount || 0)
                              ).toFixed(2)}
                            </td>
                            <td className="py-2 text-left px-4 border-b"> {order?.coupon || "-"}</td>
                            <td className="py-2 text-left px-4 border-b">
                              $ {order?.couponDiscount || "0.00"}
                            </td>
                            <td className="py-2 text-left px-4 border-b">
                              $
                              {/* {(parseFloat(order?.price) - parseFloat(order?.couponDiscount || 0)).toFixed(2)} */}
                              {calculateBookPurchaseTotalAmount(order)}
                            </td>
                            <td className="py-2 text-left px-4 border-b capitalize">
                              {(order?.status && order?.status?.replace("_", " ")) || "-"}
                            </td>
                            <td className="py-2 text-left px-4">
                              <div className="flex justify-center items-center text-center gap-2">
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="cursor-pointer"
                                  title="View Order Details"
                                  onClick={() => setSelectedOrderDetails(order)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end mt-4 gap-2">
                    {totalPages > 1 && (
                      <PaginationButtons
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex h-[20rem] justify-center items-center">
                  <h1 className="text-storyblue font-bold mt-[5rem]">No Orders Found</h1>
                </div>
              )}
            </>
          ) : (
            <>
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="text-left py-2 px-4 border-b">Customer Email</th>
                    <th className="text-left py-2 px-4 border-b">Order Id</th>
                    <th className="text-left py-2 px-4 border-b">Cost</th>
                    <th className="text-left py-2 px-4 border-b">Status</th>
                    <th className="text-left py-2 px-4 border-b"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* {displayedUsers.map((user, index) => ( */}
                  <tr
                  // key={index}
                  // className={index % 2 === 0 ? "bg-gray-100" : ""}
                  >
                    <td className="text-left py-2 px-4 border-b">hi@gmail.com</td>
                    <td className="text-left py-2 px-4 border-b">1cxSe3FsF1D4sdd5</td>
                    <td className="text-left py-2 px-4 border-b">$ 99.99</td>
                    <td className="text-left py-2 px-4 border-b">Delivered</td>
                    <td className="flex gap-4 py-4 px-4 border-b">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="cursor-pointer text-xl text-storyblue"
                        title="Details"
                        // onClick={() => openDialog(user)}
                      />
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className="cursor-pointer text-xl text-storyblue"
                        title="Download PDF"
                        // onClick={() => getUserBooks(user.email)}
                      />
                    </td>
                  </tr>
                  {/* ))} */}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
      <Dialogs
        openDialog={viewBooks}
        handelClose={closeDialog}
        handelProcess={() => {
          closeDialog();
        }}
        selectedUser={selectedUser}
      />
    </div>
  );
};
export default PhysicalBookOrder;
