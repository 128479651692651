import { callAPI } from "../services/apiService";
import CryptoJS from "crypto-js";
import { LOCAL_STORAGE_STORY_STEP } from "./Constant";

export const getStoryWordsCount = (age) => {
  const wordRanges = {
    "0-2_years": { min: 0, max: 50 },
    "3-6_years": { min: 500, max: 1000 },
    "7-9_years": { min: 2000, max: 4000 },
    "10-12_years": { min: 3000, max: 5000 },
    "13-14_years": { min: 4000, max: 6000 },
  };

  return wordRanges[age] || { min: 0, max: 0 };
};

export const generatePDF = async (bookId) => {
  try {
    const response = await callAPI(`api/pdf-generator/pdf?bookId=${bookId}`); //await fetch(`${process.env.REACT_APP_API_URL}/api/pdf-generator/pdf?bookId=${bookId}`);
    return response?.pdfUrl;
  } catch (error) {
    console.error("Error generating PDF:", error);
    return null;
  }
};

export const getUSStates = () => {
  return [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
};

export const encryptToken = (token, secretKey) => {
  // Generate a random IV (16 bytes for AES)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the token with AES using the secret key and IV
  const encryptedData = CryptoJS.AES.encrypt(token, secretKey, { iv }).toString();

  // Convert IV to base64 string
  const ivString = CryptoJS.enc.Base64.stringify(iv);

  // Prepend IV to the encrypted data, separated by a delimiter (you can choose your own delimiter)
  const encryptedTokenWithIV = ivString + "|" + encryptedData;

  return encryptedTokenWithIV;
};

export const convertTimestampToDateTime = (ts, onlyDate = false) => {
  if (ts) {
    const timestamp = new Date(ts._seconds * 1000 + ts._nanoseconds / 1000000);
    // return timestamp.toLocaleString();
    if (onlyDate) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[timestamp.getMonth()];
      const day = timestamp.getDate();
      const year = timestamp.getFullYear();

      return `${month} ${day}, ${year}`;
    } else {
      return timestamp.toLocaleString();
    }
  } else return "";
};

// export const goToTopOfPage = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };


export const goToTopOfPage = (scrollElement= window) => {
// console.log("go to top called", scrollElement);
scrollElement.scrollTo(0, 0);
  // scrollElement.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });

}

export const scrollToArea = (targetRefs, id) => {
  //Make Sure timeout is important
  setTimeout(() => {
    const targetDiv = targetRefs.current[id];
    if (targetDiv) targetDiv.scrollIntoView({ behavior: "auto" });
  }, 100);
};

export const getCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
};

export const setCurrentStoryStep = (step) => {
  localStorage.setItem(LOCAL_STORAGE_STORY_STEP, step);
};

export const makeDropdownOptions = (options) => {
  return options.map((option) => {
    return <option value={option.value}>{option.label}</option>;
  });
};

export const getCurrentTimestamp = () => {
  const now = new Date();
  const seconds = Math.floor(now.getTime() / 1000);
  const nanoseconds = now.getMilliseconds() * 1e6;

  return {
    _seconds: seconds,
    _nanoseconds: nanoseconds,
  };
};

export const replaceFromString = (target, replaceItems) => {
  const escapedReplaceItems = replaceItems.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

  // Create a regular expression that matches any of the characters in replaceItems
  const regex = new RegExp(`[${escapedReplaceItems}]`, "g");

  // Replace all matches with a space
  const replacedString = target.replace(regex, " ");

  return replacedString;
};

export const capitalizeFirstCharacter = (str) => {
  if (!str) return str; // If the string is empty or null, return it as is.
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const secondsToMinutesAndSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertMidjourneyTimestampToDateTime = (dateStr) => {
  try {
    const date = new Date(dateStr);

    // Extract the parts of the date
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Format the date as MM:HH dd/mm/yyyy
    return `${hours}: ${minutes} ${day}/${month}/${year}`;
  } catch (error) {
    console.log("error converting timestamp", error);

    return "";
  }
};
