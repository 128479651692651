import React, { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Dialogs from "../../components/Common/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { SettingForm } from "./Forms/SettingForm";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
const Settings = () => {
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [settings, setAllSettings] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [settingDetails,setSettingDetails]=useState(null);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(settings && settings.length / itemsPerPage);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  const getAllSettings = async () => {
    try {
      setLoader(true);
      const data = await callAPI("admin/get-admin-settings");
      setAllSettings(data?.settings);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getAllSettings();
  }, []);

  //   const handleDeleteSetting = async () => {
  //     try {
  //       //   await callAPI(`admin/coupon/delete`, {
  //       //     couponId,
  //       //   });
  //       //   Toast("Customer Deleted Successfully");
  //       setOpenDialog(false);
  //     } catch (err) {
  //       Toast("Setting Delete Failed", true);
  //     }
  //   };
  const handleClose = () => {
    setOpenDialog(false);
  };
  // Logic to slice array based on current page
  const listSettings =
    settings && settings.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage);
  // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleEditSetting = (settingKey) => {
    const settingInfo = settings.filter((val) => val.id === settingKey);
    setSettingDetails(settingInfo);
    setShowForm(true);
  };

  return (
    <div className="p-0 md:p-4">
      {loader ? (
        <div className="w-full h-80 flex justify-center items-center">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : showForm ? (
        <SettingForm
          setShowForm={setShowForm}
          getAllSettings={getAllSettings}
          settingDetails={settingDetails}
        />
      ) : (
        <div className="flex flex-col">
          <>
            <div className="w-full overflow-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="text-left py-2 px-4 border-b">Setting Title</th>
                    <th className="text-left py-2 px-4 border-b">Setting Value</th>
                    <th className="text-left py-2 px-4 border-b"></th>
                  </tr>
                </thead>
                <tbody>
                  {listSettings &&
                    listSettings.map((setting, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                        <td className="text-left py-2 px-4 border-b">{setting?.settingTitle}</td>
                        <td className="text-left py-2 px-4 border-b">{setting?.settingValue}</td>
                        <td className="text-left py-4 px-4 border-b flex gap-5">
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="cursor-pointer"
                            title="Edit Setting"
                            onClick={() => handleEditSetting(setting?.id)}
                          />
                          {/* <FontAwesomeIcon
                          icon={faTrashCan}
                          className="cursor-pointer"
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                        /> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* <div className="flex justify-end mt-4 gap-2">
              <div>Total Settings: {settings && settings?.length}</div>
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                containerClassName="pagination flex gap-2"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                activeLinkClassName="font-bold"
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                onPageChange={handlePageChange}
              />
            </div> */}
          </>
        </div>
      )}
      {/* <Dialogs openDialog={openDialog} handelClose={handleClose} handelProcess={handleDeleteSetting} /> */}
    </div>
  );
};
export default Settings;
