import { useEffect } from "react";
import { callAPI } from "../../services/apiService";
import Button from "../../components/Common/Button";
import { goToTopOfPage } from "../../helper/helper";
import { LOCAL_STORAGE_EDIT_MODE } from "../../helper/Constant";
import { useApp } from "../../context/appContext";

const ChooseCover = ({
  bookData,
  selectedCoverUrl,
  setSelectedCoverUrl,
  setShowBookCover,
  showBookCover,
  displayBookPages,
  setStateData,
  stateData,
  className = `w-[14rem] h-[14rem] lg:h-72 lg:w-72 rounded-lg hover:border-4 hover:border-storygreen bg-gray-200 cursor-pointer`,
}) => {

const{setBookDataModified}=useApp()


  useEffect(() => {
    goToTopOfPage();
  }, []);

  const handleUpdateCover = async () => {
    await callAPI("api/update-cover", {
      bookId: bookData?.bookId,
      coverURL: selectedCoverUrl,
    });
     if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE) === "yes") setBookDataModified(true);
    setStateData({ ...stateData, showDownloadPDF: true });
    displayBookPages();
  };

  return (
    <>
      {Object.entries(bookData?.pages).map(([pageIndex, page]) => {
        const isSelected = selectedCoverUrl === (page.imageURLwebp || page.imageURL);
        const imageClass = isSelected ? "border-4 border-storyblue" : selectedCoverUrl ? "opacity-50" : "";

        return (
          <div
            key={pageIndex}
            className={`${className} ${imageClass}`}
            style={{
              backgroundImage: `url('${page.imageURLwebp || page.imageURL || "/loading.gif"}')`,
              backgroundSize: "contain",
              margin: "auto",
            }}
            onClick={() => {
              setSelectedCoverUrl(page.imageURLwebp || page.imageURL);
              // setIsCoverSelected(true);
            }}
          ></div>
        );
      })}

      <div
        // className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
        className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 text-center mt-8 w-full flex gap-4 justify-center"
      >
        <Button
          title=" Close"
          // loader={loader}
          onclick={() => {
            setShowBookCover(!showBookCover);
            setStateData({ ...stateData, showDownloadPDF: true });
          }}
          className="submit-button w-[45vw] lg:w-[40vw] flex justify-center gap-3 max-w-7xl bg-storygreen  text-white font-bold rounded-md text-base h-11 items-center"
        />
        <Button
          title="Choose Cover"
          // loader={loader}
          disabled={!selectedCoverUrl}
          onclick={() => {
            setShowBookCover(!showBookCover);
            handleUpdateCover();
          }}
          className={`submit-button w-[45vw] lg:w-[40vw] whitespace-nowrap flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-base h-11 items-center ${
            selectedCoverUrl ? "" : "opacity-50"
          }`}
        />
        {/* <button
          //   onClick={startStepFive}
          onClick={() => {
            setShowBookCover(!showBookCover);
            handleUpdateCover();
          }}
          disabled={!selectedCoverUrl}
          className="submit-button w-[50vw] flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-xl h-11 items-center"
        >
          Approve Cover
        </button> */}

        {/* <button
          className="submit-button w-[50vw] flex justify-center gap-3 max-w-7xl bg-storygreen  text-white font-bold rounded-md text-xl h-11 items-center"
          onClick={() => {
            setShowBookCover(!showBookCover);
          }}
        >
          Close
        </button> */}
      </div>
    </>
  );
};

export default ChooseCover;
