import React, { useState, useEffect } from 'react';
import { auth, provider } from '../../firebase'; 
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import NavigationBar from '../../components/NavigationBar/NavigationBar';

function About() {
  // const [user, setUser] = useState(null); // Track the user object

  // useEffect(() => {
  //   // This observer gets called whenever the users sign-in state changes.
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser); // Set the user or null based on authentication status
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);

  // const handleLogin = () => {
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       setUser(result.user); // Set the user object on successful login
  //     })
  //     .catch((error) => {
  //       console.error("Error during sign in:", error);
  //     });
  // };

  // const handleLogout = () => {
  //   signOut(auth)
  //     .then(() => {
  //       setUser(null); // Clear the user object on successful logout
  //     })
  //     .catch((error) => {
  //       console.error("Error during sign out:", error);
  //     });
  // };

  return (
    <div>
      <NavigationBar  />
      <main>
        <h1>About Us</h1>
        <p>Text content goes here...</p>
      </main>
    </div>
  );
}

export default About;
