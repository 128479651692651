import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUser } from "../context/userContext";
import { BROWSER_REFRESH_POPUP_PARAM } from "../helper/Constant";

const UserRouteGuard = ({ children }) => {
  return <BrowserPopupComponent>{children || <Outlet />}</BrowserPopupComponent>;
};

export default UserRouteGuard;

// Or, a simpler version assuming the hash starts with `/?`
export const getQueryParamSimple = (paramName, url) => {
  // const url = window.location.href;
  const queryString = url.includes("?") ? url.split("?")[1] : "";
  console.log(queryString, "queryString");
  const searchParams = new URLSearchParams(queryString);

  return searchParams.get(paramName);
};

export const BrowserPopupComponent = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const popup = getQueryParamSimple(BROWSER_REFRESH_POPUP_PARAM, location.search);
    if (popup === "no") return;

    const handleUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Chrome requires returnValue to be set
      // setShowLeaveConfirmation(true);
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload); // Cleanup
  }, [location]);

  return <div>{children}</div>;
};
