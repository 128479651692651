import React, { useState, useEffect } from 'react';
import { auth, provider } from '../../firebase'; 
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import NavigationBar from '../../components/NavigationBar/NavigationBar';

function Help() {
  return (
    <div>
      <NavigationBar />

      <main>
        <h1>Help</h1>
        <p>Text content goes here...</p>
      </main>
    </div>
  );
}

export default Help;
