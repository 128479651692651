import { useState } from "react";
import Button from "../../components/Common/Button";
import CountdownTimer from "../../components/Common/CountDownTimer";
import { useUser } from "../../context/userContext";
import { resendVerificationEmail } from "./builderHelpers";

const AccountVerfication = ({ setLoaders, loaders }) => {
  const { user } = useUser();

   const [visibleTarget, setVisibleTarget] = useState({
     resendVerificationEmail: true,
   });

  return (
    <>
      <div className="text-storygreen  text-bold my-3   text-xl">
        <b>Thank You For Your Subscription </b>
        <br></br> <br></br>
        <br></br>
      </div>
      <h2 className="text-left text-2xl md:text-4xl mb-4 font-bold text-storyblue">Account Verification</h2>
      <div className="text-storygreen text-bold mt-2 text-xl">
        We’ve sent you an email from Build My Story. In order to start creating your story, you must first
        verify your account by clicking on the link provided in the email, which will take you to the next
        step! <br></br>
        <br></br>
        <span className="text-base italic text-red-800">
          *Please note! If you don’t find the verification email, it will be sent from “Build My Story” please
          check your spam folder
          {visibleTarget?.resendVerificationEmail ? (
            <span
              onClick={() => {
                // console.log("user", user);
                resendVerificationEmail(loaders, setLoaders, user?.email, user?.firstName);
                setVisibleTarget({ ...visibleTarget, resendVerificationEmail: false });
              }}
            >
              {" "}
              or click{" "}
              <span className="italic underline font-bold cursor-pointer hover:text-storygreen">
                {" "}
                request a new verification email.
              </span>
            </span>
          ) : (
            <CountdownTimer
              durationInSeconds={20}
              text="You can resend email after 20 seconds"
              processAfterTimerStopped={() => {
                setVisibleTarget({ ...visibleTarget, resendVerificationEmail: true });
              }}
            />
          )}
        </span>
      </div>
      {/* {visibleTarget?.resendVerificationEmail ? (
        <>
        // <Button
        //   title="Resend Verification Email"
        //   loader={loaders?.verificationEmail}
        //   className=" mt-4 w-72 h-11 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base items-center"
        //   onclick={() => {
        //     console.log("user", user);
        //     resendVerificationEmail(loaders, setLoaders, user?.email, user?.firstName);
        //     setVisibleTarget({ ...visibleTarget, resendVerificationEmail: false });
        //   }}
        // /> 
        </>
      ) : (
        <CountdownTimer
          durationInSeconds={20}
          text="You can resend email after 20 seconds"
          processAfterTimerStopped={() => {
            setVisibleTarget({ ...visibleTarget, resendVerificationEmail: true });
          }}
        />
      )} */}
    </>
  );
};

export default AccountVerfication;