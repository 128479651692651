import { createContext, useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import {
  COLLECTION_BOOKS,
  HTTP_STATUS_200,
  ROLE_ADMIN,
  ROLE_SALES,
  ROLE_SUPERADMIN,
} from "../helper/Constant";
import { callAPI } from "../services/apiService";
import { doc, onSnapshot } from "firebase/firestore";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [bookDataModified, setBookDataModified] = useState(null);
  const [draftId, setDraftId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0); // New state variable to track the current step
  const [startNewStory, setStartNewStory] = useState(true);
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);

  useEffect(() => {
    let getBookData; // Define unsubscribe variable outside the if block

    if (bookId) {
      // Start listening for changes to the document
      getBookData = onSnapshot(doc(db, COLLECTION_BOOKS, bookId), async (doc) => {
        if (doc.exists()) {
          // console.log("Book SnapSHOT RUN", doc.data());

          if (bookDataModified === true) {
            const response = await callAPI("api/book-update/remove-pdf-url", { bookId });
            if (response?.status === HTTP_STATUS_200) setBookDataModified(false);
          }
          // console.log("Book SnapSHOT RUN", doc.data());
          if (isTitleModalOpen === false) {
            // console.log("book data set ", isTitleModalOpen);
            setBookData(doc.data());
          } else console.log("title open...state not updated", isTitleModalOpen);
        } else {
          // Handle the case when the document doesn't exist
          console.log("Document does not exist");
          setBookData(null);
        }
      });
    } else {
      // Reset bookData if bookId is null
      setBookData(null);
    }

    // Return a cleanup function to getBookData from the snapshot listener
    return () => {
      if (getBookData) {
        getBookData();
      }
    };
  }, [bookId, bookDataModified, isTitleModalOpen]);

  const isAdmin = (user) => {
    return (
      user !== null &&
      (user?.role === ROLE_ADMIN || user?.role === ROLE_SUPERADMIN || user?.role === ROLE_SALES)
    );
  };

  // useEffect(() => {
  //   console.log("bookdata changedddddddd!!",isTitleModalOpen);
  // }, [bookData]);

  const contextValue = {
    draftId,
    setDraftId,
    currentStep,
    setCurrentStep,
    startNewStory,
    setStartNewStory,
    bookId,
    setBookId,
    bookData,
    setBookData,
    setBookDataModified,
    isAdmin,
    isTitleModalOpen,
    setIsTitleModalOpen,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  return useContext(AppContext);
};
