import { useState } from "react";
import Loader from "../../components/Common/Loader";
import { LogInSchema, handleGoogleLogin } from "./builderHelpers";
import { useUser } from "../../context/userContext";
import { Formik } from "formik";
import { callAPI } from "../../services/apiService";
import { LOCAL_STORAGE_TOKEN, USER_STATUS_ACTIVE } from "../../helper/Constant";
import { encryptToken } from "../../helper/helper";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Toast } from "../../components/Common/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Guide from "../../components/Common/Guide";

const Login = ({ setSignUpStep }) => {
  const { setUser, setShowLogin, getUserDetails, signupViaGoogle, setSignupViaGoogle } = useUser();
  const [signInLoader, setSignInLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [showSigninPassword, setShowSigninPassword] = useState(false);

  const handleSignInFormSubmit = async (values) => {
    try {
      const email = values.email;
      setSignInLoader(true);
      const data = await callAPI(`user/check-user-verified?email=${email}`);
      if (data?.verified) {
        if (data?.status === USER_STATUS_ACTIVE) {
          const auth = getAuth();
          const userCredential = await signInWithEmailAndPassword(auth, email, values.password);
          // console.log("login", userCredential);
          const encryptedToken = encryptToken(
            userCredential?.user?.accessToken,
            process.env.REACT_APP_TOKEN_ENCRYPT_KEY
          );
          localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
          Toast("Login Successfully");
        } else Toast("User Not Active", true);
      } else Toast("User Not Verified", true);
      setSignInLoader(false);
    } catch (error) {
      Toast("Fail to Login", true);
      setSignInLoader(false);
    }
  };

  return (
    <>
      <div className="w-full p-4 text-center">
        {/* <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">Login</h2> */}
        <div className=" flex items-center align-end mb-4">
          <h2 className="text-left text-4xl  font-bold text-storyblue">Login</h2>
          <div className="mt-2">
            <Guide  />
          </div>
        </div>
        <button
          onClick={() =>
            handleGoogleLogin(
              setGoogleLoader,
              setUser,
              getUserDetails,
              setSignUpStep,
              setShowLogin,
              setSignupViaGoogle
            )
          }
          className="w-full flex justify-center gap-2 bg-white text-storyblue font-bold rounded-[1.5rem] text-xl h-11 items-center cursor-pointer"
          disabled={googleLoader}
        >
          <img src="/google-icon.png" alt="google" className="w-[26px]" />
          Log In with Google
          <Loader loader={googleLoader} />
        </button>
        <div className="w-full flex items-center gap-1 py-6">
          <hr className="w-[50%] border-[#cacaca]" />
          <p>OR</p>
          <hr className="w-[50%] border-[#cacaca]" />
        </div>
        <div className="">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LogInSchema}
            onSubmit={(values) => {
              handleSignInFormSubmit(values);
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <form
                id="form-signin"
                name="form-signin"
                className="mt-0 md:mt-8 text-start"
                onSubmit={handleSubmit}
              >
                {/* <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">Login</h2> */}
                <input
                  autoFocus
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email "
                  className="mt-4 p-2 w-full rounded-md"
                />
                <div className="text-red-600 mt-1">{touched.email && errors.email}</div>

                <div className="relative mt-4">
                  <input
                    type={showSigninPassword ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="p-2 w-full rounded-md pr-10"
                  />
                  <span
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowSigninPassword(!showSigninPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showSigninPassword ? faEye : faEyeSlash}
                      className="text-gray-500 hover:text-gray-700"
                    />
                  </span>
                </div>
                <div className="text-red-600 mt-1">{touched.password && errors.password}</div>
                <button
                  type="submit"
                  className="w-full flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-xl p-2 mt-10 h-11"
                  // disabled={signupLoader}
                  disabled={signInLoader}
                >
                  Login
                  <Loader loader={signInLoader} />
                  {/* {signupLoader && <Spinner />} */}
                </button>

                <Link to="/forgot-password">
                  <p className="mt-4">Forget Password?</p>
                </Link>
                <div>
                  Don't have an account yet? Click{" "}
                  <Link
                    to="#"
                    className="text-storygreen cursor-pointer underline"
                    onClick={() => {
                      setShowLogin(false);
                    }}
                  >
                    here
                  </Link>{" "}
                  to create.
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;