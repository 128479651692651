import React from 'react'

export const NoRecordFound = ({colSpan}) => {
  return (
    <>
      <tr>
        <td colSpan={colSpan?colSpan:5} className="bg-gray-100 pt-4 pb-4 text-base text-center font-bold">
          Not Found
        </td>
      </tr>
    </>
  );
}
