import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import DummyPic from "../../components/Image holder Icon.png";
import { useStory } from "../../context/storyContext";
import { CHARACTER_AGE_UNIT, CHARACTER_TYPE_HUMAN } from "../../helper/Constant";
import { Toast } from "../../components/Common/Toast";

const Uploader = ({
  onFileChange,
  setPreviewImage,
  previewImage,
  selectedImage = null,
  disabled = false,
  currentPortrait = null,
  // handleOpenModal = null,
  setShouldCoverPortrait = null,
  title = "Upload Headshot of Main Character",
}) => {
  const fileInputRef = useRef(null);
  const { setStoryData } = useStory();

  // const handleFileUpload = (file) => {
  //   //console.log("File selected uploader 1:", file, onFileChange, typeof onFileChange);
  //   if (file && onFileChange && typeof onFileChange === "function") {
  //     onFileChange(file);

  //     // Set the preview image
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       // setCurrentPortrait(null);

  //       setStoryData((prevData) => ({
  //         ...prevData,
  //         uploadedImage: event.target.result,
  //         newRefImageUploaded: true,
  //         regeneratedNewCharacter: false,
  //         age: "",
  //         ageUnit: CHARACTER_AGE_UNIT,
  //         gender: "",
  //         animalType: null,
  //         characterType: CHARACTER_TYPE_HUMAN,
  //         mics: "",
  //       }));
  //       setPreviewImage(event.target.result);

  //       if (handleOpenModal) handleOpenModal();
  //     };
  //     reader.onerror = (error) => {
  //       console.error("Error reading file:", error);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.error("No valid file selected or onFileChange not provided");
  //   }
  // };

  const handleFileUpload = (file) => {
    if (!file || !onFileChange || typeof onFileChange !== "function") {
      console.error("No valid file selected or onFileChange not provided");
      return; // Exit early if conditions are not met
    }

    onFileChange(file); // Call the provided callback for further processing

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width < 300 || height < 300 || width > 500 || height > 500) {
          setShouldCoverPortrait(true);
        } else {
          setShouldCoverPortrait(false);
        }
        // Update state with validated image data
        setStoryData((prevData) => ({
          ...prevData,
          uploadedImage: event.target.result,
          newRefImageUploaded: true,
          regeneratedNewCharacter: false,
          age: "",
          ageUnit: CHARACTER_AGE_UNIT,
          gender: "",
          animalType: null,
          characterType: CHARACTER_TYPE_HUMAN,
          mics: "",
        }));
        setPreviewImage(event.target.result);
      };

      // Handle potential image loading errors gracefully
      img.onerror = (error) => {
        console.error("Error loading image:", error);
        Toast("Error: Could not load the selected image.", true);
      };

      img.src = event.target.result; // Set the image source
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      Toast("Error: An error occurred while processing the file.", true);
    };

    reader.readAsDataURL(file); // Read the file as Data URL
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      // console.log("acceptedFiles", acceptedFiles);
      handleFileUpload(acceptedFiles[0]);
      // console.log("fileInputRef", fileInputRef);
      // setCurrentPortrait(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    },
    [onFileChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (!(selectedImage && previewImage)) {
      setPreviewImage(DummyPic);
      setStoryData((prevData) => ({
        ...prevData,
        isDummyPic: true,
      }));
    }
    // else setPreviewImage(currentPortrait)
  }, [selectedImage, previewImage]);

  //console.log("currentPortrait", currentPortrait, "selected one",selectedImage);

  useEffect(() => {
    if (currentPortrait) setPreviewImage(currentPortrait);
  }, [currentPortrait]);

  return (
    <div
      {...getRootProps()}
      className={`flex  gap-4 items-center justify-around w-full bg-gray-50  border-gray-300 border-2 rounded-lg  ${
        selectedImage || previewImage ? "h-[4.5rem] rounded-[1.5rem] border-solid" : "h-[20rem] border-dashed"
      } `}
    >
      <div>
        <label
          htmlFor="dropzone-file"
          className={`flex flex-col items-center justify-center w-full ${
            disabled ? "" : "cursor-pointer"
          } bg-gray-50`}
        >
          <div
            className={`flex flex-col items-center justify-center ${
              selectedImage || previewImage ? "" : "pt-5 pb-6"
            }`}
          >
            {!(selectedImage || previewImage) ? (
              <svg
                className="w-10 h-10 mb-3 text-gray-400 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className={` ${disabled ? "text-[#c1c1c1]" : "cursor-pointer text-storygreen"}  text-2xl `}
                  title="Upload Image"
                  onClick={() => {
                    // handleEditCredits(user?.id);
                  }}
                />
                <p className="text-sm text-gray-500 dark:text-gray-400">Optional</p>
              </>
            )}

            {!(selectedImage || previewImage) && (
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                <span className="font-semibold">
                  {title}
                  <br />
                  or <br />
                  Drag & Drop Photo Here
                </span>
              </p>
            )}
            {/* <p className="text-xs text-gray-500 dark:text-gray-400">PNG or JPG (MAX. 1500x1500px)</p> */}
          </div>
          <input
            disabled={disabled}
            {...getInputProps()}
            id="dropzone-file"
            type="file"
            className="hidden"
            ref={fileInputRef}
          />
        </label>
      </div>
    </div>
  );
};

export default Uploader;
