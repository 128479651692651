import { useEffect, useRef} from "react";
import { callAPI } from "../../services/apiService";
import { Toast } from "../Common/Toast";
import {
  DEDUCT_CREDITS_PER_IMAGE,
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  LOCAL_STORAGE_EDIT_MODE,
} from "../../helper/Constant";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
const PageModal = ({
  pageData,
  // onSave,
  onClose,
  modalMode,
  modalOpen,
  bookId,
  displayBookPages,
  setShowLowCreditModal,
  index,
  setLoadingStatus,
  // userCreditBalance,
  // setUserCreditBalance,
}) => {
  const textRef = useRef(null);
  const sceneRef = useRef(null);
  const { setBookDataModified, bookData } = useApp();
  const { storyData, setStoryData } = useStory();

  const callImageGenerator = async (pageKey, storyPrompt, storyText) => {
    const response = await callAPI("api/generate-single-image", { bookId, pageKey, storyPrompt });
    setLoadingStatus(pageData?.pageIndex, false);
    if (response?.status === HTTP_STATUS_200) {
      Toast("New Image Will Show Soon");
      //Note: Since we will get image response in webhook so we need to get via interval
      // setInterval(() => {
      //   displayBookPages();
      // }, 20000);
    } else if (response?.status === HTTP_STATUS_400) {
      // setUserCreditBalance(userCreditBalance + DEDUCT_CREDITS_PER_IMAGE);
            setStoryData({
              ...storyData,
              creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
            });
      Toast(response?.message, true);
      setShowLowCreditModal(true);
    } else {
      Toast("Image Generation Failed! Try Again!", true);
      setStoryData({
        ...storyData,
        creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
      });
    }
  };

  useEffect(() => {
    if (textRef.current && pageData) {
      const textLength = textRef.current.value.length;
      textRef.current.setSelectionRange(textLength, textLength);
      textRef.current.focus();
    }
    if (sceneRef.current && pageData) {
      const sceneLength = sceneRef.current.value.length;
      sceneRef.current.setSelectionRange(sceneLength, sceneLength);
      sceneRef.current.focus();
    }
  }, [pageData]);

  const handleUpdateBook = (generateNewImage = true) => {
    if (
      parseInt(storyData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
      parseInt(bookData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)
    ) {
      setStoryData({
        ...storyData,
        creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) - DEDUCT_CREDITS_PER_IMAGE,
      });

      // if (userCreditBalance > 0) {
      // setUserCreditBalance(userCreditBalance - DEDUCT_CREDITS_PER_IMAGE);
      const updatedText = textRef.current ? textRef.current.value : "";
      const updatedScene = sceneRef.current ? sceneRef.current.value : "";
      if (pageData.scene !== updatedScene || pageData.text !== updatedText) {
        // onSave(pageData.pageIndex, updatedText, updatedScene);
        // if (currentStep === 4 && pageData.scene != updatedScene)
      }

      if (generateNewImage) {
        setLoadingStatus(pageData?.pageIndex, true);
        callImageGenerator(pageData.pageIndex, updatedScene, updatedText);
      }
      if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE) === "yes") {
        setBookDataModified(true);
      }
    } else {
      Toast("Additional Image Credits Needed!", true);
    }
    onClose();
  };

  if (!pageData) return null;
  return (
    <div
      className={`relative z-10 ${modalOpen ? "block" : "hidden"}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-full overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-8 md:p-4 text-center">
          <div className="relative transform overflow-hidden rounded-lg bg-storytan px-8 pb-4 pt-5 text-left shadow-xl transition-all  sm:max-w-6xl">
            {/* Content of the modal */}
            <h2 className="text-left text-2xl md:text-4xl mb-4 font-bold text-storyblue">
              Edit Page {pageData?.index + 4}
              {modalMode === "page" ? `- ${pageData?.index + 1}` : ""}
            </h2>
            <div
              className={`grid ${modalMode === "page" ? "grid-cols-3 " : "grid-cols-1 w-[500px]"} md: gap-6`}
            >
              <div style={{ display: modalMode === "editText" || modalMode === "page" ? "block" : "none" }}>
                <h3 className="text-sm text-gray-500 font-bold">Page Text </h3>
                <textarea
                  ref={textRef}
                  defaultValue={pageData?.text}
                  className="h-24 md:h-96 text-lg md:text-2xl w-full p-4 border-gray-400 border-2 text-gray-800"
                />
              </div>

              <div className="my-2" style={{ display: modalMode === "page" ? "block" : "none" }}>
                <img
                  src={pageData?.imageURLwebp}
                  alt="Page"
                  className="mx-auto mt-4 w-48 md:w-96 h-48 md:h-96"
                />
              </div>
              <div style={{ display: modalMode === "editPrompt" || modalMode === "page" ? "block" : "none" }}>
                <h3 className="text-sm text-gray-500 font-bold ">
                  This prompt controls the image only and allows you to modify image created
                </h3>
                <textarea
                  autoFocus
                  ref={sceneRef}
                  defaultValue={pageData.scene}
                  className="h-24 md:h-96 text-lg md:text-2xl w-full p-4 border-gray-400 border-2 text-gray-800"
                />
              </div>
            </div>

            <div className="w-full flex justify-end gap-3 mt-1">
              <button
                className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base   text-white shadow-sm"
                onClick={() => handleUpdateBook(false)}
                style={{ display: modalMode === "editText" || modalMode === "page" ? "block" : "none" }}
              >
                Update Text
              </button>
              <button
                type="button"
                className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base   text-white shadow-sm"
                onClick={() => handleUpdateBook()}
                // disabled={createImageLoader}
                style={{ display: modalMode === "editPrompt" || modalMode === "page" ? "block" : "none" }}
              >
                New Image
                {/* <Loader loader={createImageLoader} /> */}
              </button>
              <button
                className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base   text-white shadow-sm"
                onClick={onClose}
              >
                Close
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageModal;
