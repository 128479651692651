import React, { useEffect, useState } from "react";
import { useApp } from "../../context/appContext";
import { callAPI } from "../../services/apiService";
import Button from "../../components/Common/Button";
import { scrollToArea } from "../../helper/helper";
import { LOCAL_STORAGE_EDIT_MODE } from "../../helper/Constant";

export const EditStorySummary = ({ summary, type, closeSummaryModal, displayBookPages, targetRefs, summaryId }) => {
  const [summaryText, setSummaryText] = useState("");
  const [loader, setLoader] = useState(false);
  const { bookId,setBookDataModified } = useApp();

  useEffect(() => {
    setSummaryText(summary);
  }, [summary]);
  const updateSummary = async () => {
    try {
      setLoader(true);
      await callAPI("api/book-update/update-book-summary", {
        bookId,
        summaryText,
      });
         if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE) === "yes") {
           setBookDataModified(true);
         }
      displayBookPages();
    } catch (error) {
      console.error("Error updating Summary:", error);
    } finally {
      closeSummaryModal();
      scrollToArea(targetRefs, "edit-summary")
    }
  };
  return (
    <>
      <div className="text-left m-2 mb-8 text-sm text-storyblue animate__animated animate__slideInRight">
        <div className="flex flex-col gap-2">
          <h2 className="text-left text-2xl md:text-4xl mb-4 font-bold text-storyblue">Edit Summary</h2>
          <textarea
            // ref={textRef}
            autoFocus
            value={summaryText}
            onChange={(e) => {
              setSummaryText(e.target.value);
            }}
            className="h-36 md:h-96 text-lg md:text-2xl p-4 border-gray-400 border-2 text-gray-800 w-full"
          />
        </div>
        <div className="w-full flex justify-center flex-wrap gap-2 md:gap-4 mt-4">
          <Button
            title=" Close"
            onclick={() => {
              closeSummaryModal();
            }}
            className="submit-button w-60 flex justify-center gap-3 bg-storygreen text-white font-bold rounded-md text-base h-11 items-center"
          />
          <Button
            title={"Update Summary"}
            loader={loader}
            onclick={() => {
              updateSummary();
            }}
            className="submit-button w-60 flex justify-center gap-3 bg-storyblue text-white font-bold rounded-md text-base h-11 items-center"
          />
        </div>
      </div>
    </>
  );
};
