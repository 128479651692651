import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import SubmitButton from "../../components/Common/SubmitButton";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Heading from "../../components/Common/Heading";
import { HTTP_STATUS_200 } from "../../helper/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Guide from "../../components/Common/Guide";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email, token } = useParams();
  const [loader, setLoader] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateError = null;
    if (newPassword.length < 8) validateError = "Password Must be atleast 8 Characters Long";
    else if (newPassword !== confirmPassword) validateError = "Passwords do not Match";
    if (validateError) {
      Toast(validateError, true);
      return;
    }
    try {
      setLoader(true);
      const response = await callAPI("user/reset-password", {
        email,
        token,
        newPassword,
        confirmPassword,
      });

      if (response.status === HTTP_STATUS_200) {
        Toast("Password Reset Successfully");
        navigate("/");
      } else Toast(response.message, true);
      setLoader(false);
    } catch (err) {
      Toast("Unexpected Error Occurred", true);
      setLoader(false);
    }
  };


  useEffect(() => {
    console.log("Email:", email, token);
  }, [email, token]);

  return (
    <div className="App">
      <NavigationBar />
      <div className="bg-storytan p-4 mx-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
          {/* <Heading title="Reset Password" /> */}
          <div className=" flex items-center align-end mb-4">
            <Heading title="Reset Password" />
            <div className="mt-1">
              <Guide />
            </div>
          </div>
          <form
            id="form-reset-password"
            name="form-reset-password"
            className="mt-8 text-start"
            onSubmit={handleSubmit}
          >
            <div className="relative mt-4">
              <input
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="New Password"
                className="p-2 w-full rounded-md pr-10"
                required
              />
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <FontAwesomeIcon
                  icon={showNewPassword ? faEye : faEyeSlash}
                  className="text-gray-500 hover:text-gray-700"
                />
              </span>
            </div>
            <div className="relative mt-4">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
                className="p-2 w-full rounded-md pr-10"
                required
              />
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEye : faEyeSlash}
                  className="text-gray-500 hover:text-gray-700"
                />
              </span>
            </div>{" "}
            <SubmitButton type="submit" title="Reset Password" loader={loader} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
