import React, { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import Coupons from "./Coupon";
import Books from "./Book";
import Customers from "./Customers";
import Heading from "../../components/Common/Heading";
import PhysicalBookOrder from "./PhysicalBookOrder";
import Settings from "./Settings";
import ErrorLogs from "./ErrorLogs";
import MidJourneyStats from "./MidJourneyStats"
import Users from "./Users";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
import { useNavigate } from "react-router-dom";
import AdminStats from "./AdminStats";
import Plans from "./Plans";
import ArtStyles from "./ArtStyles";
import Guide from "../../components/Common/Guide";
const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Dashboard");
  const [selectedUserEmail, setSelectedUserEmail] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedUserEmail(null);
  };
  const handleSelectUser = (email) => {
    setSelectedUserEmail(email);
  };


  let content = null;
  switch (selectedOption) {
    default:
    case "Dashboard":
      content = <AdminStats />;
      break;
    case "Customers":
      content = <Customers onOptionClick={handleOptionClick} onSelectUser={handleSelectUser} />;
      break;
    case "Orders":
      content = (
        <PhysicalBookOrder
          selectedUserEmail={selectedUserEmail}
          setSelectedUserEmail={setSelectedUserEmail}
          onOptionClick={handleOptionClick}
        />
      );
      break;
    case "Coupons":
      content = <Coupons />;
      break;
    case "Settings":
      content = <Settings />;
      break;
    case "Error Logs":
      content = <ErrorLogs />;
      break;
    case "Admin Users":
      content = <Users />;
      break;
    case "Plans":
      content = <Plans />;
      break;
    case "Art Styles":
      content = <ArtStyles />;
      break;
    case "Mid Journey Stats":
      content = <MidJourneyStats />;
      break;

    // default:
    //   content = null;
  }
  return (
    <div className="flex flex-col sm:flex-row h-screen bg-storytan">
      <Sidebar
        onOptionClick={handleOptionClick}
        selectedOption={selectedOption}
        selectedUserEmail={selectedUserEmail}
        onSelectUser={handleSelectUser}
      />
      <main className="flex-1 flex flex-col bg-storytan ">
        <div className="flex-1 flex flex-col">
          <div className="p-4">
            <div className="flex align-center justify-between ml-0 md:ml-4 mt-0 md:mt-5">
              {/* <Heading title={selectedOption} /> */}
              <div className=" flex items-center align-end mb-4">
                <Heading title={selectedOption} />
                <div className="mt-1">
                  <Guide />
                </div>
              </div>
            </div>
            {content}
          </div>
        </div>
      </main>
    </div>
  );
};
export default Dashboard;
