import { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import Loader from "../components/Common/Loader";
import { callAPI } from "../services/apiService";
import {
  LOCAL_STORAGE_CURRENT_USER,
  LOCAL_STORAGE_EDIT_MODE,
  LOCAL_STORAGE_TOKEN,
  USER_STATUS_ACTIVE,
} from "../helper/Constant";
import { encryptToken } from "../helper/helper";
import { Toast } from "../components/Common/Toast";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [signupViaGoogle, setSignupViaGoogle] = useState(null);
  const [singleBookPlan, setSingleBookPlan] = useState(false);

  useEffect(() => {
    // This observer gets called whenever the users sign-in state changes.
    let unsubscribeUserSnapShot;
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      // setUser(currentUser); // Set the user or null based on authentication status
      if (currentUser) {
        unsubscribeUserSnapShot = onSnapshot(doc(db, "users", currentUser.email), async (doc) => {
          //  console.log("CurrentUser:", currentUser);

          const status = await getUserDetails(currentUser.email);
          if (status) {
            const idTokenResult = await currentUser.getIdTokenResult();
            // console.log("idTokenResult:current", idTokenResult);
            let encryptedToken = encryptToken(idTokenResult?.token, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);
            if (encryptedToken != localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
              localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
            }
            // console.log("idTokenResult", idTokenResult);
            const expirationTime = Date.parse(idTokenResult.expirationTime);
            // console.log("exxpi", expirationTime, Date.now());
            if (expirationTime < Date.now()) {
              // Token is expired or about to expire in the next 5 minutes, refresh it

              await currentUser.getIdToken(true);
              // console.log("new token", idToken);
              encryptedToken = encryptToken(idTokenResult?.token, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);
              // console.log("New token", encryptedToken);
              localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
            }
          }

          // const status = await getUserDetails(currentUser.email);
          // console.log("doc.data()", doc.data());
          // if (status) setUser({ ...user, ...doc.data(), email: currentUser.email });
          setIsLoading(false);
        });

        // let status = await getUserDetails(currentUser.email);
        /*if (status) {
          const idTokenResult = await currentUser.getIdTokenResult();
          let encryptedToken = encryptToken(idTokenResult?.token, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);
          if (encryptedToken != localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
            localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
          }
          // console.log("idTokenResult", idTokenResult);
          const expirationTime = Date.parse(idTokenResult.expirationTime);
          // console.log("exxpi", expirationTime, Date.now());
          if (expirationTime < Date.now()) {
            // Token is expired or about to expire in the next 5 minutes, refresh it

            const idToken = await currentUser.getIdToken(true);
            // console.log("new token", idToken);
            encryptedToken = encryptToken(idTokenResult?.token, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);
            localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
          } 
        }//*/
      } else setIsLoading(false);
    });

    // console.log("user", user);
    // Cleanup subscription on unmount
    return () => {
      unsubscribeUserSnapShot && unsubscribeUserSnapShot();
      unsubscribe();
    };
  }, []);

  const getUserDetails = async (email) => {
    try {
      const currentUser = await callAPI(`user/getUser?email=${email}`);
      // console.log("currentUser is", currentUser);
      if (currentUser?.status) {
        if (currentUser.status === USER_STATUS_ACTIVE) {
          setUser({ ...user, ...currentUser, email });
          if (currentUser?.plan?.chargeCycle === "one_time") setSingleBookPlan(true);
          else setSingleBookPlan(false);
          return true;
        } else {
          Toast("Account Not Active!", true);
          setUser(null);
          localStorage.removeItem(LOCAL_STORAGE_TOKEN);
          localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
          localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER);
          //  window.location.href = "/";
          return false;
        }
      }
    } catch (error) {
      console.error("error while fetching user!", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const contextValue = {
    user,
    setUser,
    showLogin,
    setShowLogin,
    isLoading,
    getUserDetails,
    signupViaGoogle,
    singleBookPlan,
    setSingleBookPlan,
    setSignupViaGoogle,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {isLoading ? (
        <div className="h-[100vh] flex justify-center items-center">
          <img className="w-16 h-16" src="/loading.gif" alt="Loading" />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
