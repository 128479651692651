const TableHeader = (headers)=>{
    return (
      <thead>
        <tr className="bg-gray-200">
          {headers.map((hd)=>{
            return <th key={hd} className="py-2 text-left px-4 border-b">{hd}</th>
          })}
        </tr>
      </thead>
    );
}

export default TableHeader;