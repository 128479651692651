import React from "react";
import { useUser } from "../../context/userContext";

const BookCoverImage = ({ bookDetails }) => {
  const { user } = useUser();

  return (
    <div className="w-[15rem] h-[15rem] object-cover mx-auto rounded-md shadow-xl mb-2 flex justify-center items-center relative mt-4">
      <div
        className="absolute h-full w-full grayscale rounded"
        style={{
          backgroundImage: `url('${bookDetails?.coverURL}')`,
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="w-[11rem] h-[13rem] rounded-sm flex justify-center pt-[1rem]"
        style={{
          backgroundImage: `url('${"/texture.png"}')`,
          backgroundSize: "cover",
          zIndex: 1,
        }}
      >
        <div
          className="w-[9rem] h-[9rem] flex justify-center items-center relative rounded-sm"
          style={{
            backgroundImage: `url('${bookDetails?.coverURL}')`, 
            backgroundSize: "15rem",
            backgroundPosition: "50% 33%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="relative flex flex-col items-center top-[97px]">
            <h1 className="text-[13px] leading-[15px] flex justify-center items-center text-center font-architects text-storyblue">
              {bookDetails?.title}
            </h1>
            <h1 className="font-poppins text-[9px] leading-[15px] text-gray-600 lowercase">
              by {user?.firstName} {user?.lastName}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookCoverImage;
