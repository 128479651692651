const Loader = ({ loader,dimentions="w-8 h-8",label=null }) => {
  return (
    <div className="flex items-center">
      {loader && <img className={dimentions} src="/loading.gif" alt="Loading" />}
      {label}
    </div>
  );
};

export default Loader;
