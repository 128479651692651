import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, provider, app } from "../../firebase";
import { useDropzone } from "react-dropzone";
import { getDoc, doc, collection, getFirestore } from "firebase/firestore";
import {
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

import Heading from "../../components/Common/Heading";
import SubmitButton from "../../components/Common/SubmitButton";
import { Toast } from "../../components/Common/Toast";
import { useUser } from "../../context/userContext";
import { encryptToken } from "../../helper/helper";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Admin = () => {
 
  const navigate = useNavigate();
  const [loader,setLoader]=useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAdminPassword, setShowAdminPassword] = useState(false);
  const { getUserDetails } = useUser();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const encryptedToken = encryptToken(
          userCredential?.user?.accessToken,
          process.env.REACT_APP_TOKEN_ENCRYPT_KEY
        );
        localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
      await getUserDetails(email);
      Toast("Login Successfully");
      setLoader(false);
      // window.location.href = "./admin/dashboard";
      navigate("/admin/dashboard");
    } catch (error) {
      Toast("Fail to Login", true);
      setLoader(false);
    }
  };

  return (
    <div className=" grid grid-cols-1 h-[100vh] bg-storytan  items-center md:grid-cols-2 gap-4 p-5">
      <div className="w-full flex flex-col gap-1">
        <form onSubmit={handleSubmit}>
          <Heading title="Admin Login" />
          <input
            autoFocus
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email "
            className="mt-4 p-2 w-full rounded-md bg-red"
          /> 
          <div className="relative mt-4">
            <input
              type={showAdminPassword ? "text" : "password"}
              name="password" 
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="p-2 w-full rounded-md pr-10"
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setShowAdminPassword(!showAdminPassword)}
            >
              <FontAwesomeIcon
                icon={showAdminPassword ? faEye : faEyeSlash}
                className="text-gray-500 hover:text-gray-700"
              />
            </span>
          </div>

          <SubmitButton title="Login" type="submit" loader={loader} />
          <Link to="/forgot-password">
            <p className="mt-4">Forget Password?</p>
          </Link>
        </form>
      </div>

      <div>
        <img
          className="px-8 animate__animated animate__rubberBand mx-auto"
          src="/bookcover.png"
          alt="Book Cover"
        />
      </div>
    </div>
  );
};

export default Admin;
