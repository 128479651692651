import React, { useEffect, useState, memo } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { callAPI } from "../../services/apiService";
const Verify = () => {
  const { token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState("idle");
  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const verifyOtp = async () => {

    setLoading(true);
    try {
      // const response = await fetch(`${process.env.REACT_APP_API_URL}/user/verify?otp=${token}`);
      // const data = await response.json();
  const data = await callAPI(`user/verify?otp=${token}`);
      if (data?.status === true) {
        setVerificationStatus("success");
        setLoading(false);
        setTimeout(() => {
          navigateTo("/");
        }, 5000);
      } else {
        setVerificationStatus("fail");
      }
    } catch (error) {
      setVerificationStatus("fail");
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      verifyOtp();
    }
  }, [token]); // Ensure
  const redirectToLogin = () => {
    setTimeout(() => {
      navigateTo("/");
    }, 15000);
  };
  return (
    <div className="App">
      <NavigationBar />
      <div className="bg-storytan p-4 mx-auto">
        <div className="max-w-7xl mx-auto mt-11  sm:px-6 lg:px-8 min-h-screen text-3xl">
          {loading ? ( // Render loader if loading state is true
            <div className="m-auto w-full flex justify-center">
              <Loader loader={loading} />
            </div>
          ) : verificationStatus === "success" && !loading ? (
            <>
              <div className="flex flex-col">
                <strong className="text-green-800">Your Account has been Verified Successfully!</strong>
                <span className="text-base mt-4">You will be redirect soon</span>
              </div>
            </>
          ) : !loading && verificationStatus === "fail" ? (
              <strong className="text-red-800 text-bold">Verification failed due to Invalid OTP!</strong>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default Verify;
