import { forwardRef } from "react";
import Loader from "./Loader";

const Button = forwardRef(
  (
  { title, loader = null, onclick, className, disabled = null, tooltip = null, ...props },
  ref
) => {
  return (
    <div className="flex gap-3">
      <button
        {...props}
        ref={ref}
        onClick={onclick}
        className={className}
        disabled={loader || disabled}
        title={tooltip}
      >
        {title}
        <Loader loader={loader} />
      </button>
    </div>
  );
});

export default Button;
