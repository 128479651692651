import React, { useState, useEffect, useCallback, useRef } from "react";
import { auth, provider, app } from "../../firebase";
import {
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

function AdminSignup({ }) {
  const addUser = async () => {
    const email = "admin@buildmystory.ai";
    const password = "12345678";
    const firebaseResponse = await createUserWithEmailAndPassword(auth, email, password);
  };

  useEffect(()=>{
addUser();
  },[])

  return <div className="App mx-auto">Signup ADMIN</div>;
}

export default AdminSignup;