import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/Common/SearchInput";
import TableHeader from "../../../components/Common/TableHeader";
import { callAPI } from "../../../services/apiService";
import { HTTP_STATUS_200 } from "../../../helper/Constant";
import { Toast } from "../../../components/Common/Toast";
import { convertTimestampToDateTime } from "../../../helper/helper";
import PaginationButtons from "../../../components/Common/Pagination";

const UserPaymentDetails = ({ user, paymentDetailsModalOpen, onClose }) => {
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const getPayementHistory = async () => {
    try {
      setLoader(true);
      const response = await callAPI(
        `user/get-user-payment-history?email=${user?.email}&pageNumber=${currentPage}`
      );
      if (response?.status === HTTP_STATUS_200) {
        setPaymentHistory(response?.payments);
        setTotalPages(response?.totalPages);
      } else Toast("Something Went Wrong", true);
      console.log("Response", response);
    } catch (error) {
      Toast("Something Went Wrong", true);
    } finally {
      setLoader(false);
    }
  };


  useEffect(() => {
    getPayementHistory();
  }, [user, currentPage]);

  const handlePageChange = (page) => {
    // console.log("pageNo", page);
    setCurrentPage(page);
  };
  return (
    <div className="p-0 md:p-4">
      {loader ? (
        <div className="w-full  h-80 flex justify-center items-center">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4 mb-4">
            <h1 className="text-storyblue text-xl font-bold text-start capitalize">
              Payment history of {user?.firstName} {user?.lastName}
            </h1>
            {/* <SearchInput placeholder={"Search Customer"} onChange={handleSearchInputChange} /> */}
            <button
              type="button"
              className={`w-[5rem] flex justify-center bg-storygreen text-white hover:text-white font-bold rounded-md text-base p-2 mr-2`}
              onClick={() => onClose(false)}
            >
              Back
            </button>
          </div>
          <div className="w-full overflow-auto">
            {paymentHistory?.length > 0 ? (
              <table className="min-w-full border border-gray-300">
                {TableHeader([
                  "Amount Charged",
                  "Coupon",
                  "Discount Availed",
                  "Payment Description",
                  "Payment Time",
                ])}

                <tbody>
                  {paymentHistory &&
                    paymentHistory?.map((payment, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                        <td
                          className="py-2 text-left px-4 border-b"
                          // onClick={() => {
                          //   onOptionClick("Orders");
                          //   onSelectUser(user?.email);
                          // }}
                        >
                          {/* <FontAwesomeIcon
                    icon={user?.status === USER_STATUS_ACTIVE ? faCircleCheck : faBan}
                    className=" mr-2 cursor-pointer"
                    title="Status"
                    style={{ color: user?.status === USER_STATUS_ACTIVE ? "green" : "red" }}

                    // onClick={() => {
                    //   setOpenDialog(true);
                    //   setEmail(user?.email);
                    // }}
                  /> */}
                          ${" "}
                          {payment?.couponDiscount
                            ? (payment?.amount - payment?.couponDiscount).toFixed(2)
                            : payment?.amount}
                        </td>
                        <td className="py-2 text-left px-4 border-b">{payment?.coupon || "-"}</td>
                        <td className="py-2 text-left px-4 border-b">
                          {payment?.couponDiscount ? `$ ${payment?.couponDiscount}` : "-"}
                        </td>
                        <td className="py-2 text-left px-4 border-b">{payment?.description}</td>

                        <td className="py-2 text-left px-4 border-b">
                          {convertTimestampToDateTime(payment?.timestamp)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <div className="flex h-[20rem] justify-center items-center">
                <h3 className="text-storyblue  text-xl font-bold">No Payments Found</h3>
              </div>
            )}
          </div>
        </>
      )}
      <div className="flex justify-end mt-4 gap-2">
        {totalPages > 1 && (
          <PaginationButtons
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default UserPaymentDetails;
