import React, { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { HTTP_STATUS_200 } from "../../helper/Constant";
import TableHeader from "../../components/Common/TableHeader";
import { convertTimestampToDateTime } from "../../helper/helper";
import PaginationButtons from "../../components/Common/Pagination";
import { Toast } from "../../components/Common/Toast";
import { useUser } from "../../context/userContext";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
const Payments = () => {
  const { user } = useUser();
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const getPayementHistory = async () => {
    try {
      setLoader(true);
      const response = await callAPI(
        `user/get-user-payment-history?email=${user?.email}&pageNumber=${currentPage}`
      );
      if (response?.status === HTTP_STATUS_200) {
        setPaymentHistory(response?.payments);
        setTotalPages(response?.totalPages);
      } else Toast("Something Went Wrong", true);
    } catch (error) {
      Toast("Something Went Wrong", true);
    } finally {
      setLoader(false);
    }
  }; 
  useEffect(() => {
    getPayementHistory();
  }, [user, currentPage]);
  const handlePageChange = (page) => {
    // console.log("pageNo", page);
    setCurrentPage(page);
  };
  return (
    <div>
      <div className="App">
        <NavigationBar />
        <div className="bg-storytan p-4 mx-auto">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
            <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">My Payments</h2>
            <>
              {loader ? (
                <div className="w-full  h-80 flex justify-center items-center">
                  <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
                </div>
              ) : (
                <>
                  {paymentHistory?.length > 0 ? (
                    <div className="w-full overflow-auto">
                      <table className="min-w-full border border-gray-300">
                        {TableHeader([
                          "Amount Charged",
                          "Coupon",
                          "Discount Availed",
                          "Payment Description",
                          "Payment Time",
                        ])}
                        <tbody>
                          {paymentHistory &&
                            paymentHistory?.map((payment, index) => (
                              <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                <td className="py-2 text-left px-4 border-b">
                                  ${" "}
                                  {payment?.couponDiscount
                                    ? (payment?.amount - payment?.couponDiscount).toFixed(2)
                                    : payment?.amount}
                                </td>
                                <td className="py-2 text-left px-4 border-b">{payment?.coupon || "-"}</td>
                                <td className="py-2 text-left px-4 border-b">
                                  {payment?.couponDiscount ? `$ ${payment?.couponDiscount}` : "-"}
                                </td>
                                <td className="py-2 text-left px-4 border-b">{payment?.description}</td>
                                <td className="py-2 text-left px-4 border-b">
                                  {convertTimestampToDateTime(payment?.timestamp)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="flex h-[20rem] justify-center items-center">
                      <h3 className="text-storyblue  text-xl font-bold">No Payments Found</h3>
                    </div>
                  )}
                </>
              )}
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payments;
