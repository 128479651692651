import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import "../../App.css";
import Logo from "../Admin/logo.webp";
import LogoBlack from "../Admin/logoBlack.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faDeleteLeft,
  faFilePdf,
  faPenToSquare,
  faFolderPlus,
  faFolderMinus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { generatePDF, getCurrentYear, goToTopOfPage } from "../../helper/helper";
import Loader from "../../components/Common/Loader";
import { useUser } from "../../context/userContext";
import { AGE_0_2, AGE_3_7, AGE_8_12, LOCAL_STORAGE_BOOK_ID } from "../../helper/Constant";
import { callAPI } from "../../services/apiService";
import { archiveBook, editStory, handleDeleteBook, unArchiveBook } from "../Builder/builderHelpers";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
import Dialogs from "../../components/Common/Dialog";
import Footer from "../../components/Footer/Footer";

const Page = React.forwardRef(({ children, className, style }, ref) => (
  <div className={`page ${className}`} ref={ref} style={style}>
    {children}
  </div>
));

function FlipBook({ bookIdAdmin = null }) {
  const navigateTo = useNavigate();
  const { user } = useUser();
  const { setDraftId } = useApp();
  const { setStoryData } = useStory();
  // const { bookData: contextBookData, } = useApp();

  const [totalPages, setTotalPages] = useState(0);
  // const [pdfUrl, setPDFUrl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [bookData, setBookData] = useState(null);
  const flipBookRef = useRef(null);
  // const [flipFirstPage, setFlipFirstPage] = useState(false);
  const { bookId } = useParams(); // Extract bookId from URL
  // const [pdfGeneratingLoader, setPdfGeneratingLoader] = useState(false);
  const [archiveLoader, setArchivedLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedBook, setSelectedBook] = useState({ bookId: null });
  const [openDialog, setOpenDialog] = useState(false);
  //  const [deletingBook, setDeletingBook] = useState(false);

  // Fetch book data
  const fetchBookData = async () => {
    try {
      const bookID = bookIdAdmin ? bookIdAdmin : bookId ? bookId : "";
      if (bookID) {
        const data = await callAPI(`api/books/${bookID}`);
        // console.log("data in fechbook in flipbok", data);
        setBookData(data);
        setTotalPages(data?.pages ? Object.keys(data?.pages).length * 2 + 1 : 0);
      } else {
        console.error("Failed to fetch book data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchBookData();
  }, [bookId, bookIdAdmin]);

  useEffect(() => {
    goToTopOfPage();
    const currentBookId = localStorage.getItem(LOCAL_STORAGE_BOOK_ID);
    if (currentBookId !== bookId) localStorage.setItem(LOCAL_STORAGE_BOOK_ID, bookId);
  });

  const sortedPages = () => {
    if (bookData?.pages) {
      return Object.entries(bookData.pages).sort(([pageKey1], [pageKey2]) => {
        const pageNum1 = parseInt(pageKey1.match(/\d+/)[0]);
        const pageNum2 = parseInt(pageKey2.match(/\d+/)[0]);
        return pageNum1 - pageNum2;
      });
    } else return [];
  };
  if (!bookData) {
    return (
      <div>
        {!bookIdAdmin && <NavigationBar />}
        <div className="h-[100vh] flex justify-center items-center">
          <img className="w-16 h-16" src="/loading.gif" alt="Loading" />
        </div>
      </div>
    );
  }
  const createPDF = async () => {
    setLoader(true);
    await generatePDF(bookId);
    // setPDFUrl(pdfUrl);
    await fetchBookData()
    setLoader(false);
  };

  const scrollToFirstPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flip(0);
      // setFlipFirstPage(true);
    }
  };

  const scrollToLastPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flip(totalPages + 5);
    }
  };
console.log("bookData flipbook", bookData);
  return (
    <div>
      {!bookIdAdmin && <NavigationBar />}
      <div className="bg-storytan p-[.25rem] md:p-4 mx-auto">
        <div>
          {!bookIdAdmin && (
            <div className="flex justify-center items-center px-2 gap-[.7rem] md:gap-4 mt-4 rounded bg-white h-[5rem] w-full">
              {/* <div className="cursor-pointer" onClick={() => navigateTo(-1)}>
                <FontAwesomeIcon icon={faDeleteLeft} className=" text-3xl text-storyblue" title="Back" />
                <p className="text-storyblue">Back</p>
              </div> */}
              <Link to="/library" rel="noopener noreferrer">
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                    icon={faDeleteLeft}
                    className="text-2xl md:text-3xl text-storyblue"
                    title="Back"
                  />
                  <p className="text-storyblue text-[13px] leading-tight md:text-base">Back</p>
                </div>
              </Link>

              {bookData && (
                <Link
                  to="#"
                  rel="noopener noreferrer"
                  //onClick={() => editStory(bookData, setDraftId, setIllustrationCharacter, navigateTo)}
                  onClick={() => editStory(bookData, setDraftId, setStoryData, navigateTo)}
                >
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className=" text-2xl md:text-3xl text-storyblue"
                      title="Purchase"
                    />
                    <p className="text-storyblue text-[13px] leading-tight md:text-base">Edit story</p>
                  </div>
                </Link>
              )}
              {bookData && !bookData?.pdfUrl ? (
                <Link
                  onClick={() => {
                    if (!loader) {
                      createPDF();
                    }
                  }}
                  rel="noopener noreferrer"
                  className={`cursor-pointer ${loader ? "disabled-link" : ""}`}
                  style={{ pointerEvents: loader ? "none" : "auto" }}
                >
                  <div className="flex flex-col items-center justify-center">
                    {!loader ? (
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className="text-2xl md:text-3xl text-storyblue"
                        title="Generate PDF"
                      />
                    ) : (
                      <Loader loader={loader} />
                    )}
                    <p className="text-storyblue text-[13px] leading-tight md:text-base">Generate PDF</p>
                  </div>
                </Link>
              ) : (
                <Link to={bookData?.pdfUrl} target="_blank" rel="noopener noreferrer">
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className=" text-2xl md:text-3xl text-storyblue"
                      title="View PDF"
                    />
                    <p className="text-storyblue text-[13px]  leading-tight md:text-base">View PDF</p>
                  </div>
                </Link>
              )}
              {bookData && !bookData?.archived ? (
                <Link
                  onClick={() => {
                    archiveBook(bookData?.bookId, fetchBookData, setArchivedLoader);
                  }}
                  rel="noopener noreferrer"
                  className={`cursor-pointer ${archiveLoader ? "disabled-link" : ""}`}
                  style={{ pointerEvents: archiveLoader ? "none" : "auto" }}
                >
                  <div className="flex flex-col items-center justify-center">
                    {!archiveLoader ? (
                      <FontAwesomeIcon
                        icon={faFolderPlus}
                        className="text-2xl md:text-3xl text-storyblue"
                        title="Archive"
                      />
                    ) : (
                      <Loader loader={archiveLoader} />
                    )}
                    <p className="text-storyblue text-[13px]  leading-tight md:text-base">Archive</p>
                  </div>
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    unArchiveBook(bookData?.bookId, fetchBookData, setArchivedLoader);
                  }}
                  // target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="cursor-pointer">
                    {!archiveLoader ? (
                      <FontAwesomeIcon
                        icon={faFolderMinus}
                        className="text-2xl md:text-3xl text-storyblue"
                        title="Archive"
                      />
                    ) : (
                      <Loader loader={archiveLoader} />
                    )}
                    <p className="text-storyblue text-[13px]  leading-tight md:text-base">UnArchive</p>
                  </div>
                </Link>
              )}
              <Link onClick={() => navigateTo(`/bookpurchase/${bookId}`)} to="#" rel="noopener noreferrer">
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    className="text-2xl md:text-3xl text-storyblue"
                    title="Purchase"
                  />
                  <p className="text-storyblue text-[13px] md:text-base">Purchase</p>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setSelectedBook({
                    ...selectedBook,
                    bookId: bookData?.bookId,
                  });
                  // setDeleteLoader(true);
                  setOpenDialog(true);
                  // handleDeleteBook(bookData?.bookId, setDeleteLoader);
                  // navigateTo(-1);
                }}
                // to="#"
                rel="noopener noreferrer"
              >
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="text-2xl md:text-3xl text-storyblue"
                    title="Delete"
                  />

                  <p className="text-storyblue text-[13px] md:text-base">Delete</p>
                </div>
              </Link>
            </div>
          )}

          <div
            className={`max-w-7xl mx-auto md:px-6 lg:px-8 ${
              bookIdAdmin ? "h-[100%]" : " min-h-screen"
            }  mt-8`}
          >
            <HTMLFlipBook
              width={
                bookIdAdmin ? (window.innerWidth < 640 ? 300 : 500) : window.innerWidth < 640 ? 310 : 600
              }
              height={
                bookIdAdmin ? (window.innerWidth < 640 ? 300 : 500) : window.innerWidth < 640 ? 320 : 600
              }
              className="mx-auto overflow-hidden flex items-center justify-center"
              // width={bookIdAdmin ? 500 : 600}
              // height={bookIdAdmin ? 500 : 600}
              // className=" mx-auto overflow-hidden flex items-center justify-center"
              ref={flipBookRef}
              onFlip={(event) => {
                // setFlipFirstPage(true);
              }}
            >
              <Page key="cover2" className="cover-page ">
                <div className=" mx-4 mr-4 flex justify-center items-center h-full">
                  <h1 className="text-xl md:text-2xl w-[20rem] md:w-[25rem] font-bold text-center">
                    Enjoy flipping through the pages of your personal story.
                  </h1>
                </div>
              </Page>
              {/* cover page */}
              <Page key="cover" className="cover-page bg-storyblue shadow-2xl">
                <div className="h-full w-full flex justify-center items-center relative">
                  <div
                    className="absolute h-full w-full grayscale rounded"
                    style={{
                      backgroundImage: `url('${bookData?.coverURL || "/pattern.png"}')`,
                      backgroundSize: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>
                  <div
                    // className="h-[540px] w-[450px] rounded flex justify-center pt-[25px]"
                    className="h-[300px] md:h-[540px] w-[250px] md:w-[450px] rounded flex justify-center pt-[15px] md:pt-[25px]"
                    style={{
                      backgroundImage: `url('${"/texture.png"}')`,
                      backgroundSize: "cover",
                      zIndex: 1,
                    }}
                  >
                    <div
                      // className="h-[400px] w-[400px] rounded flex justify-center items-center relative"
                      className="h-[200px] md:h-[400px] w-[200px] md:w-[400px] rounded flex justify-center items-center relative"
                      style={{
                        backgroundImage: `url('${bookData?.coverURL || "/pattern.png"}')`,
                        // backgroundSize: "600px",
                        backgroundSize: window.innerWidth < 768 ? "300px" : "600px",
                        backgroundPosition: "50% 27.5%",
                      }}
                    >
                      {/* <div className="relative flex flex-col items-center  gap-2  top-[16rem]"> */}
                      <div className="relative flex flex-col items-center gap-2 top-[8.5rem] md:top-[16rem]">
                        <h1 className="text-lg md:text-3xl text-center font-yuji-boku">{bookData?.title}</h1>
                        <h1 className="font-poppins text-sm md:text-md capitalize text-gray-600">
                          {/* <h1 className="text-3xl text-center font-yuji-boku">{bookData?.title}</h1>
                        <h1 className="font-poppins text-md capitalize  text-gray-600"> */}
                          by {bookData?.author}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Page>

              {/* blank pages  */}
              <Page key="blank" className=" text-page h-screen">
                <div
                  className="flex flex-col justify-end pb-4 gap-1 items-center  font-serif"
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </Page>
              <Page key="blank" className=" text-page h-screen">
                <div
                  className="flex flex-col justify-end pb-4 gap-1 items-center  font-serif"
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </Page>
              <Page key="cover2" className="cover-page bg-storyblue">
                <div
                  className="h-[100%] flex flex-col  mx-auto py-3 bg-gray-200"
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* <div className="flex flex-col justify-center items-center p-8 gap-28"> */}
                  <div className="flex flex-col justify-center items-center p-4 md:p-8 gap-4 md:gap-28">
                    <h3 className="text-xl md:text-4xl bold font-yuji-boku">{bookData?.title}</h3>
                    <h3 className="text-[.75rem] md:text-xl text-justify text-black">
                      <p className="font-poppins mb-2 md:mb-4">
                        All rights reserved. No part of the publication may be reproduced, stored in or
                        introduced into a retrieval system, or trasmitted, in any form, or by any means
                        (electronic, mechanica, photocopying, recording or otherwise) without the prior
                        written permission of the author.
                      </p>
                      <p className="  font-poppins">
                        Any person who does any unauthorized act in relation to this publication may be liable
                        to criminal prosecution ad civil claims for damages. Text and illustrations copyright
                        &#169; {user?.firstName} {user?.lastName} {getCurrentYear()} Moral rights asserted.
                      </p>
                    </h3>
                  </div>
                  <div className="w-full flex flex-col   items-center gap-1">
                    <div className="flex items-baseline gap-1">
                      <img className="h-3 md:h-6 w-auto" src={LogoBlack} alt="Build My Story" />
                    </div>
                  </div>
                </div>
              </Page>
              <Page key="cover2" className=" text-page h-screen">
                <div
                  className="flex flex-col justify-end pb-4 gap-1 items-center  font-serif"
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {/* //branding */}
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundImage: `url('paper.jpg')`,
                      backgroundSize: "cover",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Logo}
                      alt="logo"
                      className="h-12 md:h-16 w-auto mx-auto"
                      // style={{ height: "4rem", margin: "auto" }}
                    />
                  </div>
                </div>
              </Page>

              {sortedPages()?.flatMap(([pageKey, pageDetails], index) => [
                <Page key={`${pageKey}-text`} className="bg-storytan text-page h-screen">
                  <div
                    style={{
                      backgroundImage: `url("/paper.jpg")`,
                      backgroundSize: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <h3
                      className={`leading-normal px-8 h-full flex items-center font-bold text-justify font-poppins ${
                        bookData?.childAge === AGE_0_2
                          ? "text-xl md:text-2xl"
                          : bookData?.childAge === AGE_3_7
                          ? "text-lg md:text-xl"
                          : bookData?.childAge === AGE_8_12
                          ? "text-base md:text-lg"
                          : "text-xs md:text-sm"
                      }`}
                    >
                      {pageDetails.text}
                    </h3>
                  </div>
                  <div className="page-number mt-[-30px] w-full flex justify-center">
                    <div
                      className=" text-base w-12 h-6 flex justify-center items-center font-bold text-storyblue "
                      style={{ fontFamily: "system-ui" }}
                    >
                      {index * 2 + 5}
                    </div>
                  </div>
                </Page>,
                <Page key={`${pageKey}-image`} className="image-page">
                  <div
                    style={{
                      backgroundImage: `url('${
                        pageDetails?.imageURLwebp || pageDetails?.imageURL || "/pattern.png"
                      }')`,
                      backgroundSize: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                  <div className="page-number mt-[-30px] w-full flex justify-center">
                    <div
                      className=" w-12 h-6 mb-2 flex justify-center items-center rounded-full font-bold text-base text-white"
                      style={{
                        fontFamily: "system-ui",
                        position: "absolute",
                        bottom: "0",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      {/* {index * 2 + 6}/{bookData?.pageTotal} */}
                      {index * 2 + 6}
                    </div>
                  </div>
                </Page>,
              ])}

              {/*First Blank Page-dino page */}
              <Page key="cover2" className="cover-page ">
                <div
                  style={{
                    backgroundImage: `url("/pattern.png")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </Page>
              {/* Second Blank Page */}
              <Page key="cover2" className="cover-page bg-storyblue">
                <div
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </Page>
              {/* Last Cover Page */}
              <Page key="cover2" className=" text-page h-screen">
                <div
                  className="flex flex-col justify-between py-4 md:py-12 px-8 items-center  font-serif"
                  style={{
                    backgroundImage: `url("/paper.jpg")`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div className="flex flex-col  gap-4 md:gap-14">
                    <h3 className="text-xl md:text-2xl font-yuji-boku">{bookData?.title}</h3>
                    <h3 className="text-sm md:text-base font-poppins">{bookData?.summary}</h3>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center gap-2">
                    <img className="h-6 md:h-10 w-auto" src={LogoBlack} alt="Build My Story" />
                    <h3>www.buildmystory.ai</h3>
                    {/* <FontAwesomeIcon icon={faRegistered} style={{ color: "#062457" }} />  */}
                    {/* <Barcode value={bookData?.barCode} /> */}
                  </div>
                </div>
              </Page>
            </HTMLFlipBook>
            <div
              className={` ${
                bookIdAdmin ? "w-full  lg:w-[92%]" : "w-full"
              } flex flex-col items-end gap-2 mt-4 mr-4`}
            >
              <strong className=" text-lg text-storyblue">*Click on corner of book to flip pages</strong>
              <div className="flex gap-4">
                <button
                  onClick={scrollToFirstPage}
                  className="text-sm font-bold text-white rounded p-2 bg-storygreen"
                >
                  Go to First Page
                </button>
                <button
                  onClick={scrollToLastPage}
                  className="text-sm font-bold text-white rounded p-2 bg-storygreen"
                >
                  Go to Last Page
                </button>
              </div>
            </div>
            {/* </div>  */}
          </div>
        </div>
      </div>
      <Dialogs
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        handelProcess={() => handleDeleteBook(selectedBook.bookId, setDeleteLoader, navigateTo)}
        confirmMessage="Are you sure you want to delete this Story?"
        loader={deleteLoader}
      />
      <Footer/>
    </div>
  );
}
export default FlipBook;
