import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import TableHeader from "../../components/Common/TableHeader";
import { useUser } from "../../context/userContext";
import { HTTP_STATUS_200, PURCHASE_TYPE_CREDITS, PURCHASE_TYPE_STORY } from "../../helper/Constant";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import PaginationButtons from "../../components/Common/Pagination";
import { convertTimestampToDateTime } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import PurchaseConfirmation from "./PurchaseConfirmation";
import Heading from "../../components/Common/Heading";
import Guide from "../../components/Common/Guide";
import Footer from "../../components/Footer/Footer";

const OrderHistory = () => {
  const { user } = useUser();
  const [orderHistory, setOrderHistory] = useState(null);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [resendEmailLoader, setResendEmailLoader] = useState(null);
  const [showPurchaseConfirmation, setShowPurchaseConfirmation] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(false);
   // const [resendCreditEmailLoader, setResendCreditEmailLoader] = useState(false);

  const getOrdersHistory = async () => {
    try {
      setLoader(true);
      const response = await callAPI(
        `user/get-user-order-history?email=${user?.email}&pageNumber=${currentPage}`
      );
      if (response?.status === HTTP_STATUS_200) {
        setOrderHistory(response?.orders);
        setTotalPages(response?.totalPages);
      } else Toast("Something Went Wrong", true);
    } catch (error) {
      Toast("Something Went Wrong", true);
    } finally {
      setLoader(false);
    }
  };

  const handleResendConfirmationEmail = async (orderId) => {
    try {
      setResendEmailLoader(orderId);

      const order = orderHistory.filter((obj) => obj?.id === orderId);
      
      let response=null
      switch (order[0]?.orderType) {
        case "book":
          response = await callAPI(`api/book-purchase/resend-book-purchase-order-confirmation-email`, {
            email: user?.email,
            orderId,
          });
          break;
        case PURCHASE_TYPE_CREDITS:
        case PURCHASE_TYPE_STORY:
          response = await callAPI(`user/resend-image-Credit-purchase-email`, {
            email: user?.email,
            orderId,
          });
          break;
        case "subscription":
          response = await callAPI(`user/resend-plan-purchase-email`, {
            email: user?.email,
            orderId,
          });
          break;
        default:
          console.log("ordertype not found", order?.orderType);
      }
      if (response?.status === HTTP_STATUS_200) {
        Toast("Confirmation Email Resend Successfully");
      } else Toast("Something Went Wrong", true);
    } catch (error) {
      Toast("Something Went Wrong", true);
    } finally {
      setResendEmailLoader(null);
    }
  };


  useEffect(() => {
    getOrdersHistory();
  }, [user, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

const handleEyeClick = (order) => {
  setSelectedOrder(order);
  setShowPurchaseConfirmation(true);
};

const handleCloseConfirmationEmail = () => {
  setShowPurchaseConfirmation(false);
};

const calculateBookPurchaseTotalAmount = (order)=>{
  let total = (parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)).toFixed(2);
  if(order?.orderType === "book") total = (
   parseFloat(total) * parseInt(order?.quantity) -
    parseFloat(order?.couponDiscount || 0) +
    parseFloat(order?.shippingAmount)
  ).toFixed(2);
  return total;
}

  return (
    <div>
      <div className="App">
        <NavigationBar />
        {/* <div className="bg-storytan p-4 h-[73rem] lg:h-[86vh] mx-auto overflow-hidden  ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full"> */}
        <div
          className={`bg-storytan ${
            showPurchaseConfirmation ? " h-max" : "p-4 h-[73rem] lg:h-[86vh] "
          }  mx-auto overflow-hidden  `}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
            {/* <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">My Orders</h2> */}
            <div className=" flex items-center align-end mb-4">
              <Heading title="My Orders" />
              <div className="mt-1">
                <Guide />
              </div>
            </div>
            {showPurchaseConfirmation ? (
              <PurchaseConfirmation onClose={handleCloseConfirmationEmail} order={selectedOrder} />
            ) : (
              <>
                {loader ? (
                  <div className="w-full  h-80 lg:h-96 flex justify-center items-center">
                    <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
                  </div>
                ) : (
                  <>
                    {orderHistory?.length > 0 ? (
                      <div>
                        <div className="w-full h-[100%] lg:h-[60vh] overflow-auto ">
                          <table className="min-w-full border border-gray-300">
                            {TableHeader([
                              "Date",
                              "Description",
                              "Qty",
                              "Book Number",
                              "Sub-Total",
                              "Discount",
                              "Total",
                              "Shipping Status",
                              " ",
                            ])}
                            <tbody>
                              {orderHistory &&
                                orderHistory?.map((order, index) => (
                                  <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                    <td className="py-2 text-left px-4 border-b">
                                      {convertTimestampToDateTime(order?.timestamp)}
                                    </td>
                                    <td className="py-2 text-left px-4 border-b capitalize">
                                      {order?.orderType && order?.orderType.replace("_", " ")}
                                    </td>
                                    <td className="py-2 text-left px-4 border-b">{order?.quantity}</td>
                                    <td className="py-2 text-left px-4 border-b">
                                      {order?.orderNumber || "-"}
                                    </td>
                                    <td className="py-2 text-left px-4 border-b">
                                      ${" "}
                                      {parseFloat(
                                        parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)
                                      ).toFixed(2)}
                                    </td>
                                    <td className="py-2 text-left px-4 border-b">
                                      $ {order?.couponDiscount || "0.00"}
                                    </td>
                                    <td className="py-2 text-left px-4 border-b">
                                      ${calculateBookPurchaseTotalAmount(order)}
                                    </td>

                                    <td className="py-2 text-left px-4 border-b capitalize">
                                      {(order?.status && order?.status?.replace("_", " ")) || "-"}
                                    </td>
                                    <td className="py-2 text-left px-4">
                                      <div className="flex justify-center items-center text-center gap-2">
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="cursor-pointer"
                                          title="View Confirmation"
                                          onClick={() => handleEyeClick(order)}
                                        />
                                        {resendEmailLoader != order?.id ? (
                                          <FontAwesomeIcon
                                            icon={faPaperPlane}
                                            className="cursor-pointer"
                                            title="Send Confirmation Email"
                                            onClick={() => {
                                              handleResendConfirmationEmail(order?.id);
                                            }}
                                          />
                                        ) : (
                                          <img className="w-8 h-8" src="/loading.gif" alt="Loading" />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          {/* <div className="flex justify-end mt-4 gap-2">
                          {totalPages > 1 && (
                            <PaginationButtons
                              totalPages={totalPages}
                              currentPage={currentPage}
                              handlePageChange={handlePageChange}
                            />
                          )}
                        </div> */}
                        </div>
                        {/* Pagination section */}
                        <div className="flex justify-end sm:justify-end mt-0 md:mt-2 lg:mt-0 gap-2">
                          {totalPages > 1 && (
                            <PaginationButtons
                              totalPages={totalPages}
                              currentPage={currentPage}
                              handlePageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex h-[20rem] justify-center items-center">
                        <h3 className="text-storyblue  text-xl font-bold">No Payment History Found</h3>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default OrderHistory;
