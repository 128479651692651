import { CheckIcon } from '@heroicons/react/24/solid'
import {Link} from "react-router-dom"

const steps = [
  { id: "1", name: "Log In", href: "#", status: "current" },
  { id: "2", name: "Story Details", href: "#", status: "upcoming" },
  { id: "3", name: "Build My Character", href: "#", status: "upcoming" },
  { id: "4", name: "Build My Story", href: "#", status: "upcoming" },
  { id: "5", name: "Purchase & Download", href: "#", status: "upcoming" },
];

const StepsBar = ({ currentStep }) => {
  const updatedSteps = steps.map((step, index) => {
    if (index < currentStep) {
      return { ...step, status: 'complete' };
    } else if (index === currentStep) {
      return { ...step, status: 'current' };
    } else {
      return { ...step, status: 'upcoming' };
    }
  });

  return (
    <nav className="pt-[2rem]" aria-label="Progress">
      {/* <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md  md:flex md:divide-y-0 "
      > */}
      <div className="relative flex items-center w-full">
        {updatedSteps.map((step) => {
          // console.log("steps",updatedSteps)
          return (
            <div
              className={`h-1 w-full  ${
                step.status === "progress" || step.status === "current" || step.status ==="complete" ? "bg-[#8dcbbd]" : "bg-transparent"
              }`}
            ></div>
          );
        })}
      </div>
      {/* {updatedSteps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === "complete" ? (
              // <a href={step.href} className="group flex w-full items-center">
              <Link to={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-2 py-1 md:py-2 text-sm font-medium">
                  <span className="flex h-5 w-5 md:h-8 md:w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-storygreen bg-storygreen group-hover:bg-storygreen">
                    <CheckIcon className="h-6 w-6 text-storytan" aria-hidden="true" />
                  </span>
                  <span className="ml-2 text-xs font-medium text-storygreen text-left leading-none">
                    {step.name}
                  </span>
                </span>
              </Link>
            ) : step.status === "current" ? (
              <Link
                to={step.href}
                className="flex items-center px-2 py-1 md:py-2 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-4 w-4 md:h-8 md:w-8 flex-shrink-0 items-center justify-center rounded-full border-2 bg-storyblue border-storyblue">
                  <span className="text-storytan text-xs">{step.id}</span>
                </span>
                <span className="ml-2 text-xs font-bold text-storyblue text-left leading-none">
                  {step.name}
                </span>
              </Link>
            ) : (
              <Link to={step.href} className="group flex items-center">
                <span className="flex items-center px-2 py-1 md:py-2 text-sm font-medium">
                  <span className="flex h-4 w-4 md:h-8 md:w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900 text-xs">{step.id}</span>
                  </span>
                  <span className="ml-2 text-xs font-medium text-gray-500 group-hover:text-gray-900 text-left leading-none">
                    {step.name}
                  </span>
                </span>
              </Link>
            )} 

          {stepIdx !== steps.length - 1 ? (
              <>
                //  Arrow separator for lg screens and up 
               <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null} 
          </li>
        ))}  */}
      {/* </ol> */}
    </nav>
  );
};

export default StepsBar;