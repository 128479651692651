import React, { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import SearchInput from "../../components/Common/SearchInput";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
import {
  convertMidjourneyTimestampToDateTime,
  convertTimestampToDateTime,
  secondsToMinutesAndSeconds,
} from "../../helper/helper";
import TableHeader from "../../components/Common/TableHeader";
import { debounce } from "../../helper/Debounce";
import { Toast } from "../../components/Common/Toast";
import PaginationButtons from "../../components/Common/Pagination";
import { NoRecordFound } from "../../components/Common/NoRecordFound";

const MidJourneyStats = () => {
  const [loader, setLoader] = useState(true);
  const [MidJourneyStats, setMidJourneyStats] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const getMidJourneyStats = async () => {
    try {
      setLoader(true);
      const logs = await callAPI(`admin/imageGenStats/?pageNumber=${currentPage}`);
      console.log("logss,", logs);
      setMidJourneyStats(logs?.books || []);
      setTotalPages(logs?.totalPages);
      setLoader(false);
    } catch {
      setLoader(false);
      Toast("someThing Went Wrong!", true);
    }
  };

  useEffect(() => {
    getMidJourneyStats();
  }, []);

  useEffect(() => {
    getMidJourneyStats();
  }, [currentPage]);

  const searchError = async () => {
    try {
      setsearchLoading(true);
      const data = await callAPI(`api/error-log/error-search?searchTerm=${searchTerm}`);
      setMidJourneyStats(data?.message);
      setsearchLoading(false);
    } catch (error) {
      console.error("Error searching Coupons:", error);
      setsearchLoading(false);
    }
  };

  const debouncedSearch = debounce(searchError, 300);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      debouncedSearch();
    }
  }, [searchTerm]);

  const MakeFormatString = (str) => {
    return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="p-0 md:p-4">
      {loader ? (
        <div className="w-full  h-80 flex justify-center items-center">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : (
        <>
          <div className="float-left md:float-right mb-2 ">
            <SearchInput placeholder={"Search Error"} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>

          {searchLoading ? (
            <div className="w-full h-80 flex justify-center items-center">
              <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
            </div>
          ) : (
            <>
              <div className="w-full overflow-auto h-[55vh] md:h-[63vh] flex flex-col justify-between">
                <div className="h-[17rem] md:h-[34rem]">
                  {" "}
                  <table className="min-w-full border border-gray-300">
                    {TableHeader([
                      "Book Id",
                      "Job Id",
                      "MidJourney Mode",
                      "Request Start Time",
                      "Time taken to Completion",
                    ])}

                    {/*  */}
                    <tbody>
                      {MidJourneyStats && MidJourneyStats.length > 0 ? (
                        MidJourneyStats?.map((log, index) => {
                          // console.log("inside map", log);
                          return (
                            <>
                              <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                <td className="py-2 text-left px-4 border-b">
                                  {MakeFormatString(log?.bookId)}
                                </td>
                                <td className="py-2 text-left px-4 border-b">{log?.characterObj.jobId}</td>
                                <td className="py-2 text-left px-4 border-b capitalize">
                                  {log?.characterObj.characterMidJourneyMode
                                    ? log?.characterObj?.characterMidJourneyMode
                                    : "-"}
                                </td>
                                <td className="py-2 text-left px-4 border-b">
                                  {log?.characterObj.startTime
                                    ? convertMidjourneyTimestampToDateTime(log?.characterObj?.startTime)
                                    : "-"}
                                </td>
                                <td className="py-2 text-left px-4 border-b capitalize">
                                  {log?.characterObj.duration
                                    ? secondsToMinutesAndSeconds(log?.characterObj?.duration)
                                    : "-"}
                                </td>
                              </tr>
                              {log?.pagesData &&
                                log?.pagesData.length > 0 &&
                                log?.pagesData?.map((page, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="py-2 text-left px-4 border-b">
                                          {MakeFormatString(log?.bookId)}
                                        </td>
                                        <td className="py-2 text-left px-4 border-b">{page?.jobId}</td>
                                        <td className="py-2 text-left px-4 border-b capitalize">
                                          {page?.midJourneyMode ? page?.midJourneyMode : "-"}
                                        </td>
                                        <td className="py-2 text-left px-4 border-b">
                                          {page?.requestStartTime
                                            ? convertMidjourneyTimestampToDateTime(page?.requestStartTime)
                                            : "-"}
                                        </td>
                                        <td className="py-2 text-left px-4 border-b capitalize">
                                          {page?.requestDuration
                                            ? secondsToMinutesAndSeconds(page?.requestDuration)
                                            : "-"}
                                        </td>
                                        {/* <td className="py-2 text-left px-4 border-b capitalize">
                                          {page?.requestDuration
                                            ? secondsToMinutesAndSeconds(page?.requestDuration)
                                            : "-"}
                                        </td> */}
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          );
                        })
                      ) : (
                        <NoRecordFound colSpan={8} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>{" "}
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MidJourneyStats;
