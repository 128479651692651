import React from 'react'
import HelpImage from "../../images/help.png";
import { Link } from "react-router-dom";




const Guide= ({ to = "https://buildmystory.ai/guide/" ,className="" })=> {
  return (
    <div className={`flex items-center ${className} `}>
      <Link
        to={to}
        target="_blank"
        className="flex-shrink-0 flex items-center ml-2 "
      >
        <img className="h-[30px] md:h-[30px]" src={HelpImage} alt="Build My Story Guide" title="Guide" />
      </Link>
    </div>
  );
}

export default Guide