import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../services/apiService";
import { useUser } from "../../context/userContext";
import Dialogs from "../../components/Common/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  HTTP_STATUS_200,
  LOCAL_STORAGE_STORY_STEP,
  STORY_STEP_ILLUSTRATION_CREATE,
} from "../../helper/Constant";
import { Toast } from "../../components/Common/Toast";
import Loader from "../../components/Common/Loader";
import Heading from "../../components/Common/Heading";
import { goToTopOfPage, setCurrentStoryStep } from "../../helper/helper";
import { saveCharacterForImagePrompt } from "../Builder/builderHelpers";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
import Guide from "../../components/Common/Guide";

const CharacterLibrary = ({
  getCharacterLibary,
  loaders,
  library,
  setLibrary,
  setUploadedImageUrl,
  setShowCharacterLibrary,
  currentPortrait,
  setCurrentPortrait,
  className = `w-72 h-72 md:w-48 md:h-48 rounded-lg hover:border-4 hover:border-storygreen bg-gray-200 cursor-pointer`,
  setCurrentStep,
  currentStep,
  setLoaders,
  setStateData,
  stateData,
  artStyle,
  selectedArtStyle,
  startStepFour,
  resetCharacter,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [showBackButton, setShowBackButton] = useState(false);
  const [selectedPortrait, setSelectedPortrait] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const { bookId } = useApp();
  const {  storyData } = useStory();

  const handleDeleteConfirmed = async () => {
    try {
      const response = await callAPI(`api/book-update/remove-character`, {
        email: user?.email,
        characterUrl: selectedPortrait?.characterUrl,
      });
      if (response?.status === HTTP_STATUS_200) {
        getCharacterLibary(user?.email, loaders, setLoaders, setLibrary);
        setSelectedPortrait(null);
        setOpenDialog(false);
        Toast("Character Deleted Successfully");
      } else Toast("Character Not Deleted", true);
    } catch (error) {
      console.error("Error deleting character:", error);
    }
  };

  useEffect(() => {
    goToTopOfPage();
    getCharacterLibary(user?.email, loaders, setLoaders, setLibrary, artStyle);
  }, []);

  useEffect(() => {
    if (sortOption) {
      if (sortOption === "recent") {
        setLibrary([...library]?.sort((a, b) => a.timestamp - b.timestamp));
      } else if (sortOption === "name") {
        setLibrary([...library]?.sort((a, b) => a.characterName.localeCompare(b.characterName)));
      }
    }
  }, [sortOption, library]);

  return (
    <div className="text-left m-2 mb-8 text-sm text-storyblue animate__animated animate__slideInRight">
      <div className="flex justify-between">
        {/* <div className=" w-full flex justify-between ">
          <Heading title="Character Library" />
        </div> */}
        <div className=" flex items-center align-end mb-4">
          <Heading title="Character Library" />
          <div className="mt-1">
            <Guide />
          </div>
        </div>
        <div className="flex gap-2">
          {/* {showBackButton && (
            <button
              className=" px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11 "
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              Back
            </button>
          )} */}

          <button
            className=" px-4 bg-storyblue text-white font-bold rounded-md text-sm h-11"
            onClick={() => {
              setShowCharacterLibrary(false);
            }}
          >
            Close
          </button>
        </div>
      </div>

      {loaders.CharacterLibrary ? (
        <div className="flex justify-center">
          <Loader loader={loaders.CharacterLibrary} />
        </div>
      ) : (
        <>
          <div className="w-full flex md:flex-nowrap flex-wrap justify-between items-center gap-4 my-4">
            <p className="text-left text-sm text-storyblue">
              Select an existing Character or create a new One. To create a new character, click on Generate
              New Character button at bottom! Happy story building
            </p>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="rounded-md text-base h-10"
              placeholder="Sort By"
            >
              <option value="" disabled>
                Sort By
              </option>
              <option value="recent">Sort by Recent</option>
              <option value="name">Sort by Name</option>
            </select>
          </div>
          <div className="w-full grid grid-cols-1s md:grid-cols-3 lg:grid-cols-6 gap-2 overflow-y-auto p-0 md:pr-2 h-[20rem]">
            {library &&
              library?.length > 0 &&
              library
                ?.filter((character) => character.artStyle === selectedArtStyle?.id)
                ?.map((character, index) => {
                  const isSelected = selectedPortrait === (character || currentPortrait);
                  const imageClass = isSelected
                    ? "border-4 border-storyblue"
                    : selectedPortrait
                    ? "opacity-50"
                    : "";
                  return (
                    <div className="flex flex-col gap-2 mx-auto">
                      <div
                        className={`${className} ${imageClass}`}
                        // className={`h-72 w-72 rounded-lg hover:border-8 hover:border-storygreen bg-gray-200 cursor-pointer ${imageClass}`}
                        style={{
                          backgroundImage: `url('${character?.characterUrl || "/loading.gif"}')`,
                          backgroundSize: "contain",
                        }}
                        onClick={() => {
                          setSelectedPortrait(character);
                          // setIsCoverSelected(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className={`text-[#EF4444] text-lg cursor-pointer ${
                            selectedPortrait ? "ml-64 md:ml-40" : "ml-60 md:ml-40"
                          } mt-4`}
                          onClick={() => {
                            setSelectedPortrait(character);
                            setOpenDialog(true);
                          }}
                        />
                      </div>
                      <p className="capitalize">
                        <strong>Character Name:</strong> {character?.characterName}
                      </p>
                      <p className="capitalize">
                        <strong>Art style:</strong> {character?.artStyle}
                      </p>
                    </div>
                  );
                })}
          </div>
          <div className="flex gap-2 flex-wrap md:flex-nowrap">
            {/* {selectedPortrait && ( */}
            <button
              disabled={!selectedPortrait}
              onClick={async () => {
                if (selectedPortrait?.artStyle === selectedArtStyle?.id) {
                  if (!storyData?.illustrationCharacter) {
                    setUploadedImageUrl(selectedPortrait?.characterUrl); //this must be set to view in Builder
                    setCurrentPortrait(selectedPortrait?.characterUrl);
                    setStateData({ ...stateData, characterId: selectedPortrait?.characterId });
                    setShowCharacterLibrary(false);
                    startStepFour();
                  } else {
                    /**
                     * Navigate to Generate New Illustration Component
                     */
                    await saveCharacterForImagePrompt(
                      storyData?.illustrationCharacter?.bookId,
                      selectedPortrait?.characterUrl,
                      storyData?.illustrationCharacter?.pageIndex
                    );
                    setShowCharacterLibrary(false);
                    setCurrentStep(3);
                  }
                  // localStorage.setItem(LOCAL_STORAGE_STORY_STEP, STORY_STEP_ILLUSTRATION_CREATE);
                  setCurrentStoryStep(STORY_STEP_ILLUSTRATION_CREATE);
                } else {
                  setShowBackButton(true);
                  Toast("Selected Character Artstyle is Different Than Chosen Artstyle", true);
                }
              }}
              className={`mt-4 submit-button w-full bg-storyblue text-white font-bold rounded-md text-base p-2 ${
                selectedPortrait ? "" : "opacity-50"
              }`}
            >
              Select Existing Character
            </button>
            {/* )}*/}
            <button
              onClick={() => {
                // setSelectedImage(null);
                // setCurrentPortrait(null);
                // setimageCharacterPrompt(null);
                // setShowCharacterLibrary(false);
                resetCharacter();
                //  setShowCharacterLibrary(false);
              }}
              className="md:mt-4 submit-button w-full bg-storygreen text-white font-bold rounded-md text-base p-2"
            >
              Generate New Character
            </button>
          </div>
        </>
      )}
      <Dialogs
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        handelProcess={handleDeleteConfirmed}
        confirmMessage="Are you sure you want to delete this character?"
      />
    </div>
  );
};
export default CharacterLibrary;
