import React, { useEffect, useState } from "react";
import axios from "axios";
import { Toast } from "../../components/Common/Toast";
import BarChart from "../../components/Charts/BarChart";

const AdminStats = () => {
  const [stats, setStats] = useState(null);

  const getstats = async () => {
    try {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BUILD_MY_STORY_API}/stats`,
        headers: {
          token: process.env.REACT_APP_BUILD_MY_STORY_API_TOKEN,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };
      const res = await axios.request(options);
      if (res?.status === 200) setStats(res?.data);
      else Toast("Error Getting Stats", true);
     
    } catch (error) {
      Toast("Error Getting Stats", true);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    getstats();
  }, []);

  return (
    <div className="container mx-auto p-4">
      
       {stats && <BarChart data={stats?.running_jobs} />} 
     
       <h2 className="text-storyblue text-base mx-auto">Jobs in Queue: {stats?.queued_jobs || 0}</h2>
    </div>
  );
};

export default AdminStats;
