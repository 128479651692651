import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { callAPI } from "../../services/apiService";
import Dialogs from "../../components/Common/Dialog";
import { Toast } from "../../components/Common/Toast";
import SearchInput from "../../components/Common/SearchInput";
import Button from "../../components/Common/Button";
import { convertTimestampToDateTime } from "../../helper/helper";
import { AdminUserForm } from "./Forms/AdminUserForm";
import { LOCAL_STORAGE_TOKEN, ROLE_ADMIN, ROLE_SUPERADMIN } from "../../helper/Constant";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../components/Common/TableHeader";
import PaginationButtons from "../../components/Common/Pagination";
import { debounce } from "../../helper/Debounce";
import { NoRecordFound } from "../../components/Common/NoRecordFound";

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  const getUsers = async () => {
    setLoading(true);
    const data = await callAPI(`admin/admin-user?pageNumber=${currentPage}&role=${ROLE_ADMIN}`);
    setUsers(data?.users);
      setTotalPages(data?.totalPages);
    setLoading(false);
  };
  const searchUsers = async () => {
    try {
      setSearchLoader(true);
      const data = await callAPI(`admin/admin-user-search?search=${searchText}`);
      setUsers(data?.message);
      setSearchLoader(false);
    } catch (error) {
      console.error("Error searching users:", error);
          Toast("Error Searching Users", true);
      setSearchLoader(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const debouncedSearchUsers = debounce(searchUsers, 300);

  useEffect(() => {
    if (searchText.length >= 3)  debouncedSearchUsers();
    if (!searchText)  getUsers();
    
  }, [searchText]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const handleEdit = async (id) => {
    const userDetail = await callAPI(`admin/user?id=${id}`);
    if (userDetail) {
      setShowForm(true);
      setUserDetails(userDetail);
    }
  };

  const handleDelete = async () => {
    try {
      await callAPI(`admin/user-delete`, {
        email: userId,
      });
      Toast("User Deleted Successfully");
      getUsers();
      setOpenDialog(false);
    } catch (err) {
      Toast("User Delete Failed", true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="p-0 md:p-4">
      {showForm ? (
        <AdminUserForm setShowForm={setShowForm} getUsers={getUsers} userDetails={userDetails} />
      ) : (
        <>
          <div>
            <div className="flex justify-between items-baseline flex-wrap gap-2 my-5">
              <Button
                title="New User"
                className="w-40 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base p-[0.4rem]"
                onclick={() => {
                  setShowForm(true);
                  setUserDetails(null);
                }}
              />
              <SearchInput placeholder={"Search User"} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>
          {loading ? (
            <div className="w-full h-80 flex justify-center items-center">
              <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
            </div>
          ) : (
            <div className="w-full overflow-auto h-[55vh] md:h-[63vh] flex flex-col justify-between">
              <div className="h-[17rem] md:h-[34rem]">
                <table className="min-w-full border border-gray-300">
                  {TableHeader(["Name", "Email", "Role", "Created At", ""])}

                  <tbody>
                    {users && users.length > 0 ? (
                      users?.map((user, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                          <td className="text-left py-2 px-4 border-b">
                            {user.firstName} {user.lastName}
                          </td>
                          <td className="text-left py-2 px-4 border-b">{user.email}</td>
                          <td className="text-left py-2 px-4 border-b capitalize">
                            {user.role?.replaceAll("-", " ")}
                          </td>
                          <td className="text-left py-2 px-4 border-b">
                            {convertTimestampToDateTime(user?.timestamp)}
                          </td>
                          <td className="text-left py-4 px-4 border-b flex gap-5">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="cursor-pointer"
                              title="Edit User"
                              onClick={() => handleEdit(user.id)}
                            />
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="cursor-pointer"
                              title="Delete User"
                              onClick={() => {
                                setOpenDialog(true);
                                setUserId(user.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFound />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          )}
          <Dialogs openDialog={openDialog} handelClose={handleClose} handelProcess={handleDelete} />
        </>
      )}
    </div>
  );
};
export default Users;
