import React, { useEffect, useState } from "react";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { Toast } from "../../../components/Common/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ROLE_ADMIN, ROLE_SALES, ROLE_SUPERADMIN } from "../../../helper/Constant";
export const AdminUserForm = ({ setShowForm, getUsers, userDetails }) => {
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: userDetails
      ? Yup.string() // Optional password validation if userDetails exist
      : Yup.string().required("Password is required"), // Required password validation if userDetails do not exist
    role: Yup.string().required("Role is required"),
  });
  const formik = useFormik({
    initialValues: {
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  // console.log("formikk val", formik.values);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let response;
    try {
      setLoader(true);
      if (!userDetails)
        response = await callAPI("admin/user-add", {
          userId: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          password: formik.values.password,
          role: formik.values.role,
        });
      else
        response = await callAPI("admin/user-update", {
          userId: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          password: formik.values.password ? formik.values.password : null,
          role: formik.values.role || "",
        });
      if (response?.error) Toast(response?.error, true);
      else {
        Toast("User Saved Successfully");
        getUsers();
        setShowForm(false);
      }
      setLoader(false);
      formik.resetForm();
    } catch {
      Toast("User Failed To Save", true);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (userDetails) {
      const values = {
        userId: userDetails?.users?.email,
        firstName: userDetails?.users?.firstName,
        lastName: userDetails?.users?.lastName,
        email: userDetails?.users?.email,
        password: userDetails?.users?.password,
        role: userDetails?.users?.role,
      };
      formik.setValues(values);
    }
  }, [userDetails]);
  return (
    <div className="bg-storytan mx-auto">
      <div className="max-w-7xl mx-auto min-h-screen">
        {userDetails ? (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">Edit User</h1>
        ) : (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">New User</h1>
        )}
        <form
          id="form-admin-user"
          name="form-admin-user"
          className="text-start"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            <div className="flex flex-col gap-2">
              <label className="font-medium">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="First Name"
                className="p-2 w-full rounded-md"
                autoFocus={true}
              />
              <div className="text-red-600 mt-1">{formik.errors.firstName}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Last Name"
                className="p-2 w-full rounded-md"
              />
              <div className="text-red-600 mt-1">{formik.errors.lastName}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Email</label>
              <input
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={userDetails?.users.email}
                placeholder="Email"
                className={`p-2 w-full rounded-md  ${
                  userDetails?.users.email ? "bg-white text-[#9EA4B0]" : ""
                }  `}
              />
              <div className="text-red-600 mt-1">{formik.errors.email}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Password"
                  className="p-2 w-full rounded-md pr-10"
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="text-gray-500 hover:text-gray-700"
                  />
                </span>
              </div>
              {userDetails && (
                <div>
                  {" "}
                  <i className="text-gray-500">To Change Password, Kindly Type Your New Password</i>
                </div>
              )}
              <div className="text-red-600 mt-1">{formik.errors.password}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Role</label>
              <select
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
              >
                <option value="">Select a Role</option>
                <option value={ROLE_SUPERADMIN}>Super Admin</option>
                <option value={ROLE_ADMIN}>Admin</option>
                <option value={ROLE_SALES}>Customer Service</option>
              </select>
              <div className="text-red-600 mt-1">{formik.errors.role}</div>
            </div>
          </div>
          <SubmitButton title="Save" loader={loader} cancelOperation={setShowForm} />
        </form>
      </div>
    </div>
  );
};
