import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useUser } from "../../context/userContext";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Loader from "../../components/Common/Loader";
import Heading from "../../components/Common/Heading";
import Footer from "../../components/Footer/Footer";
import {  useNavigate } from "react-router-dom";
import {
  HTTP_STATUS_200,
  USER_SETTING_PROMOTIONAL_EMAILS,
  USER_SETTING_MARKETING_EMAILS,
} from "../../helper/Constant";
import Dialogs from "../../components/Common/Dialog";
import Guide from "../../components/Common/Guide";
import PlanPurchase from "../../components/Common/PlanPurchase";
import ThankYou from "../../components/Common/ThankYou";

const Profile = () => {
  const navigateTo = useNavigate();
  const { user, setUser } = useUser();
  const [openDialog, setOpenDialog] = useState(false);
  const [purchasePlan, setPurchasePlan] = useState(false);
  const [selectedPlanDescription, setSelectedPlanDescription] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plans, setPlans] = useState([]);
  const [errors, setErrors] = useState({});

  const [showThanksNote, setShowThanksNote] = useState(false);

  const [loader, setLoader] = useState({
    profileUpdate: false,
    removeSubscription: false,
  });

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    plan: "",
    settings: {
      USER_SETTING_MARKETING_EMAILS: false,
      USER_SETTING_PROMOTIONAL_EMAILS: false,
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      settings: user?.settings || {},
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(USER_SETTING_PROMOTIONAL_EMAILS) || name.includes(USER_SETTING_MARKETING_EMAILS)) {
      if (value === "true") {
        setValues((prevValues) => ({
          ...prevValues,
          settings: {
            ...prevValues.settings,
            [name]: true,
          },
        }));
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          settings: {
            ...prevValues.settings,
            [name]: false,
          },
        }));
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handlePlanChange = (event) => {
    // const selectedIndex = event.target.value - 1;
    const selectedObj = plans.find((value) => value.id === event.target.value);
    setSelectedPlanDescription(selectedObj);
    setSelectedPlan(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader({ ...loader, profileUpdate: true });
      await callAPI("user/updateUser", {
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        settings: values?.settings,
      });
      setUser({
        ...user,
        firstName: values?.firstName,
        lastName: values?.lastName,
      });
      Toast("Profile Updated Successfully");
      setLoader({ ...loader, profileUpdate: false });
    } catch (err) {
      Toast("User Profile Not Updated", true);
      setLoader({ ...loader, profileUpdate: false });
    }
  };

  const cancelSubscription = async () => {
    setOpenDialog(false);
    setLoader({ ...loader, removeSubscription: true });
    const cancelSub = await callAPI("user/cancel-subscription", { email: user?.email });
    Toast(
      cancelSub?.message || "Unexpected Error Occurred",
      cancelSub?.status === HTTP_STATUS_200 ? false : true
    );
    setLoader({ ...loader, removeSubscription: false });
    setPurchasePlan(false);
  };

  const goBack = () => {
    navigateTo(-1);
    setShowThanksNote(false);
  };

  return (
    <div className="App">
      <NavigationBar />
      {showThanksNote ? (
        <ThankYou goBack={goBack} />
      ) : (
        <div className="bg-storytan p-4 mx-auto">
          {purchasePlan ? (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* <PurchasePlan user={user} setPlanSubscribed={setSelectedPlan} /> */}
              <PlanPurchase />
            </div>
          ) : (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* <Heading title="My Profile" /> */}
              <div className=" flex items-center align-end mb-4">
                <Heading title="My Profile" />
                <div className="mt-1">
                  <Guide />
                </div>
              </div>
              <form id="form-signup" name="form-signup" className="mt-8 text-start" onSubmit={handleSubmit}>
                {/* <h1 className="font-bold text-storyblue">Credit Balance: {user?.creditBalance}</h1> */}
                <input
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  className="mt-4 p-2 w-full rounded-md"
                  autofocus
                />
                <div className="text-red-600 mt-1">{errors.firstName}</div>
                <input
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  className="mt-4 p-2 w-full rounded-md"
                />
                <div className="text-red-600 mt-1">{errors.lastName}</div>
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="mt-4 p-2 w-full rounded-md text-[#9ea4b0]"
                  readOnly
                />
                <div className="text-red-600 mt-1">{errors.email}</div>
                {errors.confirmPassword && <div className="text-red-600 mt-1">{errors.confirmPassword}</div>}
                <div className="text-red-600 mt-1 ml-5">{errors.isAdult}</div>

                {values?.settings &&
                  Object.entries(values?.settings).map(([key, value]) => {
                    return (
                      <div className=" w-full flex flex-col  space-between ">
                        <label className="capitalize mt-2 pt-2 cursor-pointer">
                          {key.replace(/_/g, " ").replace("USER", "").toLocaleLowerCase()}
                        </label>
                        <select
                          className="mt-4 p-2 w-[50%] md:w-[15%] cursor-pointer bg-white rounded-md "
                          id={key}
                          name={key}
                          onChange={(e) => handleChange(e)}
                          value={value}
                        >
                          <option className="capitalize mt-2 pt-2" value={true}>
                            Yes
                          </option>
                          <option className="capitalize mt-2 pt-2" value={false}>
                            No
                          </option>
                        </select>
                      </div>
                    );
                  })}

                <div className="flex flex-wrap gap-2 md:flex-nowrap mt-5">
                  <button
                    type="submit"
                    className="w-full md:w-[30%] flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base h-11 items-center"
                    disabled={loader?.profileUpdate}
                  >
                    Update Profile
                    <Loader loader={loader?.profileUpdate} />
                  </button>
                  {/* {user?.stripeSubscriptionId ? (
                  <button
                    type="button"
                    className="w-full md:w-[50%] flex justify-center gap-2 bg-storygreen text-white font-bold rounded-md text-base h-11 items-center"
                    disabled={loader?.removeSubscription}
                    onClick={() => setOpenDialog(true)}
                  >
                    Cancel Subscription
                    <Loader loader={loader?.removeSubscription} />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-[50%] flex justify-center gap-2 bg-storygreen text-white font-bold rounded-md text-base h-11 items-center mt-5"
                    onClick={() => setPurchasePlan(true)}
                  >
                    Purchase Plan
                  </button>
                )} */}
                </div>
              </form>
            </div>
          )}
        </div>
      )}
      <Footer />

      <Dialogs
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        handelProcess={cancelSubscription}
        confirmMessage="Are you sure you want to cancel Subscription? If subscription cancelled, you may no longer have access to the books you created."
      />
    </div>
  );
};

export default Profile;
