import React from "react"; // >3
const PaginationButtons = ({ totalPages, currentPage, handlePageChange }) => {
  const renderPaginationButtons = () => {
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
    if (totalPages <= 3) {
      return pages.map((page, i) => {
        const pageNo = ++i;
        return (
          <li
            key={pageNo}
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(pageNo);
            }}
          >
            <a
              // href="#"
              onClick={()=>{handlePageChange(currentPage-1);}}
              className={`flex items-center justify-center px-3 h-8 leading-tight text-white border border-gray-300 ${
                pageNo === currentPage
                  ? "bg-storygreen hover:bg-storygreen dark:bg-storygreen dark:hover:bg-storygreen"
                  : "bg-storyblue hover:bg-storygreen dark:bg-storyblue dark:hover:bg-storygreen"
              }`}
            >
              <span className={pageNo === currentPage ? "text-white font-bold" : ""}>{pageNo}</span>
            </a>
          </li>
        );
      });
    } else {
      const firstPage = 1;
      const lastPage = totalPages;
      const currentPageIndex = currentPage - 1;
      const buttons = [];
      if (currentPageIndex < 2) {
        buttons.push(...pages.slice(0, 3), "...", lastPage);
      } else if (currentPageIndex >= totalPages - 2) {
        buttons.push(firstPage, "...", ...pages.slice(-3));
      } else {
        buttons.push(
          firstPage,
          "...",
          ...pages.slice(currentPageIndex - 1, currentPageIndex + 2),
          "...",
          lastPage
        );
      }
      return buttons.map((button, index) => {
        if (button === "...") {
          return (
            <li key={index}>
              <span className="p-1 font-bold ">. . .</span>
            </li>
          );
        } else {
          return (
            <li
              key={button}
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(button);
              }}
            >
              <a
                // href="#"
                onClick={() => {
                  handlePageChange(currentPage + 1);
                }}
                className={`flex items-center justify-center px-3 h-8 leading-tight text-white border border-gray-300 ${
                  button === currentPage
                    ? "bg-storygreen hover:bg-storygreen dark:bg-storygreen dark:hover:bg-storygreen"
                    : "bg-storyblue hover:bg-storygreen dark:bg-storyblue dark:hover:bg-storygreen"
                }`}
              >
                <span className={button === currentPage ? "text-white font-bold" : ""}>{button}</span>
              </a>
            </li>
          );
        }
      });
    }
  };
  return (
    <nav aria-label="Page navigation example">
      <ul className="flex items-center -space-x-px h-8 text-sm">
        <li>
          <a
            // href="#"
            onClick={(e) => {
              e.preventDefault();
              if (currentPage > 1) {
                handlePageChange(currentPage - 1);
              }
            }}
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-white-500 bg-storyblue border border-e-0 border-gray-300 rounded-s-lg hover:bg-storygreen dark:bg-storyblue dark:text-white dark:hover:bg-storygreen dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </li>
        {renderPaginationButtons()}
        <li>
          <a
            // href="#"
            onClick={(e) => {
              e.preventDefault();
              if (currentPage < totalPages) {
                handlePageChange(currentPage + 1);
              }
            }}
            className="flex items-center justify-center px-3 h-8 leading-tight text-white-500 bg-storyblue border border-e-0 border-gray-300 rounded-e-lg hover:bg-storygreen dark:bg-storyblue dark:text-white dark:hover:bg-storygreen dark:hover:text-white"
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default PaginationButtons;
