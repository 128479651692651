import React, { useState } from "react";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useUser } from "../../context/userContext";
import SubmitButton from "../../components/Common/SubmitButton";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import Heading from "../../components/Common/Heading";
import LowCreditModal from "../../components/LowCreditModal/LowCreditModal";
import Footer from "../../components/Footer/Footer";

const Profile = () => {
  const { user } = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const [paymentLoader, setPaymentLoader] = useState(false);
  const [showLowCreditModal, setShowLowCreditModal] = useState(true);
  const handlePayment = async () => {
    if (!stripe || !elements) return;

    setPaymentLoader(true);
    const resultStripe = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email,
      },
    });
    if (resultStripe?.error) {
      setPaymentLoader(false);
      Toast(resultStripe?.error?.message, true);
    } else {
      const userData = {
        email: user.email,
        paymentMethod: resultStripe.paymentMethod.id,
      };
      const paymentResponse = await callAPI("user/purchase-credits", userData, "POST");
      if (paymentResponse) {
        Toast("Image Credits Purchased Successfully");
      } else Toast("Unexpected Error Occurred", true);
      setPaymentLoader(false);
    }
  };

  return (
    <div className="App mx-auto">
      <NavigationBar />
      <div className="bg-storytan p-0 md:p-4 mx-auto">
        <div className="max-w-7xl mx-auto pt-4 md:pt-0 px-4 sm:px-6 lg:px-8">
          <LowCreditModal
            showLowCreditModal={showLowCreditModal}
            setShowLowCreditModal={setShowLowCreditModal}
          />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Profile;
