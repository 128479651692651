import { createContext, useContext, useState } from "react";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth, db } from "../firebase";
// import { doc, onSnapshot } from "firebase/firestore";
// import Loader from "../components/Common/Loader";
// import { callAPI } from "../services/apiService";
// import {
//   LOCAL_STORAGE_CURRENT_USER,
//   LOCAL_STORAGE_EDIT_MODE,
//   LOCAL_STORAGE_TOKEN,
//   USER_STATUS_ACTIVE,
// } from "../helper/Constant";
// import { encryptToken } from "../helper/helper";
// import { Toast } from "../components/Common/Toast";

const StoryContext = createContext();

export const StoryProvider = ({ children }) => {
  const [storyNewCharacter, setStoryNewCharacter] = useState(true);
  // const [illustrationCharacter, setIllustrationCharacter] = useState(null);

  const [storyData, setStoryData] = useState({
    gettingError:false,
    uploadedImage: null,
    characterBuilding: false,
    showLibrary: false,
    storybuildingError: false,
    isDummyPic: false,
    creditsPerBookLimit: 0,
  });

  const contextValue = {
    // illustrationCharacter,
    storyNewCharacter,
    // setIllustrationCharacter,
    setStoryNewCharacter,
    storyData,
    setStoryData,
  };

  return <StoryContext.Provider value={contextValue}>{children}</StoryContext.Provider>;
};

export const useStory = () => {
  return useContext(StoryContext);
};
