import React, { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import { Link, useNavigate } from "react-router-dom";
import SearchInput from "../../components/Common/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { useUser } from "../../context/userContext";
import { LOCAL_STORAGE_TOKEN } from "../../helper/Constant";
const Books = () => {
  const [loader, setLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [viewBooks, setViewBooks] = useState(false);
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(allUsers.length / itemsPerPage);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  const getAllUsers = async () => {
    try {
      setLoader(true);
      const data = await callAPI("admin/user");
      setAllUsers(data?.users);
      setLoader(false);
    } catch {
      setLoader(false);
    }
  };
  const getUserBooks = async (email) => {
    try {
      setLoader(true);

      const userBooks = await callAPI(`api/booklist?email=${email}`);
      // console.log("userBooks", userBooks, userBooks?.length);
      setViewBooks(true);
      setBooks(userBooks || []);
      if (userBooks?.length === 0) Toast("No books found");
      setLoader(false);
    } catch (error) {
      console.error("Error fetching user books:", error);
      setLoader(false);
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  // Logic to slice array based on current page
  const displayedUsers = allUsers.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );
  // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <div className="">
      {loader ? (
        <div className="w-full h-80 flex justify-center items-center">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : viewBooks ? (
        <div className="max-w-7xl py-8 mx-auto">
          <div className="mb-4 text-left">
            <button
              className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2 ml-4"
              onClick={() => setViewBooks(false)}
            >
              Back
            </button>
          </div>
          {books.length === 0 ? (
            <p className="text-center text-red-500 font-bold">No Books Found for this Customer</p>
          ) : (
            <div className="  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {books?.map(
                (book, index) =>
                  book.title && (
                    <div
                      key={book.id}
                      className="p-2 book-item font-sans text-white bg-storytan uppercase text-2xl font-bold w-full rounded-md mx-auto mb-2"
                    >
                      <div className="flex justify-center">
                        <img
                          src={book?.pages?.page4?.imageURLwebp}
                          alt={`${book.title} cover`}
                          className="h-[18rem] w-[18rem] object-cover rounded-md shadow-xl mb-2"
                        />
                      </div>
                      <Link
                        to={`/books/${book.id}`}
                        className="text-storytan text-sm h-10 flex items-center justify-center rounded-md bg-storyblue shadow-xl leading-none font-bold font-serif block p-4"
                      >
                        {book.title}
                      </Link>
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        <Link to={`/books/${book.id}`}>
                          <div className="w-full h-8 flex justify-center items-center pointer-grab text-white text-xs rounded-md bg-storygreen shadow-xl leading-none font-bold p-2 capitalize">
                            Page View
                          </div>
                        </Link>
                        <Link to={`/bookflip/${book.id}`}>
                          <div className="w-full h-8 flex justify-center items-center text-white text-xs bg-storygreen rounded-md shadow-xl leading-none font-bold p-2 capitalize">
                            FlipBook
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className=" float-right mb-2">
            <SearchInput placeholder={"Search Book"} />
          </div>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="text-left py-2 px-4 border-b">Customer Name</th>
                <th className="text-left py-2 px-4 border-b">Customer Email</th>
                <th className="text-left py-2 px-4 border-b"></th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                  <td className="py-2 text-left px-4 border-b">
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td className="py-2 text-left px-4 border-b">{user?.email}</td>
                  <td className="py-2 text-left px-4 border-b">
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      className="cursor-pointer"
                      title="Books"
                      onClick={() => getUserBooks(user?.email)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            {/* <tbody>
              {displayedUsers.map((user, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="text-left py-2 px-4 border-b">
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td className="text-left py-2 px-4 border-b">{user.email}</td>
                  <td className="text-left py-2 px-4 border-b">
                    <button
                      className="flex justify-center bg-storyblue text-white font-bold rounded-md p-2"
                      onClick={() => getUserBooks(user.email)}
                    >
                      View Books
                    </button>
                  </td>
                </tr>
              ))}
            </tbody> */}
          </table>
          <div className="flex justify-end mt-4 gap-2">
            <div>Total Users: {allUsers?.length}</div>
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              containerClassName="pagination flex gap-2"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="font-bold"
              previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
              nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Books;
