import { useNavigate } from "react-router-dom";
import { callAPI } from "../../services/apiService";
import {
  DEDUCT_CREDITS_PER_IMAGE,
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  HTTP_STATUS_403,
  LOCAL_STORAGE_BOOK_ID,
  LOCAL_STORAGE_STORY_STEP,
  PROMO_PLAN,
  STORY_LANGUAGE_ENGLISH,
  STORY_LANGUAGE_FRENCH,
  STORY_LANGUAGE_GERMAN,
  STORY_LANGUAGE_SPANISH,
  STORY_STEP_CHARACTER_BUILD,
  TYPE_ART_STYLE,
  TYPE_CHILD_AGE,
  TYPE_STORY,
  TYPE_WRITING_STYLE,
} from "../../helper/Constant";
import { Toast } from "../../components/Common/Toast";
import { useUser } from "../../context/userContext";
import Loader from "../../components/Common/Loader";
import { makeDropdownOptions, setCurrentStoryStep } from "../../helper/helper";
import { useEffect, useState } from "react";
import { DropdownArrow, StoryTypes, StoryWritingStyle, fetchBookData, getArtStyles } from "./builderHelpers";
import { useApp } from "../../context/appContext";

import Guide from "../../components/Common/Guide";


const StepStoryInformation = ({
  setBookId,
  setSelectedBookId,
  setLoader,
  setShowLowCreditModal,
  setSelectedStoryConfig,
  setMaxpages,
  setShowDescription,
  setStoryTopic,
  setStoryType,
  setChildName,
  setArtStyle,
  setChildAge,
  setPageTotal,
  setWritingStyle,
  bookId,
  currentStep,
  childName,
  childAge,
  storyTopic,
  storyType,
  writingStyle,
  pageTotal,
  artStyle,
  storyconfig,
  storyExample,
  selectedStoryConfig,
  wordRange,
  startStepTwo,
  pageOptions,
  loader,
  showLowCreditModal,
  showDescription,
  storyLanguage,
  setStoryLanguage,
  selectedArtStyle,
  setSelectedArtStyle,
  setStoryData,
  storyData,
}) => {
  const navigateTo = useNavigate();
  const { user } = useUser();
  const [bookPages, setBookPages] = useState([]);
  const {bookData}=useApp();

  

  const handlePageTotalSelect = (e) => {
    if (!childAge) {
      e.preventDefault();
      Toast("Please Select Reading Age Field", true);
    }
  };
  useEffect(() => {
    getArtStyles(setArtStyle);
  }, []);

  useEffect(() => {
    /**
     * Check Max Allowed Book Page From User Plan
     */
    const filteredRecords = pageOptions.filter(
      (record) => parseInt(record.key) <= parseInt(user?.plan?.maxBookPage)
    );

    // console.log(filteredRecords);
    setBookPages(filteredRecords);
  }, [pageOptions]);

  const handleSubmitStepOne = async (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = {
      bookId,
      isDraft: true,
      step: currentStep,
      childName,
      childAge,
      storyTopic,
      storyType,
      writingStyle,
      pageTotal,
      artStyle: selectedArtStyle?.id,
      wordRange: storyconfig[selectedStoryConfig]?.words_total || wordRange,
      storyLanguage,
      // isImageProcessing: "n",
      // wordCount,
    };

    try {
      //check if user has enough credits to generate the desired book with pages selected
      let creditsRequired = ((parseInt(pageTotal) - 6) / 2 + 1) * DEDUCT_CREDITS_PER_IMAGE;
      if ((creditsRequired > user?.plan?.credits) && user?.plan?.name != PROMO_PLAN ) {
        Toast("You current plan does not has enough Image Credits to create this story", true);
        setLoader(false);
        // setShowLowCreditModal(true);
        navigateTo("/myPlans")
        return;
      }
      
      const data = await callAPI("api/saveFormData", { email: user.email, formData });
      if (data?.status === HTTP_STATUS_200) {
        setStoryData((prevData) => ({
          ...prevData,
          creditsPerBookLimit: data?.creditsPerBookLimit || bookData?.creditsPerBookLimit,
        }));
        setBookId(data.bookId);
        localStorage.setItem(LOCAL_STORAGE_BOOK_ID, data?.bookId);
        // localStorage.setItem(LOCAL_STORAGE_STORY_STEP, STORY_STEP_CHARACTER_BUILD);
        setCurrentStoryStep(STORY_STEP_CHARACTER_BUILD);
        setSelectedBookId(data.bookId);
        startStepTwo(bookId);
        setLoader(false);
      } else if (data.status == HTTP_STATUS_400 || data.status == HTTP_STATUS_403) {
        setLoader(false);
        Toast(data?.error, true);
        if (data.status == HTTP_STATUS_403) setShowLowCreditModal(true);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      {showLowCreditModal ? (
        navigateTo("/ImageTokens")
      ) : (
        <form onSubmit={handleSubmitStepOne} className="animate__animated animate__slideInRight p-4">
          <div className=" w-full flex justify-between ">
            {/* <h2 className="text-left text-2xl md:text-4xl mb-4 font-bold text-storyblue">Story Details</h2> */}
            <div className="flex items-center align-end  mb-4">
              <h2 className="text-left text-2xl md:text-4xl font-bold text-storyblue">Story Details</h2>
              <Guide />
            </div>
          </div>

          <p className="mb-8 text-left text-sm text-storyblue">
            The first step is to establish the character, topic and goal of the story. Also pick the style of
            art that you want to include in this childrens book.
          </p>
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                Character Name:
              </label>
              <input
                type="text"
                className="w-full rounded-md text-sm md:text-xl p-2"
                value={childName}
                onChange={(e) => {
                  setChildName(e.target.value);
                  setStoryData((prevData) => ({
                    ...prevData,
                    childName: e.target.value,
                  }));
                }}
                autoFocus
              />
            </div>
            <div className="">
              <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                Reading Age:
              </label>
              <div className="grid ">
                <select
                  className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                  value={childAge}
                  onChange={(e) => {
                    setChildAge(e.target.value);
                    setStoryData((prevData) => ({
                      ...prevData,
                      childAge: e.target.value,
                    }));
                    setSelectedStoryConfig(e.target.value);
                    setMaxpages(storyconfig[e.target.value].pages_total);
                    setShowDescription(TYPE_CHILD_AGE);
                  }}
                  placeholder="Reading Age"
                >
                  <option value="" disabled>
                    Please Select Age
                  </option>
                  {Object.keys(storyconfig)
                    .filter((optionKey) => optionKey !== "id")
                    .map((optionKey) => (
                      <option key={optionKey} value={optionKey}>
                        {optionKey}
                      </option>
                    ))}
                </select>
                {DropdownArrow()}
              </div>
              <div className=" block col-span-2">
                {((selectedStoryConfig && storyconfig) || wordRange) && (
                  <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                    Estimated Word Count Per Page:{" "}
                    {storyconfig[selectedStoryConfig]?.words_total || wordRange}
                  </label>
                )}
              </div>
            </div>
            <div className="col-span-2">
              <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                Story Setting &amp; Goal:
              </label>
              <textarea
                type="text"
                className="w-full rounded-md text-sm md:text-xl p-2"
                value={storyTopic}
                onChange={(e) => {
                  setStoryTopic(e.target.value);
                  setStoryData((prevData) => ({
                    ...prevData,
                    storyTopic: e.target.value,
                  }));
                }}
                placeholder="ex: susan wants to learn how to swim but is afraid"
              ></textarea>
            </div>
            <div className="flex flex-col gap-4 col-span-2 md:col-span-1">
              <div className="">
                <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Story Type:
                </label>
                <div className="grid">
                  <select
                    className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                    value={storyType}
                    onChange={(e) => {
                      setStoryType(e.target.value);
                      setShowDescription(TYPE_STORY);
                      setStoryData((prevData) => ({
                        ...prevData,
                        storyType: e.target.value,
                      }));
                    }}
                    placeholder="Story Type"
                  >
                    <option value="" disabled>
                      Please Select Story Type
                    </option>
                    {makeDropdownOptions(StoryTypes())}
                    {/* <option value="bedtime">Bedtime Story</option>
                    <option value="fable">Fable</option>
                    <option value="fairytale">Fairytale</option>
                    <option value="adventure">Adventure</option>
                    <option value="educational">Educational</option>
                    <option value="mystery">Mystery</option>
                    <option value="science_fiction">Science Fiction</option>
                    <option value="realistic_fiction">Realistic Fiction</option> */}
                  </select>
                  {DropdownArrow()}
                </div>
              </div>
              <div className="">
                <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Writing Style:
                </label>
                <div className="grid">
                  <select
                    className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                    value={writingStyle}
                    onChange={(e) => {
                      setWritingStyle(e.target.value);
                      setShowDescription(TYPE_WRITING_STYLE);
                      setStoryData((prevData) => ({
                        ...prevData,
                        writingStyle: e.target.value,
                      }));
                    }}
                    placeholder="Writing Style"
                  >
                    <option value="" disabled>
                      Please Select Writing Style
                    </option>
                    {makeDropdownOptions(StoryWritingStyle())}
                    {/* <option value="imaginative">Imaginative</option>
                    <option value="funny">Funny</option>
                    <option value="heartwarming">Heartwarming</option>
                    <option value="action_packed">Action-Packed</option>
                    <option value="nostalgic">Nostalgic</option>
                    <option value="empowering">Empowering</option>
                    <option value="spooky">Spooky</option>
                    <option value="educational">Educational</option> */}
                  </select>
                  {DropdownArrow()}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Art Style:
                </label>
                <div className="grid">
                  <select
                    className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                    value={selectedArtStyle?.name || ""}
                    onChange={(e) => {
                      const selectedName = e.target.value;
                      const selectedObject = artStyle?.find((style) => style?.name === selectedName);
                      setSelectedArtStyle(selectedObject);
                      setShowDescription(TYPE_ART_STYLE);
                      setStoryData((prevData) => ({
                        ...prevData,
                        artStyle: selectedObject,
                      }));
                    }}
                    placeholder="Style of Art"
                  >
                    <option value="" disabled>
                      Please Select Art Style
                    </option>
                    {artStyle &&
                      artStyle?.map((style) => {
                        if (style?.status === true) {
                          return (
                            <option key={style.id} value={style.name}>
                              {style.name}
                            </option>
                          );
                        }
                      })}
                  </select>
                  {DropdownArrow()}
                </div>
                <div className="mt-4">
                  <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                    Write Story In:
                  </label>
                  <div className="grid">
                    <select
                      className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                      value={storyLanguage}
                      onChange={(e) => {
                        setStoryLanguage(e.target.value);
                        setStoryData((prevData) => ({
                          ...prevData,
                          storyLanguage: e.target.value,
                        }));
                      }}
                      placeholder="Story Language"
                    >
                      <option value="" disabled>
                        Please Select a language
                      </option>
                      <option value={STORY_LANGUAGE_ENGLISH}>English</option>
                      <option value={STORY_LANGUAGE_SPANISH}>Spanish</option>
                      <option value={STORY_LANGUAGE_FRENCH}>French</option>
                      <option value={STORY_LANGUAGE_GERMAN}>German</option>
                    </select>
                    {DropdownArrow()}
                  </div>
                </div>
                {/* {pageOptions.length > 0 && childAge && ( */}
                <div className="mt-4">
                  <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                    Page Total:
                  </label>
                  <div className="grid" onClick={handlePageTotalSelect}>
                    <select
                      className={`${
                        !childAge ? "pointer-events-none opacity-70" : ""
                      } row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none`}
                      value={pageTotal}
                      onChange={(e) => {
                        setPageTotal(e.target.value);
                        setStoryData((prevData) => ({
                          ...prevData,
                          pageTotal: e.target.value,
                        }));
                      }}
                      placeholder="Total Pages"
                    >
                      <option value="" disabled>
                        Please Select Page Total
                      </option>
                      {bookPages}
                    </select>
                    {DropdownArrow()}
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="flex flex-col col-span-2 md:col-span-1 gap-3 items-center justify-center md:items-end">
              <div className="flex justify-center items-center bg-white w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] rounded-lg">
                <h1 className="text-justify text-base text-storyblue">
                  {showDescription === TYPE_CHILD_AGE
                    ? storyExample(showDescription, bookPages)
                    : showDescription === TYPE_STORY
                    ? storyExample(showDescription, storyType)
                    : showDescription === TYPE_WRITING_STYLE
                    ? storyExample(showDescription, writingStyle)
                    : showDescription === TYPE_ART_STYLE
                    ? storyExample(showDescription, selectedArtStyle)
                    : ""}
                </h1>
              </div>
              <div className="mr-0 md:mr-28">
                {showDescription === TYPE_ART_STYLE && (
                  <h2 className="text-sm font-medium text-storyblue">Sample Art Style</h2>
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex justify-center gap-3 bg-storyblue text-white font-bold rounded-md text-base h-11 items-center mt-4 mx-auto md:mx-0 p-2"
            disabled={loader}
          >
            Continue to Build My Character
            <Loader loader={loader} />
          </button>
        </form>
      )}
    </>
  );
};

export default StepStoryInformation;
