import { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { HTTP_STATUS_200, LOCAL_STORAGE_EDIT_MODE } from "../../helper/Constant";
import { Toast } from "../../components/Common/Toast";
import Button from "../../components/Common/Button";
import { goToTopOfPage, scrollToArea } from "../../helper/helper";
import { useApp } from "../../context/appContext";

const ChooseImageFromHistory = ({
  bookData,
  pageIndex,
  setShowImageHistory,
  displayBookPages,
  setStateData,
  stateData,
  targetRefs,
}) => {
  const [selectedImageUrl, setSelectedImageUrl] = useState({ imageUrl: "", imageUrlWebp: "" });
  const [loader, setLoader] = useState(false);
  const { setBookDataModified } = useApp();
  useEffect(() => {
    goToTopOfPage();
    setStateData({ ...stateData, showDownloadPDF: false });
  }, []);

  const handleUpdatePageImage = async () => {
    setLoader(true);
    const response = await callAPI("api/book-update/update-page-image", {
      bookData,
      pageIndex,
      imageURL: selectedImageUrl?.imageUrl,
      imageURLWebp: selectedImageUrl?.imageUrlWebp,
    });
    setLoader(false);
    displayBookPages();
    if (response.status === HTTP_STATUS_200) {
      setShowImageHistory(null);
      setStateData({ ...stateData, showDownloadPDF: true, showImageLibrary: false });
      scrollToArea(targetRefs, pageIndex);
      if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE) === "yes") {
        setBookDataModified(true);
      }
    } else Toast("Image Update Failed", true);
  };

  return (
    <>
      <div className="w-full h-[25rem] overflow-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {bookData?.imageLibrary?.map((page) => {
          const isSelected =
            selectedImageUrl.imageUrl === page?.previousImageURL ||
            selectedImageUrl.imageUrlWebp === page?.previousImageURLWebp;
          const imageClass = isSelected
            ? "border-4 border-storyblue"
            : selectedImageUrl.imageUrl
            ? "opacity-50"
            : "";
          return (
            <div
              key={page}
              className={`w-48 h-48 mx-auto rounded-lg hover:border-4 hover:border-storygreen bg-gray-200 cursor-pointer ${imageClass}`}
              style={{
                backgroundImage: `url('${page?.previousImageURL || "/loading.gif"}')`,
                backgroundSize: "contain",
              }}
              onClick={() => {
                setSelectedImageUrl({
                  imageUrl: page.previousImageURL,
                  imageUrlWebp: page.previousImageURLWebp,
                });
              }}
            ></div>
          );
        })}
      </div>

      <div className="col-span-4 text-center mt-8 w-full mx-auto  flex gap-4 justify-center flex-wrap">
        {/* {(selectedImageUrl?.imageUrl || selectedImageUrl?.imageUrlWebp) && (
          <Button
            title="Choose Image"
            loader={loader}
            onclick={handleUpdatePageImage}
            className="submit-button w-[90vw] flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-xl h-11 items-center"
          />
        )} */}

        <Button
          title="Close"
          onclick={() => {
            setShowImageHistory(null);
            setStateData({ ...stateData, showDownloadPDF: true, showImageLibrary: false });
            scrollToArea(targetRefs, stateData?.pageIndex);
          }}
          className="submit-button w-60 flex justify-center gap-3 max-w-7xl bg-storygreen text-white font-bold rounded-md text-base h-11 items-center"
        />
        <Button
          title="Choose Image"
          loader={loader}
          disabled={!selectedImageUrl?.imageUrl || !selectedImageUrl?.imageUrlWebp}
          onclick={handleUpdatePageImage}
          className={`submit-button w-60 flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-base h-11 items-center ${
            selectedImageUrl?.imageUrl || selectedImageUrl?.imageUrlWebp ? "" : "opacity-50"
          }`}
        />
      </div>
    </>
  );
};

export default ChooseImageFromHistory;
