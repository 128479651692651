import React from 'react';
import { createRoot } from 'react-dom/client'; // Importing createRoot
import './index.css'; // Global styles
import App from './App'; // Importing the App component
// Any other global imports, like context providers or libraries

// Create a root
const container = document.getElementById('root');
const root = createRoot(container);

// Render the app within the root
root.render(
  <React.Fragment >
    <App />
  </React.Fragment>
);
